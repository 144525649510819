import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createAdmin,
  clearAdmin,
  toggleActivatedAdmin,
  updateAdmin,
} from "../../Redux/actions/adminActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import useStyles from "../Auth/AuthFormStyles";

import hasPermission from "../../utils/hasPermission";

const CreateAdmin = ({
  createAdmin,
  create_loading,
  success,
  error,
  admin,
  clearAdmin,
  logged_in_permissions,
  toggleActivatedAdmin,
  updateAdmin,
}) => {
  const classes = useStyles();
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    f_name: "",
    l_name: "",
  });
  const [id, setId] = useState(null);

  useEffect(() => {
    if (Object.keys(admin).length > 0) {
      setId(admin.username);
      setNewUser({
        username: admin.username,
        email: admin.email,
        f_name: admin.f_name,
        l_name: admin.l_name,
      });
    }
  }, [admin]);

  useEffect(() => {
    return () => {
      clearAdmin();
    };
  }, []);

  const onChangeHandler = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(admin).length > 0) {
      updateAdmin(newUser, admin.username, id);
    } else {
      createAdmin(newUser);
    }
  };

  const handleActivatedToggle = (e) => {
    e.preventDefault();
    toggleActivatedAdmin(admin.username);
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {Object.keys(admin).length > 0 ? "Update Admin" : "Create New Admin"}
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            {Object.keys(newUser).map((key, index) => (
              <Grid item xs={12}>
                <TextField
                  error={error[key] ? true : false}
                  helperText={error[key] && error[key]}
                  variant="outlined"
                  required
                  fullWidth
                  id={key}
                  value={newUser[key]}
                  name={key}
                  label={
                    key == "f_name"
                      ? "First Name"
                      : key == "l_name"
                      ? "Last Name"
                      : key.charAt(0).toUpperCase() + key.slice(1)
                  }
                  autoComplete={key}
                  onChange={(e) => onChangeHandler(e)}
                />
              </Grid>
            ))}
          </Grid>
          {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )}
          {hasPermission("SUPER_ADMIN", logged_in_permissions) &&
            Object.keys(admin).length > 0 && (
              <Button
                // disabled={!typeSelected}
                type="submit"
                fullWidth
                variant="contained"
                color="warning"
                className={classes.submit}
                onClick={(e) => handleActivatedToggle(e)}
              >
                {admin.activated ? "Deactivate Admin" : "Activate Admin"}
              </Button>
            )}
          <Button
            // disabled={!typeSelected}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {Object.keys(admin).length > 0 ? "Update" : "Create"}
          </Button>
        </form>
      </div>
    </Container>
  );
};

CreateAdmin.propTypes = {};

const mapStateToProps = (state) => ({
  create_loading: state.adminReducer.create_loading,
  admin: state.adminReducer.admin,
  success: state.adminReducer.success,
  error: state.adminReducer.error,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {
  createAdmin,
  clearAdmin,
  toggleActivatedAdmin,
  updateAdmin,
})(CreateAdmin);
