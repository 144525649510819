import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  GET_RESOURCES_LOADING,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILED,
  GET_RESOURCE_LOADING,
  GET_RESOURCE_SUCCESS,
  GET_RESOURCE_FAILED,
  CREATE_RESOURCE_LOADING,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_FAILED,
  UPDATE_RESOURCE_LOADING,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILED,
  CLEAR_RESOURCE,
  DRAWER_CLOSE,
} from "../types";

export const getResources = () => (dispatch) => {
  dispatch({
    type: GET_RESOURCES_LOADING,
  });
  axiosWithAuth()
    .get("/api/resources")
    .then((res) => {
      dispatch({
        type: GET_RESOURCES_SUCCESS,
        payload: res.data.resources,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_RESOURCES_FAILED,
        payload: err.response.data,
      });
    });
};

export const getResource = (id) => (dispatch) => {
  dispatch({
    type: GET_RESOURCE_LOADING,
  });
  axiosWithAuth()
    .get(`/api/resources/${id}`)
    .then((res) => {
      dispatch({
        type: GET_RESOURCE_SUCCESS,
        payload: res.data.resource
      });
    })
    .catch((err) => {
      console.error(err.response);
      dispatch({
        type: GET_RESOURCE_FAILED,
        payload: err.response.data,
      });
    });
};

export const createResource = (resource) => (dispatch) => {
  dispatch({
    type: CREATE_RESOURCE_LOADING,
  });
  axiosWithAuth()
    .post(`/api/resources/`, resource)
    .then((res) => {
      dispatch({
        type: CREATE_RESOURCE_SUCCESS,
        payload: res.data.resource,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: CREATE_RESOURCE_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateResource = (resource, id) => (dispatch) => {
  dispatch({
    type: UPDATE_RESOURCE_LOADING,
  });
  axiosWithAuth()
    .put(`/api/resources/${id}`, resource)
    .then((res) => {
      dispatch({
        type: UPDATE_RESOURCE_SUCCESS,
        payload: res.data.resource,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: UPDATE_RESOURCE_FAILED,
        payload: err.response.data,
      });
    });
};

export const clearResource = () => (dispatch) => {
  dispatch({
    type: CLEAR_RESOURCE,
  });
};
