import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Toolbar from "../Components/Education/Toolbar";
import ResourcesLibrary from "../Components/Education/ResourcesLibrary";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: "auto",
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

const Education = ({ history }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container} alignContent="flex-start">
      <Grid item xs={12} style={{ height: 50, margin: "20px auto" }}>
        <Toolbar />
      </Grid>
      <Grid item xs={12} style={{ padding: 20 }}>
        <ResourcesLibrary />
      </Grid>
    </Grid>
  );
};

export default Education;
