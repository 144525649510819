import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Card,
  CardHeader,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import HealingIcon from "@material-ui/icons/Healing";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { formatDate, formatPhone } from "../../utils/formatData";

import hasPermission from "../../utils/hasPermission";

const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: "auto",
    // maxWidth: "95%",
  },
  table: {
    minWidth: 650,
  },
  header: {
    textTransform: "uppercase",
  },
  headerCell: {
    paddingTop: 0,
    color: "#798991",
    fontSize: 12,
    fontWeight: 400,
  },
  date: {
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 0.4,
    color: "#576C75",
  },
  name: {
    fontWeight: 500,
    color: "#283136",
  },
});

const RefillTable = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.headerCell}>Date Created</TableCell>
              <TableCell className={classes.headerCell}>Patient Name</TableCell>
              <TableCell className={classes.headerCell}>Phone</TableCell>
              <TableCell className={classes.headerCell}>RX#</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((d) => (
                <TableRow key={`patientTable-${d.id}`}>
                  <TableCell className={classes.date}>
                    {formatDate(d.created_at)}
                  </TableCell>
                  <TableCell
                    className={classes.name}
                  >{`${d.patient_f_name}, ${d.patient_l_name}`}</TableCell>
                  <TableCell>{formatPhone(d.patient_phone)}</TableCell>
                  <TableCell>{d.medication_name}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      href={`/patients/${d.patient_id}/0/${d.prescription_id}`}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RefillTable;
