import jwt_decode from "jwt-decode";
import { axiosWithAuth } from "../../utils/axiosWithAuth";
import { setAuthToken } from "../../utils/decodeToken";
import {
  SIGNIN_ADMIN_LOADING,
  SET_CURRENT_ADMIN,
  SIGNIN_ADMIN_FAILED,
  LOGOUT_ADMIN,
  SET_PASSWORD_LOADING,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILED,
  PASSWORD_RESET_LOADING,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILED,
} from "../types";
import { getLoggedInPermissions } from "./adminActions";

export const signin = (admin, redirect) => (dispatch) => {
  dispatch({
    type: SIGNIN_ADMIN_LOADING,
  });
  axiosWithAuth()
    .post("/api/admins/signin", admin)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("token", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentAdmin(decoded));
      getLoggedInPermissions(decoded.username);
      redirect();
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: SIGNIN_ADMIN_FAILED,
        payload: err.response.data,
      });
    });
};

export const logoutAdmin = (e) => (dispatch) => {
  e.preventDefault();
  // Remove token from localStorage
  localStorage.removeItem("token");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current admin to {} which will set isAuthenticated to false
  dispatch({
    type: LOGOUT_ADMIN,
  });
};

export const passwordReset = (data, redirect) => (dispatch) => {
  dispatch({
    type: PASSWORD_RESET_LOADING,
  });
  axiosWithAuth()
    .post("/api/adminPasswordResetRequest/", data)
    .then((res) => {
      redirect("setupAccount");
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: PASSWORD_RESET_FAILED,
        payload: err.response.data,
      });
    });
};

export const setPassword = (data, redirect) => (dispatch) => {
  dispatch({
    type: SET_PASSWORD_LOADING,
  });
  axiosWithAuth()
    .put("/api/adminPasswordResetRequest/setPassword", data)
    .then((res) => {
      redirect("signin");
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: SET_PASSWORD_FAILED,
        payload: err.response.data,
      });
    });
};

export const setCurrentAdmin = (decoded) => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: decoded,
  };
};
