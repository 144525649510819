import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button, TextField, Box, Modal } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";
import { connect } from "react-redux";
import {
  createConversation,
  archiveConversation,
} from "../../Redux/actions/messageActions";
import Image from "material-ui-image";

import hasPermission from "../../utils/hasPermission";
import { formatDate, formatDateTime } from "../../utils/formatData";
import CreateArchivedDialog from "../Dialog/CreateArchivedDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    overflow: "hidden",
    height: "100%",
    flexGrow: 4,
  },
  chat: {
    overflow: "scroll",
    padding: 10,
    alignItems: "flex-start",
    marginBottom: "auto",
    flexGrow: 1,
    height: "calc(100% - 127px)",
    // flexGrow: 4,
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  createConversation: {
    margin: "auto",
    marginTop: 25,
  },
}));

const AdminChat = ({
  message,
  messages,
  somebodyTyping,
  onChangeHandler,
  handleSubmit,
  username,
  user,
  onFileChange,
  logged_in_permissions,
  images,
  activeConversation,
  createConversation,
  admin,
  archiveConversation,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [archive, setArchive] = useState(false);

  const scrollToRecent = async () => {
    var bubbles = document.querySelectorAll(".bubble");
    if (bubbles.length > 0) {
      bubbles.item(bubbles.length - 1).scrollIntoView();
    }
  };
  // const [images, setImages] = useState([]);
  const previewImages = [];

  useEffect(() => {
    scrollToRecent();
  });

  const handleArchive = () => {
    if (title != "") {
      let confirm = window.confirm(
        "Pressing OK will archive the active conversation and begin a new one"
      );
      if (confirm) {
            setOpen(false)

        setArchive(true);
        if (activeConversation && activeConversation.id)
          archiveConversation(activeConversation.id);
        createConversation(title, user.id);
      }
      setArchive(false);
    } else {
      window.alert(
        "You must give a conversation a title before you can proceed"
      );
    }
  };

  const [open, setOpen] = useState(false)

  const archiveHandler = () => {
    // setArchive(true)
    setOpen(true)

  }

  if (
    activeConversation &&
    Object.keys(activeConversation).length > 0 &&
    !archive
  ) {
    return (
      <Grid container alignContent="flex-start" className={classes.container}>
        <Grid
          item
          xs={12}
          style={{
            borderBottom: "1px solid lightGrey",
            borderTop: "1px solid lightGrey",
            padding: "10px 15px",
          }}
        >
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="body1">
                {activeConversation.title}
              </Typography>
              <Typography variant="caption">
                Start Date: {formatDate(activeConversation.start_date)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {hasPermission("CARE_LOG_CHAT", logged_in_permissions) && (
                <Button onClick={() => archiveHandler()}>Archive</Button>
              )}
            </Grid>
            <CreateArchivedDialog open={open} setOpen={setOpen} title={ title} setTitle={setTitle} handleArchive={handleArchive} handleClose={() => setOpen(false)} />

          </Grid>
        </Grid>
        <Grid item className={classes.chat}>
          {messages &&
            messages.map((m, i) => {
              if (!m.statusUpdate) {
                return (
                  <Bubble
                    key={i}
                    message={m}
                    username={admin.username}
                    userId={admin.username}
                  />
                );
              } else {
                return (
                  <StatusUpdate
                    key={i}
                    message={m}
                    username={admin.username}
                    userId={admin.username}
                  />
                );
              }
            })}
          {somebodyTyping ? (
            <Box
              style={{
                width: "55%",
                margin: "10px auto 20px 10px",
                padding: "5px 15px",
                borderRadius: 14,
                height: 44,
                backgroundColor: "#F0F8FF",
              }}
            >
              <Typography style={{ padding: 10 }}>...</Typography>
            </Box>
          ) : (
            ""
          )}
        </Grid>
        {hasPermission("CARE_LOG_CHAT", logged_in_permissions) && (
          <Grid
            item
            container
            justify="space-between"
            alignItems="flex-end"
            style={{
              width: "100%",
              marginTop: "auto",
              borderTop: "1px solid #EEF0F1",
              padding: 10,
              position: "relative",
            }}
          >
            <Box
              style={{
                position: "absolute",
                bottom: 113,
                display: "flex",
              }}
            >
              {images.length > 0 &&
                images.forEach((image, index) => {
                  previewImages.push(
                    <Image
                      key={index}
                      src={`${image}`}
                      aspectRatio={4 / 5}
                      imageStyle={{
                        height: 100,
                        width: "auto",
                        position: "static",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
                        border: "1px solid white",
                      }}
                      style={{
                        position: "static",
                        paddingTop: "0",
                        marginRight: 5,
                      }}
                      color="transparent"
                    />
                  );
                })}
              {previewImages}
            </Box>
            <form
              style={{ width: "100%", display: "flex", alignItems: "center" }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Grid item xs={"auto"}>
                <input
                  accept="image/*"
                  className={classes.input}
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={onFileChange}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    color="primary"
                    variant="outlined"
                    component="span"
                    fullWidth
                    startIcon={<AttachFileIcon />}
                    className={classes.button}
                  >
                    Attach
                  </Button>
                </label>
              </Grid>
              <Grid
                item
                xs={"auto"}
                style={{ flexGrow: 5, marginLeft: 10, marginRight: 10 }}
              >
                <TextField
                  fullWidth
                  rowsMax={1}
                  label="Type your message"
                  size="small"
                  variant="outlined"
                  onChange={(e) => onChangeHandler(e)}
                  value={message}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <Button
                  onClick={(e) => handleSubmit(e)}
                  color="primary"
                  variant="contained"
                  fullWidth
                  endIcon={<SendIcon />}
                  type="submit"
                >
                  Send
                </Button>
              </Grid>
            </form>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return <Grid></Grid>
  }
};

const mapStateToProps = (state) => ({
  admin: state.authReducer.admin,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
  activeConversation: state.messageReducer.activeConversation,
});

export default connect(mapStateToProps, {
  createConversation,
  archiveConversation,
})(AdminChat);

const Bubble = ({ message, username, userId }) => {
  const classes = useStylesBubble();
  const images = [];

  if (message.images) {
    JSON.parse(message.images).forEach((image, index) => {
      images.push(
        <Image
          key={index}
          src={`${image}`}
          aspectRatio={4 / 5}
          imageStyle={{
            minWidth: "100%",
            width: "auto",
          }}
          color="#EEF0F1"
        />
      );
    });
  }

  return (
    <div
      className={
        message.sender_id === username
          ? classes.bubbleContainer2
          : classes.bubbleContainer1
      }
    >
      <div
        className={`${"bubble"} ${
          message.sender_id === username ? classes.bubble2 : classes.bubble1
        }`}
      >
        <Typography variant="caption">
          From: {message.f_name} {message.l_name}
        </Typography>
        {images}
        <Typography style={{ padding: 10 }}>{message.text}</Typography>
      </div>
      <Typography variant="caption" style={{ paddingLeft: 10 }}>
        {formatDateTime(message.created_at)}
      </Typography>
    </div>
  );
};

const StatusUpdate = ({ message, username, userId }) => {
  return (
    <div
      style={{ borderTop: "1px solid grey", borderBottom: "1px solid grey" }}
    >
      <Typography variant="h6" align="center" style={{ padding: 10 }}>
        {message.text}
        <br />
        By: {message.sender_id}
      </Typography>
      <Typography align="center" style={{ padding: 10 }}>
        {formatDate(message.created_at, "llll")}
      </Typography>
    </div>
  );
};

const useStylesBubble = makeStyles((theme) => ({
  bubbleContainer1: {
    width: "55%",
    margin: "10px auto 20px 10px",
    flexGrow: 0,
  },
  bubbleContainer2: {
    width: "55%",
    margin: "10px 10px 20px auto",
    flexGrow: 0,
  },
  bubble1: {
    width: "100%",
    padding: "5px 15px",
    borderRadius: 14,
    backgroundColor: "#F0F8FF",
    overflow: "auto",
  },
  bubble2: {
    width: "auto",
    padding: "5px 15px",
    borderRadius: 14,
    backgroundColor: "#EEF0F1",
    overflow: "auto",
  },
}));
