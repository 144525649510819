import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  CREATE_USER_LOADING,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  CLEAR_USER,
  CLEAR_USER_ERRORS,
  DRAWER_CLOSE,
  SEARCH_PATIENTS,
  GET_PLANS_LOADING,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILED,
  ADD_PLAN_LOADING,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAILED,
  SET_PLAN,
  UPDATE_PLAN_LOADING,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILED,
  CLEAR_PLAN,
  SET_USER_ERROR,
} from "../types";

export const createUser = (newUser, close) => (dispatch) => {
  dispatch({ type: CREATE_USER_LOADING });
  axiosWithAuth()
    .post("/api/patients/", newUser)
    .then((res) => {
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: res.data.patient,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: CREATE_USER_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateUser = (updatedUser, id, close) => (dispatch) => {
  dispatch({ type: CREATE_USER_LOADING });
  axiosWithAuth()
    .put(`/api/patients/update/${id}`, updatedUser)
    .then((res) => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: res.data.patient,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: CREATE_USER_FAILED,
        payload: err.response.data.error,
      });
    });
};

export const getUsers = () => (dispatch) => {
  dispatch({ type: GET_USERS_LOADING });
  axiosWithAuth()
    .get("/api/patients/")
    .then((res) => {
      console.log(res.data.patients);
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: res.data.patients.sort((a, b) => {
          let nameA = `${a.f_name} ${a.l_name}`;
          let nameB = `${b.f_name} ${b.l_name}`;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_USERS_FAILED,
      });
    });
};

export const getUser = (id) => (dispatch) => {
  dispatch({ type: GET_USER_LOADING });
  axiosWithAuth()
    .get(`/api/patients/byId/${id}`)
    .then((res) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: res.data.patient,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_USER_FAILED,
      });
    });
};

export const sendAccessEmail =
  (f_name, l_name, phone, email, triggerSnack) => (dispatch) => {
    axiosWithAuth()
      .post(`api/patients/emailNewPatient`, {
        f_name,
        l_name,
        phone,
        email,
      })
      .then((res) => {
        triggerSnack(true);
      })
      .catch((err) => {
        console.error(err.response.data);
      });
  };

export const clearUser = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER,
  });
};

export const clearErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER_ERRORS,
  });
};

export const searchPatients = (val) => (dispatch) => {
  dispatch({
    type: SEARCH_PATIENTS,
    payload: val,
  });
};

export const getPlans = () => (dispatch) => {
  dispatch({
    type: GET_PLANS_LOADING,
  });
  axiosWithAuth()
    .get(`/api/plans/`)
    .then((res) => {
      dispatch({
        type: GET_PLANS_SUCCESS,
        payload: res.data.plans.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_PLANS_FAILED,
      });
    });
};

export const addPlan = (name) => (dispatch) => {
  dispatch({
    type: ADD_PLAN_LOADING,
  });
  axiosWithAuth()
    .post(`/api/plans/`, { name })
    .then((res) => {
      dispatch({
        type: ADD_PLAN_SUCCESS,
        payload: res.data.plan,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: ADD_PLAN_FAILED,
      });
    });
};

export const updatePlan = (row, name) => (dispatch) => {
  dispatch({
    type: UPDATE_PLAN_LOADING,
  });
  axiosWithAuth()
    .put(`/api/plans/${row}`, { name })
    .then((res) => {
      dispatch({
        type: UPDATE_PLAN_SUCCESS,
        payload: res.data.plan,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: UPDATE_PLAN_FAILED,
      });
    });
};

export const setPlan = (row) => (dispatch) => {
  dispatch({
    type: SET_PLAN,
    payload: row,
  });
};

export const clearPlan = () => (dispatch) => {
  dispatch({
    type: CLEAR_PLAN,
  });
};

export const setError = (error) => (dispatch) => {
  dispatch({
    type: SET_USER_ERROR,
    payload: error,
  });
};
