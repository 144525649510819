import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  createQuestion,
  EditQuestion,
  clearQuestion,
  addQuestion,
} from "../../Redux/actions/questionnaireActions";
import {
  Button,
  Checkbox,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  IconButton,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "../Auth/AuthFormStyles";
import DeleteIcon from "@material-ui/icons/Delete";

const CreateQuestion = ({
  createQuestion,
  addQuestion,
  question_,
  EditQuestion,
  clearQuestion,
  assess,
  error,
}) => {
  const classes = useStyles();
  const [typeSelection, setTypeSelection] = useState(2);
  const [question, setQuestion] = useState({
    body: "",
    answers: [],
    description: "",
    answersType: "singleAnswer",
    questionType: "text-area",
    optional: true,
    additional: false,
  });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (Object.keys(question_).length > 0) {
      setQuestion({
        body: question_.body,
        answers: question_.answers,
        description: question_.description,
        answersType: question_.answersType,
        questionType: question_.questionType,
        optional: question_.optional === 1 ? true : false,
        additional: question_.additional === 1 ? true : false,
      });

      switch (question_.questionType) {
        case "yes-no":
          setTypeSelection(0);
          break;
        case "multiple-choice":
          setTypeSelection(1);
          break;
        case "text-area":
          setTypeSelection(2);
          break;
        case "text-block":
          setTypeSelection(3);
          break;
        case "number":
          setTypeSelection(4);
          break;
        case "date-picker":
          setTypeSelection(5);
          break;
        case "signature":
          setTypeSelection(6);
          break;
        default:
          break;
      }

      return () => {
        cleanHandler(true);
      };
    }
  }, [question_]);

  const handleTypeSelect = (i) => {
    setTypeSelection(i);
    switch (i) {
      case 0:
        setQuestion({
          ...question,
          answers: ["YES", "NO"],
          questionType: "yes-no",
        });
        break;
      case 1:
        setQuestion({
          ...question,
          answers: ["", ""],
          questionType: "multiple-choice",
        });
        break;
      case 2:
        setQuestion({ ...question, answers: [], questionType: "text-area" });
        break;
      case 3:
        setQuestion({
          ...question,
          answers: [],
          questionType: "text-block",
        });
        break;
      case 4:
        setQuestion({ ...question, answers: [], questionType: "number" });
        break;
      case 5:
        setQuestion({
          ...question,
          answers: [],
          questionType: "date-picker",
        });
        break;
      case 6:
        setQuestion({
          ...question,
          answers: [],
          questionType: "signature",
          optional: 1,
        });
      default:
        break;
    }
  };

  const addAnswer = () => {
    setQuestion({ ...question, answers: [...question.answers, ""] });
  };

  const removeAnswer = (index) => {
    const questions = { ...question };
    questions.answers.splice(index, 1);
    setQuestion(questions);
  };

  const onQuestionChange = (e) => {
    setQuestion({ ...question, body: e.target.value });
  };

  const onAnswerChange = (e, i) => {
    let container = [...question.answers];
    container[i] = e.target.value;
    setQuestion({ ...question, answers: container });
  };

  const submitQuestion = () => {
    setSubmitted(true);
    createQuestion(
      {
        ...question,
      },
      cleanHandler
    );
  };

  const clearHandler = async () => {
    await clearQuestion();
  };

  const cleanHandler = (reset = false) => {
    handleTypeSelect(reset ? 2 : typeSelection);
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      body: "",
      description: "",
      answersType: "singleAnswer",
      optional: true,
      additional: false,
    }));
    setSubmitted(false);
  };

  const descriptionHandler = (event) =>
    setQuestion({ ...question, description: event.target.value });
  const additionalHandler = (event) =>
    setQuestion({ ...question, additional: event.target.checked });
  const requireHandler = (event) =>
    setQuestion({ ...question, optional: !event.target.checked });
  const multipleAnswerHandler = (event) =>
    setQuestion({
      ...question,
      answersType: event.target.checked ? "multipleAnswer" : "singleAnswer",
    });

  return (
    <Grid
      component="main"
      style={{
        marginTop: 20,
        marginBottom: 30,
      }}
    >
      <CssBaseline />
      <div>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Select new question type
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              size="small"
              fullWidth
              style={{ height: "100%" }}
              variant={typeSelection === 0 ? "contained" : "outlined"}
              onClick={() => handleTypeSelect(0)}
            >
              YES / NO
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              variant="outline"
              size="small"
              fullWidth
              variant={typeSelection === 1 ? "contained" : "outlined"}
              onClick={() => handleTypeSelect(1)}
            >
              MULTIPLE CHOICE
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              variant="outline"
              size="small"
              fullWidth
              style={{ height: "100%" }}
              variant={typeSelection === 2 ? "contained" : "outlined"}
              onClick={() => handleTypeSelect(2)}
            >
              TEXT AREA
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              variant="outline"
              size="small"
              fullWidth
              style={{ height: "100%" }}
              variant={typeSelection === 3 ? "contained" : "outlined"}
              onClick={() => handleTypeSelect(3)}
            >
              TEXT BLOCK
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              variant="outline"
              size="small"
              fullWidth
              style={{ height: "100%" }}
              variant={typeSelection === 4 ? "contained" : "outlined"}
              onClick={() => handleTypeSelect(4)}
            >
              NUMBER
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              variant="outline"
              size="small"
              fullWidth
              variant={typeSelection === 5 ? "contained" : "outlined"}
              onClick={() => handleTypeSelect(5)}
            >
              DATE PICKER
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="secondary"
              variant="outline"
              size="small"
              fullWidth
              style={{ height: "100%" }}
              variant={typeSelection === 6 ? "contained" : "outlined"}
              onClick={() => handleTypeSelect(6)}
            >
              SIGNATURE
            </Button>
          </Grid>
        </Grid>

        {typeSelection !== 3 && typeSelection !== 6 && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle2" style={{ marginBottom: 15 }}>
                Create your question
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={error["body"] ? true : false}
                helperText={error["body"] && "Question Field Is Required"}
                variant="outlined"
                required
                fullWidth
                id="question"
                label="Question"
                name="question"
                value={question.body}
                onChange={(e) => onQuestionChange(e)}
                style={{ textTransform: "capitalize", height: "100%" }}
              />
            </Grid>

            {Array.isArray(question.answers) &&
              question.answers.map((a, i) => (
                <React.Fragment key={i}>
                  <Grid
                    item
                    xs={10}
                    style={{ marginTop: 10 }}
                    key={`answerInput-${i}`}
                  >
                    <TextField
                      error={
                        question.answers[i].length < 1 && submitted
                          ? true
                          : false
                      }
                      helperText={
                        question.answers[i].length < 1 &&
                        submitted &&
                        "Answer Field is Required"
                      }
                      variant="outlined"
                      required
                      fullWidth
                      disabled={typeSelection === 0 ? true : false}
                      id={`answer-${i}`}
                      label={`Answer ${i + 1}`}
                      name="answer"
                      value={question.answers[i]}
                      onChange={(e) => onAnswerChange(e, i)}
                      style={{ textTransform: "capitalize" }}
                    />
                  </Grid>
                  {question.answers.length > 2 && (
                    <Grid
                      item
                      xs={2}
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => removeAnswer(i)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            {typeSelection === 1 && (
              <Button onClick={addAnswer}>Add Answer</Button>
            )}
          </Grid>
        )}
        {typeSelection !== 6 && (
          <>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                style={{ marginTop: 20, marginBottom: 10 }}
              >
                {typeSelection === 3 ? "Add Text Block" : "Add Description"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                id="description"
                label={typeSelection === 3 ? "Text Block" : "Description"}
                name="description"
                value={question.description}
                onChange={descriptionHandler}
              />
            </Grid>
          </>
        )}

        {typeSelection !== 3 && typeSelection !== 6 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ marginTop: 15 }}>
              Do you want an additional answer:
              <Checkbox
                checked={question.additional}
                onChange={additionalHandler}
                color="primary"
              />
            </Typography>
          </Grid>
        )}
        {typeSelection !== 3 && typeSelection !== 6 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ marginTop: 15 }}>
              Is this question is require?
              <Checkbox
                checked={!question.optional}
                onChange={requireHandler}
                color="primary"
              />
            </Typography>
          </Grid>
        )}

        {typeSelection === 1 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ marginTop: 15 }}>
              Do you want this question have multiple answer:
              <Checkbox
                checked={
                  (question.answersType === "multipleAnswer" ? true : false,
                  question.multipleAnswer)
                }
                onChange={multipleAnswerHandler}
                color="primary"
              />
            </Typography>
          </Grid>
        )}
        <Grid
          container
          justify="flex-end"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {Object.keys(question_).length > 0 ? (
            <>
              <Button
                variant="outlined"
                style={{ marginRight: 20 }}
                onClick={clearHandler}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => EditQuestion(question, question_.id)}
                startIcon={<SaveIcon />}
              >
                Update
              </Button>
            </>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={submitQuestion}
              endIcon={<KeyboardArrowRightIcon />}
            >
              {assess ? "Add To Assessment" : "Add To Survey"}
            </Button>
          )}
        </Grid>
        {error.error && (
          <Typography variant="body2" id="error" className={classes.errorText}>
            {error.error}
          </Typography>
        )}
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  question_: state.questionnaireReducer.question,
  error: state.questionnaireReducer.error,
});

export default connect(mapStateToProps, {
  createQuestion,
  addQuestion,
  EditQuestion,
  clearQuestion,
})(CreateQuestion);
