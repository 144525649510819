import {
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILED,
  CREATE_NOTIFICATION_LOADING,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILED,
} from "../types";

const initialState = {
  get_notifications_loading: false,
  create_notification_loading: false,
  notifications: [],
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        get_notifications_loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        get_notifications_loading: false,
        notifications: action.payload,
      };
    case GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        get_notifications_loading: false,
        error: action.payload,
      };
    case CREATE_NOTIFICATION_LOADING:
      return {
        ...state,
        create_notification_loading: true,
        error: {},
      };
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        create_notification_loading: false,
        notifications: [action.payload, ...state.notifications],
        error: {},
      };
    default:
      return state;
  }
};
