import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button, TextField, Box } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";
import io from "socket.io-client";
import { connect } from "react-redux";
import Image from "material-ui-image";
import { axiosWithAuth } from "../../utils/axiosWithAuth";
import { formatDateTime } from "../../utils/formatData";

import hasPermission from "../../utils/hasPermission";

const CHAT_URL = process.env.REACT_APP_CHAT_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    overflow: "hidden",
    height: "100%",
    flexGrow: 4,
  },
  chat: {
    overflow: "scroll",
    padding: 10,
    alignItems: "flex-start",
    marginBottom: "auto",
    flexGrow: 1,
    height: "calc(100% - 61px)",
    // flexGrow: 4,
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

const Chat = ({ user, admin, logged_in_permissions }) => {
  const classes = useStyles();
  var socket = null;
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState();
  const [somebodyTyping, setSomebodyTyping] = useState(false);
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const room = user.id;
  const { username } = admin;
  const [images, setImages] = useState([]);
  const previewImages = [];

  const initiateSocket = (room) => {
    socket = io(CHAT_URL);
    const data = { participant_id: username, room, admin: true };
    if (socket && room) socket.emit("join", data);
  };

  const disconnectSocket = (room) => {
    if (socket && room) socket.emit("disconnect");
    if (socket) socket.disconnect();
  };

  const subscribeToChat = (cb) => {
    if (!socket) return true;
    socket.on("chat", (msg) => {
      return cb(null, msg);
    });
  };

  const subscribeToTyping = (cb) => {
    if (!socket) return true;
    socket.on("display", (data) => {
      return cb(null, data);
    });
  };

  const typingToChat = (typing, room) => {
    let data = { typing, participant_id: username, room };
    if (socket && room) socket.emit("typing", data);
  };

  const sendMessage = async (text, images) => {
    if (!text && !images.length) return;
    let message = {
      text,
      sender_id: username,
      images: JSON.stringify(images),
      type: "patient",
    };
    let data = { typing: false, participant_id: username, room };
    if (socket && room) socket.emit("typing", data);
    if (socket && room) await socket.emit("chat", { message, room });
    scrollToRecent();
  };

  const scrollToRecent = async () => {
    var bubbles = document.querySelectorAll(".bubble");
    bubbles &&
      bubbles.length &&
      bubbles.item(bubbles.length - 1).scrollIntoView();
  };

  initiateSocket();

  const getMessages = async () => {
    const data = await fetchMessages(user.id);
    setMessages(data);
    scrollToRecent();
  };

  const onFileChange = ({ target }) => {
    Object.values(target.files).forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) =>
        setImages((prevImages) => [...prevImages, e.target.result]);
    });
  };

  useEffect(() => {
    if (room) initiateSocket(room);
    subscribeToChat((err, data) => {
      if (err) return;
      setMessages((oldMessages) => [...oldMessages, data]);
    });
    subscribeToTyping((err, data) => {
      if (err) return;
      if (data.participant_id != admin.username) {
        setSomebodyTyping(data.typing);
      }
      scrollToRecent();
    });
    getMessages();
    return () => {
      disconnectSocket();
    };
  }, [user]);

  const onChangeHandler = (e) => {
    setMessage(e.target.value);
    if (typingTimeout) clearTimeout(typingTimeout);
    setTyping(true);
    !typing && typingToChat(true, room);
    setTypingTimeout(
      setTimeout(function () {
        setTyping(false);
        typingToChat(false, room);
      }, 2000)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage(message, images);
    setMessage("");
    setImages([]);
  };

  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <Grid item className={classes.chat}>
        <Box id="chatbox">
          {messages &&
            messages.map((m, i) => (
              <Bubble key={i} message={m} username={username} userId={user.id} />
            ))}
          {somebodyTyping ? (
            <Box
              style={{
                width: "55%",
                margin: "10px auto 20px 10px",
                padding: "5px 15px",
                borderRadius: 14,
                height: 44,
                backgroundColor: "#F0F8FF",
              }}
            >
              <Typography style={{ padding: 10 }}>...</Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Grid>

      {hasPermission("PATIENT_CHAT", logged_in_permissions) && (
        <Grid
          item
          container
          justify="space-between"
          alignItems="flex-end"
          style={{
            width: "100%",
            marginTop: "auto",
            borderTop: "1px solid #EEF0F1",
            padding: 10,
            position: "relative",
          }}
        >
          <Box
            style={{
              position: "absolute",
              bottom: 113,
              display: "flex",
            }}
          >
            {" "}
            {images.length > 0 &&
              images.forEach((image, index) => {
                previewImages.push(
                  <Image
                    key={index}
                    src={`${image}`}
                    aspectRatio={4 / 5}
                    imageStyle={{
                      height: 100,
                      width: "auto",
                      position: "static",
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
                      border: "1px solid white",
                    }}
                    style={{
                      position: "static",
                      paddingTop: "0",
                      marginRight: 5,
                    }}
                    color="transparent"
                  />
                );
              })}
            {previewImages}
          </Box>
          <form
            style={{ width: "100%", display: "flex", alignItems: "center" }}
            onSubmit={(e) => handleSubmit(e)}
          >
            <Grid item xs={"auto"}>
              <input
                accept="image/*"
                className={classes.input}
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={onFileChange}
              />
              <label htmlFor="raised-button-file">
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  fullWidth
                  startIcon={<AttachFileIcon />}
                  className={classes.button}
                >
                  Attach
                </Button>
              </label>
            </Grid>
            <Grid
              item
              xs={"auto"}
              style={{ flexGrow: 5, marginLeft: 10, marginRight: 10 }}
            >
              <TextField
                fullWidth
                rowsMax={1}
                label="Type your message"
                size="small"
                variant="outlined"
                onChange={(e) => onChangeHandler(e)}
                value={message}
              />
            </Grid>
            <Grid item xs={"auto"}>
              <Button
                onClick={(e) => handleSubmit(e)}
                color="primary"
                variant="contained"
                fullWidth
                endIcon={<SendIcon />}
                type="submit"
              >
                Send
              </Button>
            </Grid>
          </form>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  admin: state.authReducer.admin,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {})(Chat);

const Bubble = ({ message, username, userId }) => {
  const classes = useStylesBubble();

  const images = [];
  if (message.images) {
    JSON.parse(message.images).forEach((image, index) => {
      images.push(
        <Image
          key={index}
          src={`${image}`}
          aspectRatio={4 / 5}
          imageStyle={{
            minWidth: "100%",
            width: "auto",
          }}
          color="#EEF0F1"
        />
      );
    });
  }

  return (
    <div
      className={
        message.sender_id === username
          ? classes.bubbleContainer2
          : classes.bubbleContainer1
      }
    >
      <div
        className={`${"bubble"} ${
          message.sender_id === username ? classes.bubble2 : classes.bubble1
        }`}
      >
        <Typography variant="caption">
          {message.sender_id === userId
            ? "Patient"
            : `${message.f_name} ${message.l_name}`}
        </Typography>
        {images}
        <Typography style={{ padding: 10 }}>{message.text}</Typography>
      </div>
      <Typography variant="caption" style={{ paddingLeft: 10 }}>
        {formatDateTime(message.created_at)}
      </Typography>
    </div>
  );
};

const useStylesBubble = makeStyles((theme) => ({
  bubbleContainer1: {
    width: "55%",
    margin: "10px auto 20px 10px",
    flexGrow: 0,
  },
  bubbleContainer2: {
    width: "55%",
    margin: "10px 10px 20px auto",
    flexGrow: 0,
  },
  bubble1: {
    width: "100%",
    padding: "5px 15px",
    borderRadius: 14,
    backgroundColor: "#F0F8FF",
    overflow: "auto",
  },
  bubble2: {
    width: "auto",
    padding: "5px 15px",
    borderRadius: 14,
    backgroundColor: "#EEF0F1",
    overflow: "auto",
  },
}));

function fetchMessages(id) {
  return axiosWithAuth()
    .get("api/messages/patient/" + id)
    .then(async (res) => {
      return res.data.messages;
    })
    .catch((err) => {
      console.error(err);
    });
}
