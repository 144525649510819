import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getMedications,
  getMedication,
} from "../../Redux/actions/medicationActions";
import { openDrawer } from "../../Redux/actions/drawerActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { formatDate } from "../../utils/formatData";

import hasPermissions from "../../utils/hasPermission";
import hasPermission from "../../utils/hasPermission";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const MedicationsTable = ({
  getMedications,
  getMedication,
  medications,
  openDrawer,
  logged_in_permissions,
}) => {
  const classes = useStyles();

  const [localMedications, setLocalMedications] = useState([])

  useEffect(() => {
    getMedications();
  }, []);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(medications))
    setLocalMedications(data);
  }, [medications]);

  const handleSearch = (e) => {
    let search = e.target.value;
    if (!search) return setLocalMedications(JSON.parse(JSON.stringify(medications)));
    let data = medications.filter((m) => m.name.toLowerCase().includes(search.toLowerCase()));
    setLocalMedications(JSON.parse(JSON.stringify(data)));
  };

  return (
    <>
      <div style={{ marginBottom: 25 }}>
        <TextField
          id="standard-basic"
          label="Search Medications"
          onChange={(e) => handleSearch(e)}
        />
      </div>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Medication Name</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localMedications.length > 0 &&
              localMedications.map((med) => (
                <TableRow key={`medicationTable-${med.id}`}>
                  <TableCell>{med.name}</TableCell>
                  <TableCell>{formatDate(med.created_at)}</TableCell>
                  <TableCell>{formatDate(med.updated_at)}</TableCell>
                  <TableCell align="right">
                    {hasPermission(
                      "CRUD_MEDICATION",
                      logged_in_permissions
                    ) && (
                      <IconButton
                        onClick={() => {
                          getMedication(med.id);
                          openDrawer("createMedication");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  medications: state.medicationReducer.medications,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {
  getMedications,
  getMedication,
  openDrawer,
})(MedicationsTable);
