import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosWithAuth = () => {
  const token = localStorage.getItem("token");

  return axios.create({
    headers: {
      Authorization: token,
    },
    baseURL: BASE_URL,
  });
};
