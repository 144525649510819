import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createNotification } from "../../../Redux/actions/notificationActions";
import { createAdmin } from "../../../Redux/actions/adminActions";
import { getUsers, getPlans } from "../../../Redux/actions/userActions";
import { getMedications } from "../../../Redux/actions/medicationActions";
import {
  Button,
  TextField,
  Grid,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import { axiosWithAuth } from "../../../utils/axiosWithAuth";

import useStyles from "../../Auth/AuthFormStyles";

const PlansNotifications = ({
  getUsers,
  users,
  createNotification,
  getPlans,
  plans,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });

  useEffect(() => {
    getPlans();
  }, []);

  const onSelect = (e) => {
    setSelected(e.target.value);
  };

  const onChangeHandler = (e) => {
    setNotification({ ...notification, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .get(`/api/patients/byPlan/${selected}`)
      .then((res) => {
        let recipients = res.data.patients;
        if (recipients.length > 0) {
          createNotification({ recipients, ...notification });
        }
      })
      .catch((err) => {
        console.error(err.response.data);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Patient Plans
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selected}
              onChange={onSelect}
              label="Patient Plans"
              fullWidth
            >
              {plans.map((p) => (
                <MenuItem key={p.name} value={p.name}>
                  <ListItemText primary={p.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            // error={error.password ? true : false}
            // helperText={error.password && error.password}
            variant="outlined"
            required
            fullWidth
            name="title"
            label="Title"
            id="title"
            onChange={(e) => onChangeHandler(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // error={passwordError}
            // helperText={passwordError && "Passwords do not match"}
            variant="outlined"
            fullWidth
            name="body"
            label="Body"
            id="body"
            multiline
            rows="3"
            onChange={(e) => onChangeHandler(e)}
          />
        </Grid>
      </Grid>
      {/* {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )}
          {success && (
            <Typography
              variant="body2"
              id="success"
              className={classes.successText}
            >
              {success}
            </Typography>
          )} */}

      <Button
        // disabled={!typeSelected}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={(e) => handleSubmit(e)}
      >
        Send Notification
      </Button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  create_loading: state.adminReducer.create_loading,
  success: state.adminReducer.success,
  error: state.adminReducer.error,
  users: state.userReducer.users,
  plans: state.userReducer.plans,
});

export default connect(mapStateToProps, {
  createAdmin,
  getUsers,
  createNotification,
  getPlans,
})(PlansNotifications);
