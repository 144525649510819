import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  removeQuestion,
  createSurvey,
  addQuestion,
  updateSurvey,
  clearViewSurvey,
  getSurvey,
  clearAddedQuestions,
  setAddedQuestions,
} from "../../Redux/actions/questionnaireActions";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";

import QuestionItem from "./QuestionItem";
import CreateQuestion from "../Forms/CreateQuestion";
import QuestionsLibrary from "./QuestionLibrary";

import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import useStyles from "../Auth/AuthFormStyles";

import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card } from "../Card";
import uuidv4 from "../../utils/uuidv4";

const CreateSurvey = ({
  surveyId,
  addedQuestions,
  removeQuestion,
  createSurvey,
  survey,
  addQuestion,
  updateSurvey,
  clearViewSurvey,
  getSurvey,
  error,
  clearAddedQuestions,
  setAddedQuestions,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [inst, setInst] = useState("");
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (surveyId) getSurvey(surveyId);

    return () => clearAddedQuestions();
  }, []);

  useEffect(() => {
    if (Object.keys(survey).length > 0) {
      setName(survey.name);
      setInst(survey.instructions);

      survey.questions
        .sort((a, b) => a.order - b.order)
        .forEach((q) => {
          addQuestion(q);
        });
    }

    return () => clearViewSurvey();
  }, [survey]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const surv = {
      type: "survey",
      instructions: inst,
      name: name,
      questions: cards.map(({ id, question_to_questionnaire_id }, i) => ({
        id,
        order: i,
        question_to_questionnaire_id,
      })),
    };

    if (Object.keys(survey).length > 0) {
      updateSurvey(survey.id, surv);
    } else {
      createSurvey(surv);
    }
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];

      setCards((prevCard) =>
        update(prevCard, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  useEffect(() => {
    setCards((prevCards) => {
      if (prevCards.length !== addedQuestions.length) {
        return addedQuestions.map((q) => ({
          ...q,
          question_to_questionnaire_id:
            q.question_to_questionnaire_id || uuidv4(),
        }));
      } else {
        return prevCards;
      }
    });
  }, [addedQuestions]);

  useEffect(() => {
    setAddedQuestions(cards);
  }, [cards]);

  return (
    <Grid component="main" style={{ padding: 20 }}>
      <CssBaseline />
      <form noValidate>
        <Box display="flex" p={1}>
          <Box
            flex={5}
            style={{
              maxHeight: "calc(100vh - 68px)",
              overflow: "auto",
              padding: 5,
              paddingRight: 20,
              borderRight: "1px solid lightgrey",
            }}
          >
            <Grid item xs={12}>
              <Button
                size="medium"
                href="/surveys"
                color="primary"
                startIcon={<ArrowBackIcon />}
                className={classes.button}
              >
                {surveyId ? "Back" : "Cancel"}
              </Button>
              <Typography
                variant="h4"
                style={{ fontWeight: 600, marginLeft: 10 }}
              >
                {surveyId ? "Update Survey" : "Create Survey"}
              </Typography>
              <CreateQuestion />
              <QuestionsLibrary />
            </Grid>
          </Box>
          <Box
            flex={8}
            style={{
              maxHeight: "calc(100vh - 68px)",
              overflow: "auto",
            }}
          >
            <Grid item xs={12} style={{ padding: 20 }}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    error={error.name ? true : false}
                    helperText={error.name && error.name}
                    variant="standard"
                    required
                    fullWidth
                    id="assessment_name"
                    label="Survey Name"
                    name="assessment_name"
                    autoComplete="assessment_name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {surveyId ? "Update" : "Create"}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={error.instructions ? true : false}
                    helperText={error.instructions && error.instructions}
                    variant="standard"
                    required
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    id="instructions"
                    label="Instructions"
                    name="instructions"
                    autoComplete="instructions"
                    value={inst}
                    onChange={(e) => setInst(e.target.value)}
                  />
                  {error.questions && (
                    <Typography
                      variant="body2"
                      id="error"
                      className={classes.errorText}
                    >
                      {error.questions}
                    </Typography>
                  )}
                  <DndProvider backend={HTML5Backend}>
                    <Grid
                      container
                      style={{
                        marginTop: 40,
                      }}
                    >
                      <Grid item xs={12}>
                        {cards.length > 0 &&
                          cards.map((q, i) => (
                            <Card
                              key={q.question_to_questionnaire_id}
                              index={i}
                              id={q.question_to_questionnaire_id}
                              moveCard={moveCard}
                            >
                              <Paper>
                                <QuestionItem
                                  data={q}
                                  remove={removeQuestion}
                                  added
                                  edit={
                                    Object.keys(survey).length > 0
                                      ? true
                                      : false
                                  }
                                />
                              </Paper>
                            </Card>
                          ))}
                      </Grid>
                    </Grid>
                  </DndProvider>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  addedQuestions: state.questionnaireReducer.addedQuestions,
  survey: state.questionnaireReducer.survey,
  error: state.questionnaireReducer.error,
});

export default connect(mapStateToProps, {
  removeQuestion,
  createSurvey,
  addQuestion,
  updateSurvey,
  clearViewSurvey,
  getSurvey,
  clearAddedQuestions,
  setAddedQuestions,
})(CreateSurvey);
