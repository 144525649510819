import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Welcome from "../Components/Dashboard/Welcome";
import Stats from "../Components/Dashboard/Stats";
import PatientsTable from "../Components/Tables/UsersTable";
import TabsContainer from "../Components/Dashboard/TabsContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: "auto",
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

const Dashboard = ({ history }) => {
  const classes = useStyles();
  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <Grid item xs={12} style={{}}>
        {/* <Welcome /> */}
      </Grid>
      <Grid item xs={12} style={{ margin: "25px auto" }}>
        <Stats />
      </Grid>
      <Grid item xs={12} style={{ minHeight: 600 }}>
        <TabsContainer />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
