import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  CREATE_MEDICATION_LOADING,
  CREATE_MEDICATION_SUCCESS,
  CREATE_MEDICATION_FAILED,
  UPDATE_MEDICATION_LOADING,
  UPDATE_MEDICATION_SUCCESS,
  UPDATE_MEDICATION_FAILED,
  GET_MEDICATIONS_LOADING,
  GET_MEDICATIONS_SUCCESS,
  GET_MEDICATIONS_FAILED,
  GET_MEDICATION_LOADING,
  GET_MEDICATION_SUCCESS,
  GET_MEDICATION_FAILED,
  CLEAR_MEDICATION,
  DRAWER_CLOSE,
  GET_THERAPIES_LOADING,
  GET_THERAPIES_SUCCESS,
  GET_THERAPIES_FAILED,
  CREATE_THERAPY_LOADING,
  CREATE_THERAPY_SUCCESS,
  CREATE_THERAPY_FAILED,
  SET_THERAPY,
  CLEAR_THERAPY,
  UPDATE_THERAPY_LOADING,
  UPDATE_THERAPY_SUCCESS,
  UPDATE_THERAPY_FAILED,
} from "../types";

export const getMedications = () => (dispatch) => {
  dispatch({ type: GET_MEDICATIONS_LOADING });
  axiosWithAuth()
    .get("/api/medications")
    .then((res) => {
      dispatch({
        type: GET_MEDICATIONS_SUCCESS,
        payload: res.data.medications.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_MEDICATIONS_FAILED,
        payload: err.response.data,
      });
    });
};

export const getMedication = (id) => (dispatch) => {
  dispatch({ type: GET_MEDICATION_LOADING });
  axiosWithAuth()
    .get(`/api/medications/${id}`)
    .then((res) => {
      dispatch({
        type: GET_MEDICATION_SUCCESS,
        payload: res.data.medication,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_MEDICATION_FAILED,
        payload: err.response.data,
      });
    });
};

export const createMedication = (newMedication) => (dispatch) => {
  dispatch({ type: CREATE_MEDICATION_LOADING });
  axiosWithAuth()
    .post("/api/medications/", newMedication)
    .then((res) => {
      dispatch({
        type: CREATE_MEDICATION_SUCCESS,
        payload: res.data.medication,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: CREATE_MEDICATION_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateMedication = (medication, id) => (dispatch) => {
  dispatch({
    type: UPDATE_MEDICATION_LOADING,
  });
  axiosWithAuth()
    .put(`/api/medications/${id}`, medication)
    .then((res) => {
      dispatch({
        type: UPDATE_MEDICATION_SUCCESS,
        payload: res.data.medication,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: UPDATE_MEDICATION_FAILED,
        payload: err.response.data,
      });
    });
};

export const clearMedication = () => (dispatch) => {
  dispatch({
    type: CLEAR_MEDICATION,
  });
};

export const getTherapies = () => (dispatch) => {
  dispatch({ type: GET_THERAPIES_LOADING });
  axiosWithAuth()
    .get("/api/therapies/")
    .then((res) => {
      dispatch({
        type: GET_THERAPIES_SUCCESS,
        payload: res.data.therapies.sort((a, b) => {
          let nameA = a.name;
          let nameB = b.name;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_THERAPIES_FAILED,
        payload: err.response.data,
      });
    });
};

export const addTherapy = (newTherapy) => (dispatch) => {
  dispatch({ type: CREATE_THERAPY_LOADING });
  axiosWithAuth()
    .post("/api/therapies/", { name: newTherapy })
    .then((res) => {
      dispatch({
        type: CREATE_THERAPY_SUCCESS,
        payload: res.data.therapy,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: CREATE_THERAPY_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateTherapy = (row, name) => (dispatch) => {
  dispatch({
    type: UPDATE_THERAPY_LOADING,
  });
  axiosWithAuth()
    .put(`/api/therapies/${row}`, { name })
    .then((res) => {
      dispatch({
        type: UPDATE_THERAPY_SUCCESS,
        payload: res.data.therapy,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: UPDATE_THERAPY_FAILED,
      });
    });
};

export const setTherapy = (row) => (dispatch) => {
  dispatch({
    type: SET_THERAPY,
    payload: row,
  });
};

export const clearTherapy = () => (dispatch) => {
  dispatch({
    type: CLEAR_THERAPY,
  });
};
