import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closeDrawer } from "../../Redux/actions/drawerActions";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";

import CreateAdmin from "../Forms/CreateAdmin";
import CreateUser from "../Forms/CreateUser";
import CreatePrescription from "../Forms/CreatePrescription";
import CreateResource from "../Forms/CreateResource";
import CreateNotification from "../Forms/CreateNotification";
import AssignQuestionnaire from "../Forms/AssignQuestionnaire";
import CreateMedication from "../Forms/CreateMedication";
import AddTracking from "../Forms/AddTracking";
import AdminPermissions from "../Forms/AdminPermissions";
import AddDocument from "../Forms/AddDocument";
import Plans from "../Plans/Plans";
import Therapies from "../Therapies/Therapies";
import SendAssessments from "../Forms/SendAssessments";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    top: 20,
    height: "calc(100vh - 40px)",
  },
}));

const SetDrawerContent = ({ content }) => {
  switch (content) {
    case "createAdmin":
      return <CreateAdmin />;

    case "createUser":
      return <CreateUser />;

    case "createPrescription":
      return <CreatePrescription />;

    case "createResource":
      return <CreateResource />;

    case "createNotification":
      return <CreateNotification />;

    case "assessment":
      return <AssignQuestionnaire assess />;

    case "document":
      return <AssignQuestionnaire doc />;

    case "survey":
      return <AssignQuestionnaire survey />;

    case "createMedication":
      return <CreateMedication />;

    case "addTracking":
      return <AddTracking />;

    case "adminPermissions":
      return <AdminPermissions />;

    case "uploadDocument":
      return <AddDocument />;

    case "plans":
      return <Plans />;

    case "therapies":
      return <Therapies />;

    case "sendAssessments":
      return <SendAssessments />;

    default:
      return null;
  }
};

const DrawerContainer = ({ open, content, closeDrawer }) => {
  const classes = useStyles();

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="right"
      open={open}
      onClose={closeDrawer}
    >
      <SetDrawerContent content={content} />
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  open: state.drawerReducer.open,
  content: state.drawerReducer.content,
});

export default connect(mapStateToProps, {
  closeDrawer,
})(DrawerContainer);
