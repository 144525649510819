import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Navigation from "./Components/Navigation/Navigation";
import Drawer from "./Components/Drawer/Drawer";
import Routes from "./Routes";
import Sidebar from "./Components/Navigation/Sidebar";
import { decodeToken } from "./utils/decodeToken";
import { blue, brown } from "@material-ui/core/colors";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4297D0",
    },
    secondary: {
      main: "#a0c92f",
    },
    // type: "dark"
  },
  overrides: {
    // Style sheet name ⚛️

    MuiButton: {
      outlined: {
        borderRadius: 30,
      },
      contained: {
        borderRadius: 30,
      },
      containedPrimary: {
        color: "#fff",
        // backgroundColor: "#a0c92f",
        borderRadius: 30,
        "&:hover": {
          // backgroundColor: "#a0c92f",
        },
      },
      label: {
        // fontSize: 24,
        // lineHeight: 1,
        // marginTop: 4,
      },
    },
    MuiTableCell: {
      // Name of the rule
      head: {
        paddingTop: 0,
        color: "#798991",
        fontSize: 12,
        fontWeight: 400,
        textTransform: "uppercase",
      },
      body: {
        // Some CSS
        color: "#576C75",
      },
    },
  },
});
decodeToken();
const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: "white",
    // margin: "20px -20px",
    height: "calc(100vh - 40px)",
    // width: "calc(100% - 20px)",
    // borderRadius: 20,
  },
}));
function App() {
  const classes = useStyles();
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Sidebar>
              <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.container}
              >
                <Drawer />
                <Routes />
              </Grid>
            </Sidebar>
          </Router>
        </Provider>
      </MuiThemeProvider>
    </div>
  );
}
export default App;
