import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAssessments,
  getSurveys,
  getDocuments,
  assignQuestionnaire,
} from "../../Redux/actions/questionnaireActions";
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Typography,
  Container,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  ListItemText,
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";

import useStyles from "../Auth/AuthFormStyles";

const AssignQuestionnaire = ({
  surveys,
  assessments,
  documents,
  getSurveys,
  getAssessments,
  getDocuments,
  survey,
  assess,
  doc,
  user,
  assignQuestionnaire,
  error,
}) => {
  const classes = useStyles();
  const [questionnaire, setQuestionnaire] = useState("");

  useEffect(() => {
    getAssessments();
    getSurveys();
    getDocuments();
  }, []);

  const onSelect = (e) => {
    setQuestionnaire(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    assignQuestionnaire({
      patient_id: user.id,
      questionnaire_id: questionnaire,
      answers: [],
      completed: false,
    });
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <NotificationsNoneOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create {survey ? "Survey" : doc ? "Document" : "Assessment"}{" "}
          Assignment
        </Typography>

        <form className={classes.form} noValidate style={{ minWidth: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
                required
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {survey ? "Surveys" : doc ? "Documents" : "Assessments"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={questionnaire}
                  onChange={onSelect}
                  label={survey ? "Surveys" : doc ? "Documents" : "Assessments"}
                  fullWidth
                >
                  {survey
                    ? surveys.map((quest) => (
                        <MenuItem key={quest.name} value={quest.id}>
                          <ListItemText primary={quest.name} />
                        </MenuItem>
                      ))
                    : doc
                    ? documents.map((quest) => (
                        <MenuItem key={quest.name} value={quest.id}>
                          <ListItemText primary={quest.name} />
                        </MenuItem>
                      ))
                    : assessments.map((quest) => (
                        <MenuItem key={quest.name} value={quest.id}>
                          <ListItemText primary={quest.name} />
                        </MenuItem>
                      ))}
                </Select>
                {error.questionnaire_id && (
                  <FormHelperText error>
                    {error.questionnaire_id}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            Create
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  surveys: state.questionnaireReducer.surveys,
  assessments: state.questionnaireReducer.assessments,
  documents: state.questionnaireReducer.documents,
  user: state.userReducer.user,
  error: state.questionnaireReducer.error,
});

export default connect(mapStateToProps, {
  getSurveys,
  getAssessments,
  getDocuments,
  assignQuestionnaire,
})(AssignQuestionnaire);
