import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getLoggedInPermissions } from "../../Redux/actions/adminActions";

import welcomeImage from "../../assets/img/welcomeImage.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#A4D3FF",
    width: "95%",
    margin: "auto",
    // height: 300,
    borderRadius: 25,
    marginTop: 25,
    // padding: 25,
    paddingLeft: 50,
  },
  heading: {
    textAlign: "left",
    fontSize: 64,
    color: "#2787c9",
    margin: 0,
    lineHeight: 0.9,
  },
  summary: {
    fontSize: 18,
    fontWeight: 500,
    color: "#323A3E",
    marginTop: 14,
    lineHeight: 1.4,
  },
  image: {
    textAlign: "center",
    height: 300,
    position: "relative",
    WebkitBorderBottomRightRadius: "25px",
    WebkitBorderTopRightRadius: "25px",
    // marginBottom: -25,
    // marginTop: -25,
    // marginRight: -25,
    backgroundPosition: "center right",
    backgroundImage: "url(" + welcomeImage + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: 0,
  },
  gradient: {
    display: "block",
    content: "",
    height: 300,
    width: 200,
    position: "absolute",
    background:
      "linear-gradient(90deg, rgba(164,211,255,1) 0%, rgba(164,211,255,0) 100%)",
    zIndex: 2,
  },
}));

const Welcome = ({ admin, getLoggedInPermissions }) => {
  const classes = useStyles();
  useEffect(() => {
    getLoggedInPermissions(admin.username);
  }, []);
  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item xs={3} style={{ position: "relative", zIndex: 3 }}>
        <h1 className={classes.heading}>
          Welcome, <br /> {admin.username}!
        </h1>{" "}
      </Grid>{" "}
      <Grid item xs={9} className={classes.image}>
        <Box className={classes.gradient} />
      </Grid>{" "}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  admin: state.authReducer.admin,
});

export default connect(mapStateToProps, { getLoggedInPermissions })(Welcome);
