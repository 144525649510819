import { combineReducers } from "redux";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import userReducer from "./userReducer";
import drawerReducer from "./drawerReducer";
import prescriptionReducer from "./prescriptionReducer";
import medicationReducer from "./medicationReducer";
import questionnaireReducer from "./questionnaireReducer";
import notificationReducer from "./notificationReducer";
import educationReducer from "./educationReducer";
import messageReducer from "./messageReducer";
import documentReducer from "./documentReducer";

export default combineReducers({
  authReducer,
  adminReducer,
  userReducer,
  drawerReducer,
  prescriptionReducer,
  medicationReducer,
  questionnaireReducer,
  notificationReducer,
  educationReducer,
  messageReducer,
  documentReducer,
});
