import { dateUnix } from "../../utils/formatData";
import {
  GET_ALL_MESSAGES_LOADING,
  GET_ALL_MESSAGES_SUCCESS,
  GET_ALL_MESSAGES_FAILED,
  GET_UNSEEN_MESSAGES_LOADING,
  GET_UNSEEN_MESSAGES_SUCCESS,
  GET_UNSEEN_MESSAGES_FAILED,
  GET_ACTIVE_CONVERSATIONS_LOADING,
  GET_ACTIVE_CONVERSATIONS_SUCCESS,
  GET_ACTIVE_CONVERSATIONS_FAILED,
  CREATE_CONVERSATION_LOADING,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_FAILED,
  ARCHIVE_CONVERSATION_LOADING,
  ARCHIVE_CONVERSATION_SUCCESS,
  ARCHIVE_CONVERSATION_FAILED,
  GET_ARCHIVED_CONVERSATIONS_LOADING,
  GET_ARCHIVED_CONVERSATIONS_SUCCESS,
  GET_ARCHIVED_CONVERSATIONS_FAILED,
  SET_ARCHIVED_CONVERSATION_SUCCESS,
  SET_ACTIVE_CONVERSATION,
  ADD_CONVERSATION,
} from "../types";

const initialState = {
  get_unseen_loading: false,
  get_active_conversations_loading: false,
  create_conversation_loading: false,
  archive_conversation_loading: false,
  get_archived_conversations_loading: false,
  all_messages: {},
  unseen_messages: {},
  activeConversations: [],
  activeConversation: {},
  archivedConversations: [],
  archivedConversation: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MESSAGES_LOADING:
      return {
        ...state,
        get_unseen_loading: true,
        error: {},
      };
    case GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        get_unseen_loading: false,
        all_messages: action.payload,
        error: {},
      };
    case GET_ALL_MESSAGES_FAILED:
      return {
        ...state,
        get_unseen_loading: false,
        error: action.payload,
      };
    case GET_UNSEEN_MESSAGES_LOADING:
      return {
        ...state,
        get_unseen_loading: true,
        error: {},
      };
    case GET_UNSEEN_MESSAGES_SUCCESS:
      return {
        ...state,
        get_unseen_loading: false,
        unseen_messages: action.payload,
        error: {},
      };
    case GET_UNSEEN_MESSAGES_FAILED:
      return {
        ...state,
        get_unseen_loading: false,
        error: action.payload,
      };
    case GET_ACTIVE_CONVERSATIONS_LOADING:
      return {
        ...state,
        get_active_conversations_loading: true,
        error: {},
      };
    case GET_ACTIVE_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        get_active_conversations_loading: false,
        activeConversations: action.payload,
        activeConversation:
          Object.keys(state.activeConversation).length < 1 &&
          action.payload.filter((c) => c.prescription_id === null).sort((a, b) => a.start_date - b.start_date).sort((a, b) => dateUnix(b.start_date) - dateUnix(a.start_date))[0],
      };
    case GET_ACTIVE_CONVERSATIONS_FAILED:
      return {
        ...state,
        get_active_conversations_loading: false,
        error: action.payload,
      };
    case SET_ACTIVE_CONVERSATION:
      return {
        ...state,
        activeConversation: state.activeConversations.filter((c) => {
          if (action.payload === null && c.prescription_id === null) {
            return c;
          } else if (
            action.payload === c.prescription_id ||
            action.payload === c.id
          ) {
            return c;
          }
        }).sort((a, b) => dateUnix(b.start_date) - dateUnix(a.start_date))[0],
      };
    case CREATE_CONVERSATION_LOADING:
      return {
        ...state,
        create_active_conversation: true,
        error: {},
      };
    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        create_active_conversation: false,
        activeConversation: action.payload,
      };
    case CREATE_CONVERSATION_FAILED:
      return {
        ...state,
        create_active_conversation: false,
        error: action.payload,
      };
    case ARCHIVE_CONVERSATION_LOADING:
      return {
        ...state,
        archive_conversation_loading: true,
        error: {},
      };
    case ARCHIVE_CONVERSATION_SUCCESS:
      return {
        ...state,
        archive_conversation_loading: false,
        activeConversation: {},
        archivedConversations: [...state.archivedConversations, action.payload],
      };
    case ARCHIVE_CONVERSATION_FAILED:
      return {
        ...state,
        archive_conversation_loading: false,
        error: action.payload,
      };
    case GET_ARCHIVED_CONVERSATIONS_LOADING:
      return {
        ...state,
        get_archived_conversations_loading: true,
        error: {},
      };
    case GET_ARCHIVED_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        get_archived_conversations_loading: false,
        archivedConversations: action.payload,
      };
    case GET_ARCHIVED_CONVERSATIONS_FAILED:
      return {
        ...state,
        get_archived_conversations_loading: false,
        error: action.payload,
      };
    case SET_ARCHIVED_CONVERSATION_SUCCESS:
      return {
        ...state,
        archivedConversation: state.archivedConversations.filter(
          (ac) => ac.id == action.payload
        )[0],
      };
    case ADD_CONVERSATION:
      return {
        ...state,
        activeConversations: [...state.activeConversations, action.payload],
      };
    default:
      return state;
  }
};
