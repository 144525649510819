import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  CREATE_UPLOADED_DOCUMENT_LOADING,
  CREATE_UPLOADED_DOCUMENT_SUCCESS,
  CREATE_UPLOADED_DOCUMENT_FAILED,
  GET_UPLOADED_DOCUMENT_LOADING,
  GET_UPLOADED_DOCUMENT_SUCCESS,
  GET_UPLOADED_DOCUMENT_FAILED,
  DRAWER_CLOSE,
  REMOVE_UPLOADED_DOCUMENT_LOADING,
  REMOVE_UPLOADED_DOCUMENT_SUCCESS,
  REMOVE_UPLOADED_DOCUMENT_FAILED,
  GET_DOCUMENT_BY_ID,
  CLEAR_DOCUMENT_FORM,
  UPDATE_UPLOADED_DOCUMENT_LOADING,
  UPDATE_UPLOADED_DOCUMENT_SUCCESS,
  UPDATE_UPLOADED_DOCUMENT_FAILED,
  GET_ALL_DOCUMENT_RESULTS_LOADING,
  GET_ALL_DOCUMENT_RESULTS_SUCCESS,
  GET_ALL_DOCUMENT_RESULTS_FAILED,
} from "../types";

export const createUploadedDocument = (
  newDocument,
  questionnaireResultId = null
) => (dispatch) => {
  dispatch({
    type: CREATE_UPLOADED_DOCUMENT_LOADING,
  });
  var url = "/api/documents";
  if (questionnaireResultId) url += `?replace=${questionnaireResultId}`;
  axiosWithAuth()
    .post(url, newDocument)
    .then((res) => {
      dispatch({
        type: CREATE_UPLOADED_DOCUMENT_SUCCESS,
        payload: { ...res.data.document, documentType: "paper" },
      });
      if (questionnaireResultId)
        dispatch({
          type: REMOVE_UPLOADED_DOCUMENT_SUCCESS,
          payload: questionnaireResultId,
        });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: CREATE_UPLOADED_DOCUMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const getDocumentsByPatient = (id) => (dispatch) => {
  dispatch({
    type: GET_UPLOADED_DOCUMENT_LOADING,
  });
  axiosWithAuth()
    .get(`/api/documents/patients/${id}`)
    .then((res) => {
      dispatch({
        type: GET_UPLOADED_DOCUMENT_SUCCESS,
        payload: res.data.documents,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_UPLOADED_DOCUMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const removePdfResult = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_UPLOADED_DOCUMENT_LOADING,
    payload: id,
  });
  let confirm = window.confirm("Are you sure you want to delete this result?");

  if (confirm) {
    axiosWithAuth()
      .delete(`/api/documents/${id}`)
      .then((res) => {
        dispatch({
          type: REMOVE_UPLOADED_DOCUMENT_SUCCESS,
          payload: id,
        });
      })
      .catch((err) => {
        console.error(err.response.data);
        dispatch({
          type: REMOVE_UPLOADED_DOCUMENT_FAILED,
          payload: err.response.data,
        });
      });
  }
};

export const getDocumentById = (id) => (dispatch) => {
  dispatch({
    type: GET_DOCUMENT_BY_ID,
    payload: id,
  });
};

export const clearDocumentForm = () => (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_FORM,
  });
};

export const updateDocument = (id, doc) => (dispatch) => {
  dispatch({
    type: UPDATE_UPLOADED_DOCUMENT_LOADING,
  });

  axiosWithAuth()
    .put(`/api/documents/${id}`, doc)
    .then((res) => {
      dispatch({
        type: UPDATE_UPLOADED_DOCUMENT_SUCCESS,
        payload: { ...res.data.document, documentType: "paper" },
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: UPDATE_UPLOADED_DOCUMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const getAllDocumentResults = () => (dispatch) => {
  dispatch({
    type: GET_ALL_DOCUMENT_RESULTS_LOADING,
  });
  axiosWithAuth()
    .get(`/api/documents/results`)
    .then((res) => {
      dispatch({
        type: GET_ALL_DOCUMENT_RESULTS_SUCCESS,
        payload: res.data.documents,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_ALL_DOCUMENT_RESULTS_FAILED,
        payload: err.response.data,
      });
    });
};
