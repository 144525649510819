import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  clearDocumentForm,
  createUploadedDocument,
  updateDocument,
} from "../../Redux/actions/documentActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  IconButton,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";

import useStyles from "../Auth/AuthFormStyles";

const AddDocument = ({
  user,
  admin,
  createUploadedDocument,
  document,
  clearDocumentForm,
  updateDocument,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (Object.keys(document).length > 0) {
      setTitle(document.title);
      setFile(document.link);
    }

    return () => clearDocumentForm();
  }, [document]);

  const onChangeTitleHandler = (e) => setTitle(e.target.value);

  const onFileChange = ({ target }) => setFile(target.files[0]);
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("title", title);
    formData.append("uploaded_by", admin.username);
    formData.append("patient_id", user.id);

    if (Object.keys(document).length > 0) {
      updateDocument(document.id, formData);
    } else {
      createUploadedDocument(formData);
    }
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add Document
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="title"
                label="Document Name"
                name="title"
                autoComplete="title"
                value={title}
                onChange={(e) => onChangeTitleHandler(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="application/pdf"
                className={classes.input}
                style={{ display: "none" }}
                id="add-uploaded_documents"
                type="file"
                onChange={onFileChange}
              />
              <label htmlFor="add-uploaded_documents">
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  startIcon={<AttachFileIcon />}
                  className={classes.button}
                  style={{ marginTop: 20 }}
                >
                  {file ? "Change Document" : "Add Document"}
                </Button>
              </label>
              {file && (
                <>
                  {file.name ? (
                    <Typography>{`${file.name} - ${file.size}KB`}</Typography>
                  ) : (
                    <Typography>{`URL: ${file}`}</Typography>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            Add Document
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  admin: state.authReducer.admin,
  document: state.documentReducer.document,
});

export default connect(mapStateToProps, {
  createUploadedDocument,
  clearDocumentForm,
  updateDocument,
})(AddDocument);
