import React from "react";
import { connect } from "react-redux";
import { clearResource } from "../../Redux/actions/educationActions";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Chip,
  Box,
  Link,
  Grid,
  CardMedia,
  Button,
} from "@material-ui/core";
import { formatDate } from "../../utils/formatData";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // padding: "0 16px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    marginTop: "30",
    border: "1px solid #ccc",
  },
}));

const ViewResource = ({ resource, clearResource }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12} style={{ padding: "10px 0" }}>
        <Button
          size="medium"
          onClick={() => clearResource()}
          color="primary"
          startIcon={<ArrowBackIcon />}
          className={classes.button}
        >
          Back
        </Button>
      </Grid>
      {resource && Object.keys(resource).length > 0 && (
        <div style={{ padding: 20, width: "100%" }}>
          <Grid item xs={12} container>
            <Grid
              item
              xs={12}
              tems="center"
              style={{ textAlign: "center", marginBottom: 15 }}
            >
              <Typography variant="h5" style={{ marginBottom: 5 }}>
                {resource.title}
              </Typography>
              <Typography style={{ marginBottom: 5 }}>
                {resource.description}
              </Typography>
              <Box style={{ marginBottom: 10 }}>
                {resource.medications && resource.medications.length > 0
                  ? resource.medications.map((m) => (
                      <Chip style={{ margin: "0 2px" }} label={m.name} />
                    ))
                  : null}
              </Box>
              <Typography variant="caption" style={{ color: "#576C75" }}>
                Created: {formatDate(resource.created_at)}
                {resource.updated_at && (
                  <>&nbsp;&nbsp; Updated: {formatDate(resource.updated_at)}</>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ margin: "auto" }}>
              {resource.image_url && (
                <>
                  <CardMedia
                    className={classes.media}
                    image={resource.image_url}
                  />

                  <Typography
                    align="center"
                    display="block"
                    variant="caption"
                    style={{ marginTop: 10 }}
                  >
                    Media URL:{" "}
                    <Link href={resource.image_url}>{resource.image_url}</Link>
                  </Typography>
                </>
              )}
              {resource.link && (
                <Typography
                  align="center"
                  display="block"
                  variant="caption"
                  style={{ marginTop: 10 }}
                >
                  Link URL: <Link href={resource.link}>{resource.link}</Link>
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingTop: 20,
              marginTop: 30,
              borderTop: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <Typography
              style={{
                lineHeight: 1.75,
                color: "#555",
                fontSize: 18,
                maxWidth: "70%",
                margin: "auto",
              }}
            >
              {resource.text}
            </Typography>
          </Grid>
        </div>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  resource: state.educationReducer.resource,
});

export default connect(mapStateToProps, {
  clearResource,
})(ViewResource);
