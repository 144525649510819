import React, { useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
    TableRow,
  TextField
} from "@material-ui/core";

import { formatDate, formatDateTime } from "../../utils/formatData";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ArchivedDialog({ open, setOpen, title, setTitle, handleArchive, handleClose, admin }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
            <Typography variant="h5" align="center">
                Create new conversation
            </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" alignContent="center" className={classes.container}>
        <Grid item className={classes.createConversation}>
          <Typography
            // variant="caption"
            align="center"
            style={{ paddingBottom: 10, fontSize: 12 }}
          >
            You are required to create a new conversation before the previous
            can be archived
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                // error={error.username ? true : false}
                // helperText={error.username && error.username}
                variant="outlined"
                required
                fullWidth
                id="title"
                label="Conversation Title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                // disabled={!typeSelected}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleArchive}
              >
                Create Conversation
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  archivedConversation: state.messageReducer.archivedConversation,
  admin: state.authReducer.admin,
});

export default connect(mapStateToProps, {})(ArchivedDialog);

const Bubble = ({ message, username, userId }) => {
  const classes = useStylesBubble();
  const images = [];

  if (message.images) {
    JSON.parse(message.images).forEach((image, index) => {
      images.push(
        <Image
          key={index}
          src={`${image}`}
          aspectRatio={4 / 5}
          imageStyle={{
            minWidth: "100%",
            width: "auto",
          }}
          color="#EEF0F1"
        />
      );
    });
  }

  return (
    <div
      className={
        message.sender_id === username
          ? classes.bubbleContainer2
          : classes.bubbleContainer1
      }
    >
      <div
        className={`${"bubble"} ${
          message.sender_id === username ? classes.bubble2 : classes.bubble1
        }`}
      >
        <Typography variant="caption">
          From: {message.f_name} {message.l_name}
        </Typography>
        {images}
        <Typography style={{ padding: 10 }}>{message.text}</Typography>
      </div>
      <Typography variant="caption" style={{ paddingLeft: 10 }}>
        {formatDateTime(message.created_at)}
      </Typography>
    </div>
  );
};

const StatusUpdate = ({ message, username, userId }) => {
  return (
    <div
      style={{ borderTop: "1px solid grey", borderBottom: "1px solid grey" }}
    >
      <Typography variant="h6" align="center" style={{ padding: 10 }}>
        {message.text}
        <br />
        By: {message.sender_id}
      </Typography>
      <Typography align="center" style={{ padding: 10 }}>
        {formatDate(message.created_at, "llll")}
      </Typography>
    </div>
  );
};

const useStylesBubble = makeStyles((theme) => ({
  bubbleContainer1: {
    width: "55%",
    margin: "10px auto 20px 10px",
    flexGrow: 0,
  },
  bubbleContainer2: {
    width: "55%",
    margin: "10px 10px 20px auto",
    flexGrow: 0,
  },
  bubble1: {
    width: "100%",
    padding: "5px 15px",
    borderRadius: 14,
    backgroundColor: "#F0F8FF",
    overflow: "auto",
  },
  bubble2: {
    width: "auto",
    padding: "5px 15px",
    borderRadius: 14,
    backgroundColor: "#EEF0F1",
    overflow: "auto",
  },
}));
