import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  removeQuestion,
  createDocument,
  addQuestion,
  updateDocument,
  getDocument,
  clearAddedQuestions,
  setAddedQuestions,
} from "../../Redux/actions/questionnaireActions";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Paper,
  IconButton,
} from "@material-ui/core";

import QuestionItem from "./QuestionItem";
import CreateQuestion from "../Forms/CreateQuestion";
import QuestionsLibrary from "./QuestionLibrary";

import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";

import useStyles from "../Auth/AuthFormStyles";

import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card } from "../Card";
import uuidv4 from "../../utils/uuidv4";

const CreateDocument = ({
  documentId,
  addedQuestions,
  removeQuestion,
  createDocument,
  document,
  addQuestion,
  updateDocument,
  getDocument,
  error,
  clearAddedQuestions,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [inst, setInst] = useState("");
  const [link, setLink] = useState(null);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (documentId && Object.keys(document).length === 0) {
      getDocument(documentId);
    }

    return () => clearAddedQuestions();
  }, []);

  useEffect(() => {
    if (Object.keys(document).length > 0) {
      setName(document.name);
      setInst(document.instructions);
      setLink(document.document_link);

      document.questions
        .sort((a, b) => a.order - b.order)
        .forEach((q) => {
          addQuestion(q);
        });
    }
  }, [document]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      type: "document",
      instructions: inst,
      name,
      questions: cards.map(({ id, question_to_questionnaire_id }, i) => ({
        id,
        order: i,
        question_to_questionnaire_id,
      })),
    };
    if (link && typeof link !== "string") formData.document_link = link.name;
    if (link && typeof link === "string") formData.document_link = link;

    if (Object.keys(document).length > 0) {
      updateDocument(document.id, formData);
    } else {
      createDocument(formData);
    }
  };

  const onFileChange = ({ target }) => {
    const file = target.files[0];
    setLink(file);
  };
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];

      setCards((prevCard) =>
        update(prevCard, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards]
  );

  useEffect(() => {
    setCards(
      addedQuestions
        .sort((a, b) => a.order - b.order)
        .map((q) => ({
          ...q,
          question_to_questionnaire_id:
            q.question_to_questionnaire_id || uuidv4(),
        }))
    );
  }, [addedQuestions]);

  useEffect(() => {
    setAddedQuestions(cards);
  }, [cards]);

  return (
    <Grid container item xs={12} component="main" style={{ padding: 20 }}>
      <CssBaseline />
      <form noValidate>
        <Box display="flex" p={1}>
          <Box
            flex={5}
            style={{
              maxHeight: "calc(100vh - 68px)",
              overflow: "auto",
              padding: 5,
              paddingRight: 20,
              borderRight: "1px solid lightgrey",
            }}
          >
            <Grid item xs={12}>
              <Button
                size="medium"
                href="/documents"
                color="primary"
                startIcon={<ArrowBackIcon />}
                className={classes.button}
              >
                {documentId ? "Back" : "Cancel"}
              </Button>
              <Typography
                variant="h4"
                style={{ fontWeight: 600, marginLeft: 10 }}
              >
                {documentId ? "Update Document" : "Create Document"}
              </Typography>
              <CreateQuestion doc />
              <QuestionsLibrary />
            </Grid>
          </Box>
          <Box
            flex={8}
            style={{
              maxHeight: "calc(100vh - 68px)",
              overflow: "auto",
            }}
          >
            <Grid item xs={12} style={{ padding: 20 }}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    error={error.name ? true : false}
                    helperText={error.name && error.name}
                    variant="standard"
                    required
                    fullWidth
                    id="assessment_name"
                    label="Document Name"
                    name="assessment_name"
                    autoComplete="assessment_name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    style={{ height: "100%" }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {documentId ? "Update" : "Save"}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={error.instructions ? true : false}
                    helperText={error.instructions && error.instructions}
                    variant="standard"
                    required
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    id="instructions"
                    label="Instructions"
                    name="instructions"
                    autoComplete="instructions"
                    value={inst}
                    onChange={(e) => setInst(e.target.value)}
                  />
                  <input
                    accept="application/pdf"
                    className={classes.input}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                    onChange={onFileChange}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      startIcon={<AttachFileIcon />}
                      className={[classes.button, {}]}
                      style={{ marginTop: 20 }}
                    >
                      {link ? "Change Document" : "Add Document"}
                    </Button>
                  </label>
                  {link && (
                    <>
                      {link.name ? (
                        <Typography>{`${link.name} - ${link.size}KB`}</Typography>
                      ) : (
                        <Button size="medium" href={link} color="primary">
                          {`URL: ${link}`}
                        </Button>
                      )}
                      <IconButton
                        onClick={() => setLink(null)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  {error.questions && (
                    <Typography
                      variant="body2"
                      id="error"
                      className={classes.errorText}
                    >
                      {error.questions}
                    </Typography>
                  )}
                  <DndProvider backend={HTML5Backend}>
                    <Grid
                      container
                      style={{
                        marginTop: 40,
                      }}
                    >
                      <Grid item xs={12}>
                        {cards.length > 0 &&
                          cards.map((q, i) => (
                            <Card
                              key={q.question_to_questionnaire_id}
                              index={i}
                              id={q.question_to_questionnaire_id}
                              moveCard={moveCard}
                            >
                              <Paper>
                                <QuestionItem
                                  data={q}
                                  remove={removeQuestion}
                                  edit={
                                    Object.keys(document).length > 0
                                      ? true
                                      : false
                                  }
                                />
                              </Paper>
                            </Card>
                          ))}
                      </Grid>
                    </Grid>
                  </DndProvider>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  addedQuestions: state.questionnaireReducer.addedQuestions.sort(
    (a, b) => a.order - b.order
  ),
  document: state.questionnaireReducer.document,
  error: state.questionnaireReducer.error,
});

export default connect(mapStateToProps, {
  removeQuestion,
  createDocument,
  addQuestion,
  updateDocument,
  getDocument,
  clearAddedQuestions,
  setAddedQuestions,
})(CreateDocument);
