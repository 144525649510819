import React from "react";
import { connect } from "react-redux";
import { openDrawer } from "../../Redux/actions/drawerActions";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Button, Grid } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const ActionsBar = ({ openDrawer, type }) => {
  return (
    <Toolbar>
      <Grid style={{ paddingBottom: 40 }} container>
        <Grid item xs={3}>
          <Typography variant="h4" style={{ marginLeft: 10 }}>
            {type} Messages
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            textAlign: "right",
            justifyContent: "flex-end",
          }}
        >
          {/* <Button
            color="primary"
            variant="contained"
            onClick={() => openDrawer("createUser")}
            style={{ }}
            startIcon={<PersonAddIcon />}
          >
            Add New User
          </Button> */}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, {
  openDrawer,
})(ActionsBar);
