import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Card,
  CardHeader,
  Grid,
  CardContent,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import EditIcon from "@material-ui/icons/Edit";
import { formatDate } from "../../utils/formatData";
import Snackbar from "../Snackbar/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderBottom: "1px solid #EEF0F1",
  },
}));

const RenderPaperResult = ({
  data,
  assess,
  view,
  upload,
  classes,
  removePdfResult,
  handleUpdate,
}) => {
  return (
    <Grid item className={classes.root}>
      <CardHeader
        action={
          <Grid container style={{ marginTop: 10 }}>
            <Grid item>
              <IconButton
                aria-label="delete"
                size="small"
                edge="end"
                href={data.link}
                style={{ marginRight: 5, marginLeft: 5 }}
              >
                <GetAppIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                size="small"
                edge="end"
                onClick={() => handleUpdate(data.id)}
                style={{ marginRight: 5, marginLeft: 5 }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                size="small"
                edge="end"
                onClick={() => removePdfResult(data.id)}
                style={{ marginRight: 5, marginLeft: 5 }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
        title={data.title}
        subheader={
          <>
            {data.updated_at
              ? `Updated At: ${formatDate(data.updated_at, "MM/DD/YYYY")}`
              : `Created At: ${formatDate(data.created_at, "MM/DD/YYYY")}`}
          </>
        }
        titleTypographyProps={{ variant: "subtitle2" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
    </Grid>
  );
};

const RenderQuestionnaireResult = ({
  data,
  assess,
  view,
  upload,
  classes,
  sendReminder,
  updateQuestionnaire,
  setTab,
}) => {
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  return (
    <Grid item className={classes.root}>
      <Snackbar
        openSnackbar={openSnack}
        handleCloseSnackbar={handleCloseSnackbar}
        message={"Reminder sent successfully!"}
      />
      <CardHeader
        action={
          <Grid container style={{ marginTop: 10 }}>
            <Grid item>
              {!data.completed && data.assigned ? (
                <>
                  <Grid item xs={12} style={{ paddingBottom: 5 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => view(true)}
                    >
                      Edit Result
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        sendReminder(
                          data.type,
                          data.name,
                          data.id,
                          data.patient_id,
                          setOpenSnack
                        );
                        updateQuestionnaire(
                          data,
                          `Reminder sent for ${data.type}: ${data.name}`
                        );
                        setTab(e, 0);
                      }}
                    >
                      Send Reminder
                    </Button>
                  </Grid>
                </>
              ) : data.status === "request_email" ? (
                <>
                  <Typography
                    color="textSecondary"
                    style={{
                      textTransform: "uppercase",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Requested by Email
                  </Typography>
                  <input
                    accept="application/pdf"
                    className={classes.input}
                    style={{ display: "none" }}
                    id="replace-uploaded_documents"
                    type="file"
                    onChange={upload}
                  />
                  <label htmlFor="replace-uploaded_documents">
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      fullWidth
                      startIcon={<AttachFileIcon />}
                      className={classes.button}
                    >
                      Upload Results
                    </Button>
                  </label>
                </>
              ) : (
                <>
                  <Grid item xs={12} style={{ paddingBottom: 5 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => view(true)}
                    >
                      View Result
                    </Button>
                  </Grid>
                  {!data.assigned && (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={(e) => {
                          sendReminder(
                            data.type,
                            data.name,
                            data.id,
                            data.patient_id,
                            setOpenSnack
                          );
                          updateQuestionnaire(
                            data,
                            `Patient was assigned ${data.type}: ${data.name}`
                          );
                          setTab(e, 0);
                        }}
                      >
                        Send Assignment
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        }
        title={data.name}
        subheader={
          data.completed
            ? `Taken At: ${formatDate(data.taken_at, "MM/DD/YYYY")}`
            : `Assigned At: ${formatDate(data.created_at, "MM/DD/YYYY")}`
        }
        titleTypographyProps={{ variant: "subtitle2" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
    </Grid>
  );
};

const QuestionnaireResult = (props) => {
  const classes = useStyles();

  return props.data.documentType === "paper" ? (
    <RenderPaperResult {...props} classes={classes} />
  ) : (
    <RenderQuestionnaireResult {...props} classes={classes} />
  );
};

export default QuestionnaireResult;
