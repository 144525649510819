import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILED,
  CREATE_NOTIFICATION_LOADING,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILED,
  DRAWER_CLOSE,
} from "../types";

export const getNotifications = () => (dispatch) => {
  dispatch({
    type: GET_NOTIFICATIONS_LOADING,
  });
  axiosWithAuth()
    .get("/api/notifications/created")
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: res.data.notifications,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_NOTIFICATIONS_FAILED,
        payload: err.response.data,
      });
    });
};

export const createNotification = (newNotification) => (dispatch) => {
  dispatch({
    type: CREATE_NOTIFICATION_LOADING,
  });
  axiosWithAuth()
    .post("/api/notifications/add", newNotification)
    .then((res) => {
      dispatch({
        type: CREATE_NOTIFICATION_SUCCESS,
        payload: res.data.notification,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: CREATE_NOTIFICATION_FAILED,
        payload: err.response.data,
      });
    });
};
