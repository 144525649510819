import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Card,
  CardHeader,
  Grid,
  CardContent,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { formatDate } from "../../utils/formatData";
import Snackbar from "../Snackbar/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderBottom: "1px solid #EEF0F1",
  },
}));

const QuestionnaireResult = ({
  data,
  assess,
  view,
  upload,
  sendReminder,
  updateQuestionnaire,
  setTab,
}) => {
  const classes = useStyles();
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  return (
    <Grid item className={classes.root}>
      <Snackbar
        openSnackbar={openSnack}
        handleCloseSnackbar={handleCloseSnackbar}
        message={"Assigment sent successfully!"}
      />
      <CardHeader
        action={
          <Grid container style={{ marginTop: 10 }} align="right">
            {!data.completed && data.assigned ? (
              <>
                <Grid item xs={12} style={{ paddingBottom: 5 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => view(true)}
                  >
                    Edit Result
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      sendReminder(
                        data.type,
                        data.name,
                        data.id,
                        data.patient_id,
                        setOpenSnack
                      );
                      updateQuestionnaire(
                        data,
                        `Reminder sent for ${data.type}: ${data.name}`
                      );
                      setTab(e, 0);
                    }}
                  >
                    Send Reminder
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} style={{ paddingBottom: 5 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => view(true)}
                  >
                    View Result
                  </Button>
                </Grid>
                {!data.assigned && (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        data.assigned = true;
                        sendReminder(
                          data.type,
                          data.name,
                          data.id,
                          data.patient_id,
                          setOpenSnack
                        );
                        updateQuestionnaire(
                          data,
                          `Patient was assigned ${data.type}: ${data.name}`
                        );
                        setTab(e, 0);
                      }}
                    >
                      Send Assignment
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        }
        title={data.name}
        subheader={
          data.completed
            ? `Taken At: ${formatDate(data.taken_at, "MM/DD/YYYY")}`
            : `Assigned At: ${formatDate(data.created_at, "MM/DD/YYYY")}`
        }
        titleTypographyProps={{ variant: "subtitle2" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
    </Grid>
  );
};

export default QuestionnaireResult;
