import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createNotification } from "../../Redux/actions/notificationActions";
import { createAdmin } from "../../Redux/actions/adminActions";
import { getUsers } from "../../Redux/actions/userActions";
import { getTherapies } from "../../Redux/actions/medicationActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Tooltip,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  Tabs,
  Tab,
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";

import SendByMedications from "./SendAssessments/SendByMedications";
import SendByPatients from "./SendAssessments/SendByPatients";
import SendByPlans from "./SendAssessments/SendByPlans";
import SendByTherapies from "./SendAssessments/SendByTherapies";

import useStyles from "../Auth/AuthFormStyles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const SendAssessments = ({
  getUsers,
  users,
  createNotification,
  getTherapies,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    getUsers();
    getTherapies();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <NotificationsNoneOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Assign Assessments
        </Typography>
        <Grid item xs={12} className={classes.container}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Patients" />
            <Tab label="Medications" />
            <Tab label="Plans" />
            <Tab label="Therapies" />
          </Tabs>
          <TabPanel value={value} index={0} className={classes.tab}>
            <SendByPatients />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tab}>
            <SendByMedications />
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.tab}>
            <SendByPlans />
          </TabPanel>
          <TabPanel value={value} index={3} className={classes.tab}>
            <SendByTherapies />
          </TabPanel>
        </Grid>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  create_loading: state.adminReducer.create_loading,
  success: state.adminReducer.success,
  error: state.adminReducer.error,
  users: state.userReducer.users,
});

export default connect(mapStateToProps, {
  createAdmin,
  getUsers,
  createNotification,
  getTherapies,
})(SendAssessments);
