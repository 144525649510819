import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";

import Auth from "./Screens/Auth";
import Admins from "./Screens/Admins";
import Dashboard from "./Screens/Dashboard";
import Users from "./Screens/Users";
import Messages from "./Screens/Messages";
import User from "./Screens/User";
import Assessments from "./Screens/Assessments";
import AssessmentsCreate from "./Screens/AssessmentsCreate";
import ViewAssessment from "./Screens/ViewAssessment";
import Documents from "./Screens/Documents";
import DocumentsCreate from "./Screens/DocumentsCreate";
import ViewDocument from "./Screens/ViewDocument";
import Surveys from "./Screens/Surveys";
import SurveysCreate from "./Screens/SurveysCreate";
import ViewSurvey from "./Screens/ViewSurvey";
import Education from "./Screens/Education";
import Notifications from "./Screens/Notifications";
import Medications from "./Screens/Medications";

export default () => {
  return (
    <>
      <Route
        path="/signin/:var"
        render={(props) => <Auth history={props.history} match={props.match} />}
      />
      <ProtectedRoute exact path={["/", "/dashboard"]} component={Dashboard} />
      <ProtectedRoute exact path="/patients" component={Users} />
      <ProtectedRoute exact path="/patients/:id" component={User} />
      <ProtectedRoute
        exact
        path="/patients/:id/:chat/:activeId"
        component={User}
      />
      <ProtectedRoute
        exact
        path="/assessmentMessages"
        component={Messages}
        type="assessment"
      />
      <ProtectedRoute
        exact
        path="/documentMessages"
        component={Messages}
        type="document"
      />
      <ProtectedRoute
        exact
        path="/surveyMessages"
        component={Messages}
        type="survey"
      />
      <ProtectedRoute
        exact
        path="/carelogMessages"
        component={Messages}
        type="carelog"
      />
      <ProtectedRoute
        exact
        path="/patientMessages"
        component={Messages}
        type="patient"
      />
      <ProtectedRoute exact path="/assessments" component={Assessments} />
      <ProtectedRoute
        exact
        path="/assessments/create"
        component={AssessmentsCreate}
      />
      <ProtectedRoute
        exact
        path="/assessments/edit/:id"
        component={AssessmentsCreate}
      />
      <ProtectedRoute
        exact
        path="/assessments/view/:id"
        component={ViewAssessment}
      />
      <ProtectedRoute exact path="/documents" component={Documents} />
      <ProtectedRoute
        exact
        path="/documents/create"
        component={DocumentsCreate}
      />
      <ProtectedRoute
        exact
        path="/documents/edit/:id"
        component={DocumentsCreate}
      />
      <ProtectedRoute
        exact
        path="/documents/view/:id"
        component={ViewDocument}
      />
      <ProtectedRoute exact path="/surveys" component={Surveys} />
      <ProtectedRoute exact path="/surveys/create" component={SurveysCreate} />
      <ProtectedRoute
        exact
        path="/surveys/edit/:id"
        component={SurveysCreate}
      />
      <ProtectedRoute exact path="/surveys/view/:id" component={ViewSurvey} />
      <ProtectedRoute exact path="/education" component={Education} />
      <ProtectedRoute exact path="/notifications" component={Notifications} />
      <ProtectedRoute exact path="/medications" component={Medications} />
      <ProtectedRoute exact path="/admins" component={Admins} />
    </>
  );
};
