import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  clearSurvey,
  deleteConnectedQuestion,
  getSurvey,
} from "../Redux/actions/questionnaireActions";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button, Toolbar } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AssignmentIcon from "@material-ui/icons/Assignment";

import hasPermission from "../utils/hasPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    overflowY: "auto",
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

const ViewSurvey = ({
  clearSurvey,
  deleteConnectedQuestion,
  survey,
  getSurvey,
  match,
  history,
  logged_in_permissions,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getSurvey(match.params.id);
  }, []);

  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <Grid
        item
        xs={12}
        style={{
          height: 50,
          margin: "20px auto",
        }}
      >
        <Toolbar>
          <Grid
            style={{
              paddingBottom: 40,
            }}
            container
          >
            <Grid item xs={3}>
              <Typography
                variant="h4"
                style={{
                  marginLeft: 10,
                }}
              >
                Surveys
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                textAlign: "right",
                justifyContent: "flex-end",
              }}
            >
              {hasPermission("CRUD_QUESTIONNAIRE", logged_in_permissions) && (
                <Button
                  color="primary"
                  variant="contained"
                  href={`/surveys/create/`}
                  style={{}}
                  startIcon={<AssignmentIcon />}
                >
                  Create New Survey
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: 26 }}>
        <Button
          size="medium"
          onClick={() => {
            clearSurvey();
            history.goBack();
          }}
          color="primary"
          startIcon={<ArrowBackIcon />}
          className={classes.button}
        >
          Back
        </Button>
      </Grid>
      {Object.keys(survey).length > 0 && (
        <div style={{ padding: "24px 34px", width: "100%" }}>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              {survey.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: 10 }}>
              {survey.instructions}
            </Typography>
          </Grid>
          {survey.questions.length > 0 ? (
            survey.questions.map((s) => (
              <Grid
                item
                container
                style={{
                  paddingTop: 30,
                  paddingBottom: 30,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
                    {s.body || s.description}
                  </Typography>
                </Grid>

                {s.answers &&
                  s.answers.length > 0 &&
                  s.answers.map((a) => (
                    <Grid item xs={3}>
                      <Typography variant="body2" style={{ color: "#576C75" }}>
                        {a}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            ))
          ) : (
            <Typography style={{ color: "#576C75" }}>
              No questions have been added to this assessment yet
            </Typography>
          )}
        </div>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  survey: state.questionnaireReducer.survey,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {
  clearSurvey,
  deleteConnectedQuestion,
  getSurvey,
})(ViewSurvey);
