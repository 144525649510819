import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createNotification } from "../../Redux/actions/notificationActions";
import { createAdmin } from "../../Redux/actions/adminActions";
import { getUsers } from "../../Redux/actions/userActions";
import { getTherapies } from "../../Redux/actions/medicationActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Tooltip,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  Tabs,
  Tab,
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";

import PatientsNotifications from "./CreateNotifications/PatientsNotifications";
import MedicationsNotifications from "./CreateNotifications/MedicationsNotifications";
import PlansNotifications from "./CreateNotifications/PlansNotifications";
import TherapiesNotifications from "./CreateNotifications/TherapiesNotifications";

import useStyles from "../Auth/AuthFormStyles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const CreateNotification = ({
  getUsers,
  users,
  createNotification,
  getTherapies,
}) => {
  const classes = useStyles();
  const [recipients, setRecipients] = useState([]);
  const [value, setValue] = useState(0);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });

  const onSelect = (e) => {
    setRecipients(e.target.value);
  };

  const onChangeHandler = (e) => {
    setNotification({ ...notification, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createNotification({ recipients, ...notification });
  };

  useEffect(() => {
    getUsers();
    getTherapies();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <NotificationsNoneOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Send New Notification
        </Typography>
        <Grid item xs={12} className={classes.container}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Patients" />
            <Tab label="Medications" />
            <Tab label="Plans" />
            <Tab label="Therapies" />
          </Tabs>
          <TabPanel value={value} index={0} className={classes.tab}>
            <PatientsNotifications />
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tab}>
            <MedicationsNotifications />
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.tab}>
            <PlansNotifications />
          </TabPanel>
          <TabPanel value={value} index={3} className={classes.tab}>
            <TherapiesNotifications />
          </TabPanel>
        </Grid>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  create_loading: state.adminReducer.create_loading,
  success: state.adminReducer.success,
  error: state.adminReducer.error,
  users: state.userReducer.users,
});

export default connect(mapStateToProps, {
  createAdmin,
  getUsers,
  createNotification,
  getTherapies,
})(CreateNotification);
