import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutAdmin } from "../../Redux/actions/authActions";
import { getLoggedInPermissions } from "../../Redux/actions/adminActions";
import { getAllMessages, getUnseenMessages } from "../../Redux/actions/messageActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
} from "@material-ui/core";

import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ThumbsUpDownOutlinedIcon from "@material-ui/icons/ThumbsUpDownOutlined";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import HealingIcon from "@material-ui/icons/Healing";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  container: {
    // width: "calc(100vw - 20px)",
    // height: "calc(100vh - 40px)",
    padding: "20px 20px 20px 0",
    backgroundColor: "#1E252B",
    overflow: "hidden",
  },
  signin: {
    padding: 0,
    backgroundColor: "#1E252B",
  },
  content: {
    backgroundColor: "white",
    // margin: "20px -20px",
    height: "calc(100vh - 40px)",
    width: "calc(100% - 20px)",
    borderRadius: 20,
  },
  logoBackground: {
    backgroundColor: "#fff",
    height: 50,
    width: 50,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    padding: 5,
  },
  title: {
    textAlign: "left",
    paddingLeft: 20,
    paddingTop: 20,
  },
  gridItem: {
    textAlign: "center",
    color: "white",
  },
  listContainer: {
    width: "100%",
    // maxWidth: 360,
  },
  listItem: {
    opacity: 0.7,
  },
  selected: {
    opacity: 1,
  },
  icon: {
    color: "white",
    margin: "auto",
  },
  relative: {
    position: "relative",
  },
  pointer: {
    width: 24,
    height: 24,
    backgroundColor: "white",
    position: "absolute",
    right: 0,
    transform: "rotate(45deg)",
    marginRight: -12,
    borderBottomLeftRadius: 3,
  },
}));

const navObjects = [
  {
    title: "Dashboard",
    icon: InsertChartOutlinedIcon,
    link: "/dashboard",
  },
  // {
  //   title: "Messages",
  //   icon: ChatBubbleOutlineIcon,
  //   link: "/messages",
  // },
  {
    title: "Messages",
    icon: ChatBubbleOutlineIcon,
    subLinks: [
      {
        title: "Assessments",
        type: "assessment",
        name: "assessmentMessages",
        icon: AssignmentOutlinedIcon,
        link: "/assessmentMessages",
      },
      {
        title: "Documents",
        type: "document",
        name: "documentMessages",
        icon: DescriptionOutlinedIcon,
        link: "/documentMessages",
      },
      {
        title: "Surveys",
        type: "survey",
        name: "surveyMessages",
        icon: ThumbsUpDownOutlinedIcon,
        link: "/surveyMessages",
      },
      {
        title: "Care Logs",
        type: "carelog",
        name: "carelogMessages",
        icon: ThumbsUpDownOutlinedIcon,
        link: "/carelogMessages",
      },
      {
        title: "Patients",
        type: "patient",
        name: "patientMessages",
        icon: ThumbsUpDownOutlinedIcon,
        link: "/patientMessages",
      },
    ],
  },
  {
    title: "Forms",
    icon: ListAltIcon,
    subLinks: [
      {
        title: "Assessments",
        icon: AssignmentOutlinedIcon,
        link: "/assessments",
      },
      {
        title: "Documents",
        icon: DescriptionOutlinedIcon,
        link: "/documents",
      },
      {
        title: "Surveys",
        icon: ThumbsUpDownOutlinedIcon,
        link: "/surveys",
      },
    ],
  },
  {
    title: "Patients",
    icon: PersonOutlineOutlinedIcon,
    link: "/patients",
  },
  {
    title: "Settings",
    icon: SettingsIcon,
    subLinks: [
      {
        title: "Education",
        icon: BookOutlinedIcon,
        link: "/education",
      },
      {
        title: "Medications",
        icon: HealingIcon,
        link: "/medications",
      },
      {
        title: "Admins",
        icon: PersonOutlineOutlinedIcon,
        link: "/admins",
      },
    ],
  },
  {
    title: "Notifications",
    icon: NotificationsNoneOutlinedIcon,
    link: "/notifications",
  },
  {
    title: "Logout",
    icon: ExitToAppIcon,
    link: "/signin/0",
  },
];

function ListItemLink(props) {
  return (
    <Link
      to={props.link}
      style={{
        color: "white",
        textDecoration: "none",
      }}
    >
      <ListItem button {...props} />{" "}
    </Link>
  );
}

function Pointer({ classes }) {
  return <div className={classes.pointer} />;
}

function NavItem({
  classes,
  title,
  Icon,
  selected,
  setSelected,
  link,
  badge,
  unseen,
  logout,
  subLinks,
  type,
  messageTot,
  setMessageTot,
  unseenTot,
  setUnreadTot
}) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  if (subLinks.length > 0) {
    return (
      <>
        <ListItem
          button
          onClick={handleClick}
          style={{
            opacity: "0.7",
            width: "90%",
          }}
        >
          <ListItemIcon>
            <Icon className={classes.icon} />
          </ListItemIcon>
          {badge ? (
            <>
              <ListItemText primary={title} />
              <div
                style={{
                  display: "absolute",
                  left: 0,
                  marginTop: -10,
                  width: 18,
                }}
              >
                <Badge
                  badgeContent={unseenTot > 0 ? unseenTot : 0}
                  color="primary"
                />
              </div>
            </>
          ) : (
            <ListItemText primary={title} />
          )}

          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginLeft: 10 }}>
            {subLinks.map((l, i) => {
              let count = 0;
              for (const prop in unseen) {
                if (!unseen[prop].seen && unseen[prop].type === l.type) {
                  count += 1;
                }
              }
              let SlIcon = l.icon;
              return (
                <ListItemLink
                  key={i}
                  className={`${classes.relative} ${classes.listItem}`}
                  style={{
                    opacity:
                      selected === l.title.toLowerCase() && !l.name
                        ? "1"
                        : selected === l.name
                        ? "1"
                        : "0.7",
                  }}
                  onClick={() =>
                    setSelected(l.name ? l.name : l.title.toLowerCase())
                  }
                  link={l.link}
                >
                  <ListItemIcon>
                    <SlIcon className={classes.icon} />
                  </ListItemIcon>

                  {badge ? (
                    <>
                      <ListItemText primary={l.title} />
                      <div
                        style={{
                          display: "absolute",
                          left: 0,
                          marginTop: -5,
                          marginRight: 5,
                          width: 18,
                        }}
                      >
                        <Badge 
                          badgeContent={count} color="primary" 
                        />
                      </div>
                    </>
                  ) : (
                    <ListItemText primary={l.title} />
                  )}
                  {selected === l.title.toLowerCase() && !l.name ? (
                    <Pointer classes={classes} />
                  ) : selected === l.name ? (
                    <Pointer classes={classes} />
                  ) : null}
                </ListItemLink>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <ListItemLink
        className={`${classes.relative} ${classes.listItem}`}
        style={{
          opacity: selected === title.toLowerCase() ? "1" : "0.7",
        }}
        onClick={
          title == "Logout" ? logout : () => setSelected(title.toLowerCase())
        }
        link={link}
      >
        <ListItemIcon>{Icon && <Icon className={classes.icon} />}</ListItemIcon>
        <ListItemText primary={title} />
        {selected === title.toLowerCase() && <Pointer classes={classes} />}{" "}
      </ListItemLink>
    );
  }
}

const Sidebar = ({
  children,
  isAuthenticated,
  all_messages,
  unseen_messages,
  logoutAdmin,
  getLoggedInPermissions,
  getAllMessages,
  getUnseenMessages,
  admin,
}) => {
  const [selected, setSelected] = useState("dashboard");
  const [path, setPath] = useState("dashboard");
  const [messageTot, setMessageTot] = useState(0);
  const [unseenTot, setUnreadTot] = useState(0);
  const classes = useStyles();

  let signin = false;

  useEffect(() => {
    setPath(window.location.pathname.split("/")[1]);
  });

  useEffect(() => {
    fetchAllMessages();
  }, []);

  useEffect(() => {
    fetchNewMessages();
  }, []);

  useEffect(() => {
    setMessageTot(Object.keys(all_messages).length);
    console.log(all_messages);
  }, [all_messages]);

  useEffect(() => {
    setUnreadTot(Object.keys(unseen_messages).length);
    console.log(unseen_messages);
  }, [unseen_messages]);

  useEffect(() => {
    getLoggedInPermissions(admin.username);
  }, [admin.username]);

  useEffect(() => {
    if (path === "") {
      setSelected("dashboard");
    } else {
      setSelected(path);
    }
    if (path === "signin") {
      signin = true;
    } else if (path === "dashboard") {
      signin = false;
    }
  }, [path]);

  const fetchAllMessages = () => {
    setInterval(function allInt() {
      getAllMessages(admin.username);
      return allInt;
    }(), 1 * 1000);
  };

  const fetchNewMessages = () => {
    setInterval(function unseenInt() {
      getUnseenMessages(admin.username);
      return unseenInt;
    }(), 1 * 1000);
  };

  return (
    <div className={signin ? classes.signin : classes.container}>
      {isAuthenticated ? (
        <Grid container>
          <Grid item xs={2} className={classes.gridItem}>
            <h3 className={classes.title}>
              <div className={classes.logoBackground}>
                <img
                  src={require("../../assets/img/CottrillsCare-App-topleft-dashboard.png")}
                  width={50}
                  height={50}
                />
              </div>
            </h3>
            <div className={classes.listContainer}>
              <List component="nav">
                {isAuthenticated ? (
                  navObjects.map((n, i) => (
                    <NavItem
                      key={`navItem-${i}`}
                      classes={classes}
                      title={n.title}
                      Icon={n.icon}
                      selected={selected}
                      setSelected={setSelected}
                      link={n.link}
                      subLinks={"subLinks" in n ? n.subLinks : []}
                      badge={n.title === "Messages"}
                      unseen={all_messages}
                      logout={(e) => logoutAdmin(e)}
                      messageTot={messageTot}
                      unseenTot={unseenTot}
                    />
                  ))
                ) : (
                  <NavItem
                    key={`navItem-signin`}
                    classes={classes}
                    title="Signin"
                    Icon={PersonOutlineOutlinedIcon}
                    selected={true}
                  />
                )}{" "}
              </List>{" "}
            </div>{" "}
          </Grid>{" "}
          <Grid item xs={10} className={classes.content}>
            {" "}
            {children}{" "}
          </Grid>{" "}
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} className={classes.gridItem}>
            {children}
          </Grid>
        </Grid>
      )}{" "}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  all_messages: state.messageReducer.all_messages,
  unseen_messages: state.messageReducer.unseen_messages,
  admin: state.authReducer.admin,
});

export default connect(mapStateToProps, {
  logoutAdmin,
  getLoggedInPermissions,
  getAllMessages,
  getUnseenMessages,
})(Sidebar);
