import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  CREATE_PRESCRIPTION_LOADING,
  CREATE_PRESCRIPTION_SUCCESS,
  CREATE_PRESCRIPTION_FAILED,
  UPDATE_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_LOADING,
  GET_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_FAILED,
  GET_PRESCRIPTIONS_PLUS_LOADING,
  GET_PRESCRIPTIONS_PLUS_SUCCESS,
  GET_PRESCRIPTIONS_PLUS_FAILED,
  GET_PRESCRIPTION_PLUS_LOADING,
  GET_PRESCRIPTION_PLUS_SUCCESS,
  GET_PRESCRIPTION_PLUS_FAILED,
  GET_PRESCRIPTIONS_PLUS_BY_USER_LOADING,
  GET_PRESCRIPTIONS_PLUS_BY_USER_SUCCESS,
  GET_PRESCRIPTIONS_PLUS_BY_USER_FAILED,
  DELETE_PRESCRIPTION_LOADING,
  DELETE_PRESCRIPTION_SUCCESS,
  DELETE_PRESCRIPTION_FAILED,
  GET_PRESCRIPTION_HISTORY_LOADING,
  GET_PRESCRIPTION_HISTORY_SUCCESS,
  GET_PRESCRIPTION_HISTORY_FAILED,
  CLEAR_PRESCRIPTION_HISTORY,
  SET_STATUS_TRACKING_LOADING,
  SET_STATUS_TRACKING_SUCCESS,
  SET_STATUS_TRACKING_FAILED,
  DRAWER_CLOSE,
  UPDATE_TRACKING_SUCCESS,
  ADD_CONVERSATION,
} from "../types";

export const createPrescription = (newPrescription) => (dispatch) => {
  dispatch({ type: CREATE_PRESCRIPTION_LOADING });
  axiosWithAuth()
    .post("/api/prescriptions/", newPrescription)
    .then((res) => {
      dispatch({
        type: CREATE_PRESCRIPTION_SUCCESS,
        payload: res.data.prescription,
      });
      dispatch({
        type: ADD_CONVERSATION,
        payload: res.data.conversation,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: CREATE_PRESCRIPTION_FAILED,
        payload: err.response.data,
      });
    });
};

export const updatePrescription = (updatedPrescription, id) => (dispatch) => {
  dispatch({ type: CREATE_PRESCRIPTION_LOADING });
  axiosWithAuth()
    .put(`/api/prescriptions/${id}`, updatedPrescription)
    .then((res) => {
      dispatch({
        type: UPDATE_PRESCRIPTION_SUCCESS,
        payload: res.data.prescription,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: CREATE_PRESCRIPTION_FAILED,
        payload: err.response.data,
      });
    });
};

export const getPrescriptionsPlus = () => (dispatch) => {
  dispatch({ type: GET_PRESCRIPTIONS_PLUS_LOADING });

  axiosWithAuth()
    .get("/api/prescriptions/plus")
    .then((res) => {
      dispatch({
        type: GET_PRESCRIPTIONS_PLUS_SUCCESS,
        payload: res.data.prescriptions,
        requested: res.data.prescriptions.filter(
          (p) => p.status === "requested"
        ),
        inProcess: res.data.prescriptions.filter(
          (p) => p.status === "inProcess"
        ),
        processed: res.data.prescriptions.filter(
          (p) => p.status === "processed"
        ),
        shipped: res.data.prescriptions.filter((p) => p.status === "shipped"),
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_PRESCRIPTIONS_PLUS_FAILED,
      });
    });
};

export const getPrescriptionPlus = (id) => (dispatch) => {
  dispatch({ type: GET_PRESCRIPTION_PLUS_LOADING });
  axiosWithAuth()
    .get(`/api/prescriptions/plus/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PRESCRIPTION_PLUS_SUCCESS,
        payload: res.data.prescriptions,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_PRESCRIPTION_PLUS_FAILED,
      });
    });
};

export const getPrescriptionsPlusByUser = (id) => (dispatch) => {
  dispatch({ type: GET_PRESCRIPTIONS_PLUS_BY_USER_LOADING });
  axiosWithAuth()
    .get(`/api/prescriptions/patient/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PRESCRIPTIONS_PLUS_BY_USER_SUCCESS,
        payload: res.data.prescriptions,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_PRESCRIPTIONS_PLUS_BY_USER_FAILED,
      });
    });
};

export const deletePrescription = (id, username) => (dispatch) => {
  dispatch({
    type: DELETE_PRESCRIPTION_LOADING,
  });
  const confirmed = window.confirm(
    "Deleting this prescription will delete all information in regards to this prescription including it's status history. Are you sure you want to proceed?"
  );
  if (confirmed) {
    axiosWithAuth()
      .delete(`/api/prescriptions/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_PRESCRIPTION_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.error(err.response.data);
        dispatch({
          type: DELETE_PRESCRIPTION_FAILED,
        });
      });
  }
};

export const getPrescriptionHistory = (id) => (dispatch) => {
  dispatch({
    type: GET_PRESCRIPTION_HISTORY_LOADING,
  });
  axiosWithAuth()
    .get(`/api/statusHistory/prescription/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PRESCRIPTION_HISTORY_SUCCESS,
        payload: res.data.statusHistory,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_PRESCRIPTION_HISTORY_FAILED,
      });
    });
};

export const setStatusTracking = (id, tracking_link) => (dispatch) => {
  dispatch({
    type: SET_STATUS_TRACKING_LOADING,
  });
  axiosWithAuth()
    .put(`/api/statusHistory/tracking/prescription/${id}`, {
      tracking_link,
    })
    .then((res) => {
      dispatch({
        type: UPDATE_TRACKING_SUCCESS,
        payload: { id, tracking_link },
      });
      dispatch({ type: DRAWER_CLOSE });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: SET_STATUS_TRACKING_FAILED,
      });
    });
};

export const clearPrescriptionHistory = () => (dispatch) => {
  dispatch({
    type: CLEAR_PRESCRIPTION_HISTORY,
  });
};
