import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  clearMedication,
  createMedication,
  updateMedication,
  getTherapies,
} from "../../Redux/actions/medicationActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import useStyles from "../Auth/AuthFormStyles";

const CreateMedication = ({
  createMedication,
  clearMedication,
  updateMedication,
  medication,
  error,
  therapies,
  getTherapies,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [therapy, setTherapy] = useState("");

  useEffect(() => {
    if (Object.keys(medication).length > 0) {
      setName(medication.name);
      setTherapy(medication.therapy);
    }
  }, [medication]);

  useEffect(() => {
    getTherapies();
    return () => {
      clearMedication();
      setName("");
      setTherapy("");
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(medication).length > 0) {
      updateMedication({ name, therapy }, medication.id);
    } else {
      createMedication({ name, therapy });
    }
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {Object.keys(medication).length > 0
            ? "Update Medication"
            : "Create New Medication"}
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={error.name ? true : false}
                helperText={error.name && error.name}
                variant="outlined"
                required
                fullWidth
                value={name}
                name="Name"
                label="Name"
                id="name"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                // error={error.medication_id ? true : false}
              >
                <InputLabel id="therapy-select-label">Therapy</InputLabel>
                <Select
                  labelId="therapy-select-label"
                  error={error["therapy"] ? true : false}
                  id="therapy-select"
                  value={therapy}
                  onChange={(e) => setTherapy(e.target.value)}
                  label="Therapy"
                  style={{ width: "100%" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {therapies &&
                    therapies.length > 0 &&
                    therapies.map((t) => (
                      <MenuItem value={t.name} key={t.name}>
                        {t.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )}
          <Button
            // disabled={!typeSelected}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {Object.keys(medication).length > 0 ? "Update" : "Create"}
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  medication: state.medicationReducer.medication,
  therapies: state.medicationReducer.therapies,
  error: state.medicationReducer.error,
});

export default connect(mapStateToProps, {
  createMedication,
  clearMedication,
  updateMedication,
  getTherapies,
})(CreateMedication);
