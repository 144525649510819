import {
  GET_RESOURCES_LOADING,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILED,
  GET_RESOURCE_LOADING,
  GET_RESOURCE_SUCCESS,
  GET_RESOURCE_FAILED,
  CREATE_RESOURCE_LOADING,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RESOURCE_FAILED,
  UPDATE_RESOURCE_LOADING,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILED,
  CLEAR_RESOURCE,
} from "../types";

import isEmpty from "../../utils/isEmpty";

const initialState = {
  get_resources_loading: false,
  get_resource_loading: false,
  create_resource_loading: false,
  resources: [],
  resource: {},
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RESOURCES_LOADING:
      return {
        ...state,
        get_resources_loading: true,
        error: {},
      };
    case GET_RESOURCES_SUCCESS:
      return {
        ...state,
        get_resources_loading: false,
        resources: action.payload,
      };
    case GET_RESOURCES_FAILED:
      return {
        ...state,
        get_resources_loading: false,
        error: action.payload,
      };
    case GET_RESOURCE_LOADING:
      return {
        ...state,
        get_resource_loading: true,
        error: {},
      };
    case GET_RESOURCE_SUCCESS:
      return {
        ...state,
        get_resource_loading: false,
        resource: action.payload,
      };
    case GET_RESOURCE_FAILED:
      return {
        ...state,
        get_resource_loading: false,
        error: action.payload,
      };
    case CREATE_RESOURCE_LOADING:
      return {
        ...state,
        create_resource_loading: true,
        error: {},
      };
    case CREATE_RESOURCE_SUCCESS:
      return {
        ...state,
        create_resource_loading: false,
        resources: [...state.resources, action.payload],
      };
    case CREATE_RESOURCE_FAILED:
      return {
        ...state,
        create_resource_loading: false,
        error: action.payload,
      };
    case UPDATE_RESOURCE_LOADING:
      return {
        ...state,
        create_resource_loading: true,
        error: {},
      };
    case UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        create_resource_loading: false,
        resource: action.payload,
      };
    case UPDATE_RESOURCE_FAILED:
      return {
        ...state,
        create_resource_loading: false,
        error: action.payload,
      };
    case CLEAR_RESOURCE:
      return {
        ...state,
        resource: {},
      };
    default:
      return state;
  }
};
