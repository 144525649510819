import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getUsers,
  getUser,
  sendAccessEmail,
} from "../../Redux/actions/userActions";
import {
  getPrescriptionsPlusByUser,
  deletePrescription,
} from "../../Redux/actions/prescriptionActions";
import {
  createUploadedDocument,
  getDocumentsByPatient,
  removePdfResult,
  getDocumentById,
} from "../../Redux/actions/documentActions";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
  setArchivedConversation,
  setActiveConversation,
} from "../../Redux/actions/messageActions";
import {
  getQuestionnairesByPatient,
  setViewAssessment,
  getAssessment,
  sendReminderNotification,
} from "../../Redux/actions/questionnaireActions";
import { openDrawer } from "../../Redux/actions/drawerActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Prescription from "./Prescription";
import QuestionnaireResult from "./QuestionnaireResult";
import DocumentResult from "./DocumentResult";
import AssessmentDialog from "../Dialog/AssessmentDialog";
import PrescriptionHistory from "../Dialog/PrescriptionHistoryDialog";
import ArchivedDialog from "../Dialog/ArchivedDialog";
import ArchivedConversation from "./ArchivedConversation";
import {
  formatDate,
  formatPhone,
  sortFormatDate,
} from "../../utils/formatData";
import Snackbar from "../Snackbar/Snackbar";

import hasPermission from "../../utils/hasPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    color: "#576C75",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subtitle1: {
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: "work-sans",
  },
  subtitle2: {
    color: "#576C75",
    fontWeight: 300,
    fontFamily: "work-sans",
  },
  button: {
    lineHeight: 1.6,
    paddingBottom: 4,
  },
  accordion: {
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    transition: "300ms background ease",
  },
  accordionExpanded: {
    background: "#F0F8FF",
  },
  expandIcon: {
    color: "#fff",
    background: "#B3D5EC",
    "&:hover": {
      color: "#fff",
      background: "#B3D5EC",
    },
  },
}));

const UserInfo = ({
  user,
  userId,
  getUser,
  getPrescriptionsPlusByUser,
  userPrescriptions,
  patientAssessments,
  patientSurveys,
  patientDocuments,
  getQuestionnairesByPatient,
  openDrawer,
  setViewAssessment,
  getAssessment,
  updateStatus,
  updateQuestionnaire,
  createUploadedDocument,
  deletePrescription,
  username,
  getDocumentsByPatient,
  logged_in_permissions,
  removePdfResult,
  getDocumentById,
  archivedConversations,
  setArchivedConversation,
  sendReminderNotification,
  sendAccessEmail,
  setActiveConversation,
  setTab,
}) => {
  const classes = useStyles();
  const [openAssess, setOpenAssess] = React.useState(false);
  const [openPre, setOpenPre] = React.useState(false);
  const [openArch, setOpenArch] = React.useState(false);
  const [selectedPre, setSelectedPre] = React.useState({});
  const [renderAssessments, setRenderAssessments] = React.useState([]);
  const [renderSurveys, setRenderSurveys] = React.useState([]);
  const [renderDocuments, setRenderDocuments] = React.useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    getUser(userId);
    getPrescriptionsPlusByUser(userId);
    getQuestionnairesByPatient(userId);
    getDocumentsByPatient(userId);
  }, [userId]);

  useEffect(() => {
    setRenderAssessments(patientAssessments.slice(0, 3));
  }, [patientAssessments]);

  useEffect(() => {
    setRenderSurveys(patientSurveys.slice(0, 3));
  }, [patientSurveys]);

  useEffect(() => {
    setRenderDocuments(patientDocuments.slice(0, 3));
  }, [patientDocuments]);

  const handleEditUser = () => {
    openDrawer("createUser");
  };

  const handleAssignQuestionnaire = (type) => {
    openDrawer(type);
  };

  const handleViewAssessment = (data) => {
    setViewAssessment(data);
    getAssessment(data.questionnaire_id);
    setOpenAssess(true);
  };

  const handleUpload = ({ target }, as) => {
    const file = target.files[0];

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("title", as.name);
    formData.append("uploaded_by", username);
    formData.append("patient_id", userId);
    createUploadedDocument(formData, as.id);
  };

  const handleCreatePrescription = () => {
    openDrawer("createPrescription");
  };

  const handleViewHistory = (pre) => {
    setOpenPre(true);
    setSelectedPre(pre);
  };

  const handleUpdatePdf = (id) => {
    openDrawer("uploadDocument");
    getDocumentById(id);
  };

  const handleViewArchived = (id) => {
    setArchivedConversation(id);
    setOpenArch(true);
  };

  if (Object.keys(user).length > 0) {
    return (
      <Grid container style={{ padding: 15 }}>
        <Snackbar
          openSnackbar={openSnack}
          handleCloseSnackbar={handleCloseSnackbar}
          message={"Account access email sent successfully!"}
        />
        <AssessmentDialog
          open={openAssess}
          setOpen={setOpenAssess}
          updateCarelog={updateQuestionnaire}
        />
        <PrescriptionHistory
          open={openPre}
          setOpen={setOpenPre}
          pre={selectedPre}
        />
        <ArchivedDialog open={openArch} setOpen={setOpenArch} />
        <Grid item xs={12}>
          <Button
            size="medium"
            href="/patients"
            color="primary"
            startIcon={<ArrowBackIcon />}
            className={classes.button}
          >
            Patients
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <Typography variant="h4" style={{ fontWeight: 600, marginLeft: 15 }}>
            {`${user.f_name} ${user.l_name}`}{" "}
          </Typography>
          {user.plan && (
            <Typography variant="subheader" style={{ marginLeft: 15 }}>
              {user.plan}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {user.access == 0 && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginTop: 10 }}
              onClick={() =>
                sendAccessEmail(
                  user.f_name,
                  user.l_name,
                  user.phone,
                  user.email,
                  setOpenSnack
                )
              }
            >
              Send account access email
            </Button>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <div className={classes.root}>
            <Accordion
              classes={{
                root: classes.accordion,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                IconButtonProps={{
                  edge: "end",
                  size: "small",
                  disableRipple: true,
                }}
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.accordionExpanded,
                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Patient Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.subtitle2}
                    >
                      Address
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.subtitle1}
                    >
                      {user.address}
                      <br />
                      {user.city}, {user.state} {user.zip}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.subtitle2}
                    >
                      Contact
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.subtitle1}
                    >
                      {formatPhone(user.phone)} <br /> {user.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.subtitle2}
                    >
                      DOB
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.subtitle1}
                    >
                      {formatDate(user.birth, "MM/DD/YYYY")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ textAlign: "left", marginTop: 15 }}
                  >
                    {hasPermission("CRUD_PATIENT", logged_in_permissions) && (
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => handleEditUser()}
                      >
                        Edit User
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: classes.accordion,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                IconButtonProps={{
                  edge: "end",
                  size: "small",
                  disableRipple: true,
                }}
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.accordionExpanded,
                }}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Prescriptions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {userPrescriptions.length > 0 ? (
                    userPrescriptions.map((pre, i) => (
                      <Prescription
                        key={`userPrescription${pre.id}`}
                        data={pre}
                        updateStatus={updateStatus}
                        deletePre={() => deletePrescription(pre.id, username)}
                        viewHistory={() => handleViewHistory(pre)}
                        openDrawer={openDrawer}
                        logged_in_permissions={logged_in_permissions}
                        hasPermission={hasPermission}
                        setActiveConversation={setActiveConversation}
                        setTab={setTab}
                      />
                    ))
                  ) : (
                    <Typography
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        width: "100%",
                        margin: 20,
                      }}
                    >
                      No current prescriptions
                    </Typography>
                  )}
                  <Grid item xs={12}>
                    {hasPermission(
                      "CREATE_PRESCRIPTION",
                      logged_in_permissions
                    ) && (
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                        onClick={() => handleCreatePrescription()}
                      >
                        Create Prescription
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: classes.accordion,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                IconButtonProps={{
                  edge: "end",
                  size: "small",
                  disableRipple: true,
                }}
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.accordionExpanded,
                }}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>Assessments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {patientAssessments &&
                    renderAssessments.map((as, i) => (
                      <QuestionnaireResult
                        key={`userPrescription${as.id}`}
                        data={as}
                        assess
                        view={() => handleViewAssessment(as)}
                        sendReminder={sendReminderNotification}
                        updateQuestionnaire={updateQuestionnaire}
                        setTab={setTab}
                      />
                    ))}
                  {renderAssessments.length == 3 &&
                    patientAssessments.length > 3 && (
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            setRenderAssessments(patientAssessments)
                          }
                          style={{ borderStyle: "dashed", borderWidth: 2 }}
                        >
                          Show All
                        </Button>
                      </Grid>
                    )}
                  {renderAssessments.length > 3 && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                          setRenderAssessments(patientAssessments.slice(0, 3))
                        }
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                      >
                        Show Less
                      </Button>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    {hasPermission(
                      "ASSIGN_QUESTIONNAIRE",
                      logged_in_permissions
                    ) && (
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={() => handleAssignQuestionnaire("assessment")}
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                      >
                        Create Assessment Assignment
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: classes.accordion,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                IconButtonProps={{
                  edge: "end",
                  size: "small",
                  disableRipple: true,
                }}
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.accordionExpanded,
                }}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>Surveys</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {patientSurveys &&
                    renderSurveys.map((as, i) => (
                      <QuestionnaireResult
                        key={`userPrescription${as.id}`}
                        data={as}
                        assess
                        view={() => handleViewAssessment(as)}
                        sendReminder={sendReminderNotification}
                        updateQuestionnaire={updateQuestionnaire}
                        setTab={setTab}
                      />
                    ))}
                  {renderSurveys.length == 3 && patientSurveys.length > 3 && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setRenderSurveys(patientSurveys)}
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                      >
                        Show All
                      </Button>
                    </Grid>
                  )}
                  {renderSurveys.length > 3 && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                          setRenderSurveys(patientSurveys.slice(0, 3))
                        }
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                      >
                        Show Less
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {hasPermission(
                      "ASSIGN_QUESTIONNAIRE",
                      logged_in_permissions
                    ) && (
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                        onClick={() => handleAssignQuestionnaire("survey")}
                      >
                        Create Survey Assignment
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: classes.accordion,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                IconButtonProps={{
                  edge: "end",
                  size: "small",
                  disableRipple: true,
                }}
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.accordionExpanded,
                }}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>Documents</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {patientDocuments &&
                    renderDocuments.map((as, i) => (
                      <DocumentResult
                        key={`userPrescription${as.id}`}
                        data={as}
                        assess
                        view={() => handleViewAssessment(as)}
                        upload={(e) => handleUpload(e, as)}
                        removePdfResult={removePdfResult}
                        handleUpdate={handleUpdatePdf}
                        sendReminder={sendReminderNotification}
                        updateQuestionnaire={updateQuestionnaire}
                        setTab={setTab}
                      />
                    ))}
                  {renderDocuments.length == 3 && patientDocuments.length > 3 && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setRenderDocuments(patientDocuments)}
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                      >
                        Show All
                      </Button>
                    </Grid>
                  )}
                  {renderDocuments.length > 3 && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                          setRenderDocuments(patientSurveys.slice(0, 3))
                        }
                        style={{ borderStyle: "dashed", borderWidth: 2 }}
                      >
                        Show Less
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => handleAssignQuestionnaire("document")}
                      style={{ borderStyle: "dashed", borderWidth: 2 }}
                    >
                      Create Document Assignment
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      fullWidth
                      startIcon={<AttachFileIcon />}
                      style={{ borderStyle: "dashed", borderWidth: 2 }}
                      onClick={() => openDrawer("uploadDocument")} ///OPEN DRAWER
                    >
                      Upload Document
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{
                root: classes.accordion,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                IconButtonProps={{
                  edge: "end",
                  size: "small",
                  disableRipple: true,
                }}
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.accordionExpanded,
                }}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Archived Conversations
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => setActiveConversation(null)}
                      style={{ borderStyle: "dashed", borderWidth: 2 }}
                    >
                      Reset Active Conversation
                    </Button>
                  </Grid>
                  {archivedConversations.map((ac, i) => (
                    <ArchivedConversation key={i} data={ac} view={handleViewArchived} />
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  userPrescriptions: state.prescriptionReducer.userPrescriptions,
  patientAssessments: state.questionnaireReducer.patientAssessments,
  patientSurveys: state.questionnaireReducer.patientSurveys,
  patientDocuments: state.documentReducer.documents,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
  archivedConversations: state.messageReducer.archivedConversations.sort(
    (a, b) => sortFormatDate(b.start_date) - sortFormatDate(a.start_date)
  ),
});

export default connect(mapStateToProps, {
  getUser,
  getPrescriptionsPlusByUser,
  openDrawer,
  getQuestionnairesByPatient,
  getDocumentsByPatient,
  setViewAssessment,
  getAssessment,
  deletePrescription,
  createUploadedDocument,
  removePdfResult,
  getDocumentById,
  setArchivedConversation,
  sendReminderNotification,
  sendAccessEmail,
  setActiveConversation,
})(UserInfo);
