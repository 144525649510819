import {
  CREATE_USER_LOADING,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  CLEAR_USER,
  CLEAR_USER_ERRORS,
  SEARCH_PATIENTS,
  GET_PLANS_LOADING,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILED,
  ADD_PLAN_LOADING,
  ADD_PLAN_SUCCESS,
  ADD_PLAN_FAILED,
  SET_PLAN,
  UPDATE_PLAN_LOADING,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILED,
  CLEAR_PLAN,
  SET_USER_ERROR,
} from "../types";

const initialState = {
  create_loading: false,
  get_users_loading: false,
  get_user_loading: false,
  get_plans_loading: false,
  success: null,
  staticUsers: [],
  users: [],
  user: {},
  error: {},
  plans: [],
  plan: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_LOADING:
      return {
        ...state,
        create_loading: true,
        success: null,
        error: {},
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        create_loading: false,
        users: [...state.users, action.payload],
      };
    case CREATE_USER_FAILED:
      return {
        ...state,
        create_loading: false,
        error: action.payload,
        success: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        create_loading: false,
        users: [
          ...state.users.filter((p) => p.id !== action.payload.id),
          action.payload,
        ],
        user: action.payload,
      };
    case GET_USERS_LOADING:
      return {
        ...state,
        get_USERs_loading: true,
        error: {},
        users: [],
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        get_users_loading: false,
        staticUsers: action.payload,
        users: action.payload,
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        get_users_loading: false,
        error: action.payload,
        users: [],
      };
    case GET_USER_LOADING:
      return {
        ...state,
        get_user_loading: true,
        error: {},
        user: {},
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        get_user_loading: false,
        user: action.payload,
      };
    case GET_USER_FAILED:
      return {
        ...state,
        get_user_loading: false,
        user: {},
        error: action.payload,
      };
    case CLEAR_USER:
      return {
        ...state,
        user: {},
        error: {},
      };
    case CLEAR_USER_ERRORS:
      return {
        ...state,
        error: {},
      };
    case SEARCH_PATIENTS:
      return {
        ...state,
        users:
          action.payload == ""
            ? state.staticUsers
            : state.staticUsers.filter((u) => {
                if (
                  `${u.f_name.toLowerCase()} ${u.l_name.toLowerCase()}`.includes(
                    action.payload.toLowerCase()
                  )
                ) {
                  return u;
                }
              }),
      };
    case GET_PLANS_LOADING:
      return {
        ...state,
        get_plans_loading: true,
      };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        get_plans_loading: false,
        plans: action.payload,
      };
    case GET_PLANS_FAILED:
      return {
        ...state,
        get_plans_loading: false,
        plans: [],
        error: action.payload,
      };
    case ADD_PLAN_LOADING:
      return {
        ...state,
        get_plans_loading: true,
      };
    case ADD_PLAN_SUCCESS:
      return {
        ...state,
        get_plans_loading: false,
        plans: [action.payload, ...state.plans],
      };
    case ADD_PLAN_FAILED:
      return {
        ...state,
        get_plans_loading: false,
        error: action.payload,
      };
    case SET_PLAN:
      return {
        ...state,
        plan: state.plans.filter((p) => p.row === action.payload)[0],
      };
    case UPDATE_PLAN_LOADING:
      return {
        ...state,
      };
    case UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        plans: state.plans.map((p) => {
          if (p.row === action.payload.row) {
            p.name = action.payload.name;
          }
          return p;
        }),
        plan: {},
      };
    case UPDATE_PLAN_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_PLAN:
      return {
        ...state,
        plan: {},
      };
    case SET_USER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
