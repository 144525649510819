import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import CreateAssessment from "../Components/Questionnaires/CreateAssessment";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  container: {
    overflowY: "hidden",
    listStyle: "none",
    height: "100%",
  },
}));

const AssessmentsCreate = ({ match }) => {
  const classes = useStyles();

  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <Grid
        item
        xs={12}
        style={{
          height: "100%",
        }}
      >
        <CreateAssessment assessmentId={match.params.id} />
      </Grid>
    </Grid>
  );
};

export default AssessmentsCreate;
