import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clearUser } from "../Redux/actions/userActions";
import {
  getActiveConversations,
  getArchivedConversations,
  setActiveConversation,
} from "../Redux/actions/messageActions";
import { updatePrescription } from "../Redux/actions/prescriptionActions";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Tabs, Tab } from "@material-ui/core";

import UserPrescriptionsTable from "../Components/Tables/UserPrescriptionsTable";
import UserInfo from "../Components/User/UserInfo";
import Chat from "../Components/User/Chat";
import AdminChat from "../Components/User/AdminChat";
import Snackbar from "../Components/Snackbar/Snackbar";

import io from "socket.io-client";
import { axiosWithAuth } from "../utils/axiosWithAuth";

const CHAT_URL = process.env.REACT_APP_CHAT_URL;

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: "auto",
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  tab: {
    flexGrow: 1,
    height: "calc(100% - 48px)",
    "& .MuiBox-root": {
      padding: 0,
      height: "100%",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const User = ({
  match,
  clearUser,
  user,
  admin,
  updatePrescription,
  activeConversation,
  activeConversations,
  getActiveConversations,
  getArchivedConversations,
  setActiveConversation,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  var socket = null;
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState();
  const [somebodyTyping, setSomebodyTyping] = useState(false);
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [room, setRoom] = useState();
  const { username } = admin;
  const [images, setImages] = useState([]);
  const { activeId } = match.params;

  useEffect(() => {
    const { chat } = match.params;

    if (chat) {
      setValue(parseInt(chat));
    }
    return () => clearUser();
  }, []);

  useEffect(() => {
    if (Object.keys(user) && "id" in user) {
      getActiveConversations(user.id);
      getArchivedConversations(user.id);
    }
  }, [user.id]);

  useEffect(() => {
    if (activeId && activeId != undefined && activeConversations.length > 0) {
      setActiveConversation(activeId);
    }
  }, [activeId, activeConversations]);

  const initiateSocket = (room) => {
    socket = io(CHAT_URL);
    const data = {
      participant_id: username,
      room,
      admin: true,
      chatAdmin: true,
      activeId: activeId,
    };
    if (socket && room) socket.emit("join", data);
  };

  const disconnectSocket = (room) => {
    if (socket && room) socket.emit("disconnect");
    if (socket) socket.disconnect();
  };

  const subscribeToChat = (cb) => {
    if (!socket) return true;
    socket.on("chat", (msg) => {
      return cb(null, msg);
    });
  };

  const subscribeToTyping = (cb) => {
    if (!socket) return true;
    socket.on("display", (data) => {
      return cb(null, data);
    });
  };

  const typingToChat = (typing, room) => {
    let data = { typing, participant_id: username, room, chatAdmin: true };
    if (socket && room) socket.emit("typing", data);
  };

  const sendMessage = async (text) => {
    if (!text && !images.length) return;
    let message = {
      text: `${text}`,
      sender_id: username,
      images: JSON.stringify(images),
      conversation_id: activeConversation.id,
      type: "carelog",
    };
    let data = {
      typing: false,
      participant_id: username,
      room,
      chatAdmin: true,
    };
    if (socket && room) socket.emit("typing", data);
    if (socket && room)
      await socket.emit("chat", { message, room, chatAdmin: true });
  };

  const updateStatus = async (text, prescription, status) => {
    if (!text) return;
    handleOpenSnackbar();

    let conv = activeConversations.filter(
      (c) => c.prescription_id == prescription.id
    )[0];

    let data = {
      typing: false,
      participant_id: username,
      room,
      chatAdmin: true,
    };
    if (socket && room) socket.emit("typing", data);
    if (socket && room) {
      if (conv) {
        let message = {
          text: `${prescription.medication_name} was set to ${text}`,
          sender_id: username,
          statusUpdate: true,
          conversation_id: conv.id,
          type: "carelog",
        };
        await socket.emit("chat", { message, room, chatAdmin: true });
      }
      axiosWithAuth().post("/api/statusHistory/", {
        status: status,
        admin_username: username,
        prescription_id: prescription.id,
      });
    }
  };

  const updateQuestionnaire = async (questionnaire, text) => {
    // possibly abandoned
    return;
    
    if (!questionnaire) return;
    await setActiveConversation(null);

    let conv = activeConversations.filter((c) => c.prescription_id === null)[0];

    let message = {
      text: text,
      sender_id: username,
      statusUpdate: true,
      conversation_id: conv.id,
      type: questionnaire.type,
    };
    let data = {
      typing: false,
      participant_id: username,
      room,
      chatAdmin: true,
    };
    if (socket && room) socket.emit("typing", data);
    if (socket && room) {
      await socket.emit("chat", { message, room, chatAdmin: true });
    }
  };

  const onFileChange = ({ target }) => {
    Object.values(target.files).forEach((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) =>
        setImages((prevImages) => [...prevImages, e.target.result]);
    });
  };

  const getMessages = async () => {
    const data = await fetchMessages(activeConversation.id);
    setMessages(data);
  };

  initiateSocket();

  useEffect(() => {
    setRoom(user.id);
    if (user.id) initiateSocket(user.id);
    subscribeToChat((err, data) => {
      if (err) return;
      setMessages((oldMessages = []) => [...oldMessages, data]);
    });
    subscribeToTyping((err, data) => {
      if (err) return;
      if (data.participant_id != admin.username) {
        setSomebodyTyping(data.typing);
      }
    });
    activeConversation &&
      user &&
      user.id &&
      "id" in activeConversation &&
      getMessages();
    return () => {
      disconnectSocket();
    };
  }, [user, activeConversation]);

  const onChangeHandler = (e) => {
    setMessage(e.target.value);
    if (typingTimeout) clearTimeout(typingTimeout);
    setTyping(true);
    !typing && typingToChat(true, room);
    setTypingTimeout(
      setTimeout(function () {
        setTyping(false);
        typingToChat(false, room);
      }, 2000)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage(message, images);
    setMessage("");
    setImages([]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <Snackbar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        message={"Prescription Status Changed Successfully!"}
      />
      <Grid
        item
        xs={5}
        className={classes.container}
        style={{ borderRight: "1px solid lightgrey" }}
      >
        <UserInfo
          userId={match.params.id}
          updateStatus={updateStatus}
          username={username}
          updateQuestionnaire={updateQuestionnaire}
          setTab={handleChange}
        />
      </Grid>
      <Grid item xs={7} className={classes.container}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Care Log" />
          <Tab label="Patient Chat" />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tab}>
          <AdminChat
            message={message}
            messages={messages}
            somebodyTyping={somebodyTyping}
            onChangeHandler={onChangeHandler}
            onFileChange={onFileChange}
            handleSubmit={handleSubmit}
            images={images}
            username={username}
            user={user}
          />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tab}>
          <Chat user={user} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  admin: state.authReducer.admin,
  activeConversation: state.messageReducer.activeConversation,
  activeConversations: state.messageReducer.activeConversations,
});

export default connect(mapStateToProps, {
  clearUser,
  updatePrescription,
  getActiveConversations,
  getArchivedConversations,
  setActiveConversation,
})(User);

function fetchMessages(id) {
  return axiosWithAuth()
    .get("api/adminMessages/conversation/" + id)
    .then(async (res) => {
      return res.data.messages;
    })
    .catch((err) => {
      console.error(err);
    });
}
