import {
  CREATE_MEDICATION_LOADING,
  CREATE_MEDICATION_SUCCESS,
  CREATE_MEDICATION_FAILED,
  UPDATE_MEDICATION_LOADING,
  UPDATE_MEDICATION_SUCCESS,
  UPDATE_MEDICATION_FAILED,
  GET_MEDICATIONS_LOADING,
  GET_MEDICATIONS_SUCCESS,
  GET_MEDICATIONS_FAILED,
  GET_MEDICATION_LOADING,
  GET_MEDICATION_SUCCESS,
  GET_MEDICATION_FAILED,
  CLEAR_MEDICATION,
  GET_THERAPIES_LOADING,
  GET_THERAPIES_SUCCESS,
  GET_THERAPIES_FAILED,
  CREATE_THERAPY_LOADING,
  CREATE_THERAPY_SUCCESS,
  CREATE_THERAPY_FAILED,
  SET_THERAPY,
  CLEAR_THERAPY,
  UPDATE_THERAPY_LOADING,
  UPDATE_THERAPY_SUCCESS,
  UPDATE_THERAPY_FAILED,
} from "../types";

// const therapies = [
//   "ALS",
//   "Asthma / Allergy",
//   "Cardiovascular Diseases",
//   "Crohn's / Colitis",
//   "Hemophilia",
//   "Hepatitis",
//   "HIV",
//   "Huntington's Disease",
//   "Immunodeficiencies",
//   "Infertility",
//   "Multiple Sclerosis",
//   "Oncology",
//   "Osteoporosis",
//   "Psoriasis",
//   "Rheumatoid Arthritis",
// ];

const initialState = {
  create_loading: false,
  update_loading: false,
  get_medications_loading: false,
  get_medication_loading: false,
  success: null,
  medications: [],
  medication: {},
  error: {},
  create_therapy_loading: false,
  get_therapies_loading: false,
  therapies: [],
  therapy: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDICATIONS_LOADING:
      return {
        ...state,
        get_medications_loading: true,
        error: {},
      };
    case GET_MEDICATIONS_SUCCESS:
      return {
        ...state,
        get_medication_loading: false,
        medications: action.payload,
        error: {},
      };
    case GET_MEDICATIONS_FAILED:
      return {
        ...state,
        get_medication_loading: false,
        medications: [],
        error: action.payload,
      };
    case GET_MEDICATION_LOADING:
      return {
        ...state,
        get_medication_loading: true,
        error: {},
      };
    case GET_MEDICATION_SUCCESS:
      return {
        ...state,
        get_medication_loading: false,
        medication: action.payload,
      };
    case GET_MEDICATION_FAILED:
      return {
        ...state,
        get_medication_loading: false,
        error: action.payload,
      };
    case CREATE_MEDICATION_LOADING:
      return {
        ...state,
        create_loading: true,
        error: {},
      };
    case CREATE_MEDICATION_SUCCESS:
      return {
        ...state,
        create_loading: false,
        medications: [...state.medications, action.payload],
      };
    case CREATE_MEDICATION_FAILED:
      return {
        ...state,
        create_loading: false,
        error: action.payload,
      };
    case UPDATE_MEDICATION_LOADING:
      return {
        ...state,
        update_loading: true,
        error: {},
      };
    case UPDATE_MEDICATION_SUCCESS:
      return {
        ...state,
        update_loading: false,
        medications: [
          action.payload,
          ...state.medications.filter((m) => m.id !== action.payload.id),
        ],
      };
    case UPDATE_MEDICATION_FAILED:
      return {
        ...state,
        update_loading: false,
        error: action.payload,
      };
    case CLEAR_MEDICATION:
      return {
        ...state,
        medication: {},
        error: {},
      };
    case CREATE_THERAPY_LOADING:
      return {
        ...state,
        create_therapy_loading: true,
        error: {},
      };
    case CREATE_THERAPY_SUCCESS:
      return {
        ...state,
        create_therapy_loading: false,
        therapies: [action.payload, ...state.therapies],
        error: {},
      };
    case CREATE_THERAPY_FAILED:
      return {
        ...state,
        create_therapy_loading: false,
        error: action.payload,
      };
    case GET_THERAPIES_LOADING:
      return {
        ...state,
        get_therapies_loading: true,
        error: {},
      };
    case GET_THERAPIES_SUCCESS:
      return {
        ...state,
        get_therapies_loading: false,
        therapies: action.payload,
        error: {},
      };
    case GET_THERAPIES_FAILED:
      return {
        ...state,
        get_therapies_loading: false,
        therapies: [],
        error: action.payload,
      };
    case SET_THERAPY:
      return {
        ...state,
        therapy: state.therapies.filter((t) => t.row === action.payload)[0],
      };
    case CLEAR_THERAPY:
      return {
        ...state,
        therapy: {},
      };
    case UPDATE_THERAPY_LOADING:
      return {
        ...state,
      };
    case UPDATE_THERAPY_SUCCESS:
      return {
        ...state,
        therapies: state.therapies.map((t) => {
          if (t.row === action.payload.row) {
            t.name = action.payload.name;
          }
          return t;
        }),
        therapy: {},
      };
    case UPDATE_THERAPY_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
