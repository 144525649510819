import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Toolbar,
  Tabs,
  Tab,
  Box,
  Button,
} from "@material-ui/core";

import SurveysTable from "../Components/Tables/SurveysTable";
import AssignmentIcon from "@material-ui/icons/Assignment";

import hasPermission from "../utils/hasPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    overflowY: "auto",
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
}));

const Surveys = (props) => {
  const classes = useStyles();

  return (
    <Grid container alignContent="flex-start" className={classes.container}>
      <Grid
        item
        xs={12}
        style={{
          height: 50,
          margin: "20px auto",
        }}
      >
        <Toolbar>
          <Grid
            style={{
              paddingBottom: 40,
            }}
            container
          >
            <Grid item xs={3}>
              <Typography
                variant="h4"
                style={{
                  marginLeft: 10,
                }}
              >
                Surveys
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                textAlign: "right",
                justifyContent: "flex-end",
              }}
            >
              {hasPermission(
                "CRUD_QUESTIONNAIRE",
                props.logged_in_permissions
              ) && (
                <Button
                  color="primary"
                  variant="contained"
                  href={`/surveys/create/`}
                  style={{}}
                  startIcon={<AssignmentIcon />}
                >
                  Create New Survey
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: "100%",
          padding: 20,
        }}
      >
        <SurveysTable history={props.history} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {})(Surveys);
