import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createResource,
  updateResource,
} from "../../Redux/actions/educationActions";
import { getMedications } from "../../Redux/actions/medicationActions";
import { createUploadedDocument } from "../../Redux/actions/documentActions";
import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  CardMedia,
  Switch,
} from "@material-ui/core";
import BookIcon from "@material-ui/icons/Book";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import useStyles from "../Auth/AuthFormStyles";

const CreateResource = ({
  resourceData,
  createResource,
  updateResource,
  getMedications,
  medications,
  error,
  createUploadedDocument,
}) => {
  const classes = useStyles();
  const [medicationTags, setMedicationTags] = useState([]);
  const [imageErr, setImageErr] = useState(false);
  const [file, setFile] = useState(null);
  const [resource, setResource] = useState({
    title: "",
    description: "",
    toggle: false,
    link: "",
    text: "",
    image_url: "",
  });

  useEffect(() => {
    if (Object.keys(resourceData).length > 0) {
      if (resourceData.link) {
        resource.toggle = true;
      }
      setResource({
        title: resourceData.title,
        description: resourceData.description,
        toggle: true,
        link: resourceData.link,
        text: resourceData.text,
        image_url: resourceData.image_url,
      });
      if (resourceData.medications) {
        const medications_ids = resourceData.medications.map((m) => m.id);
        setMedicationTags(medications_ids);
      }
    }
    getMedications();
  }, []);

  const onSelect = (e) => {
    setMedicationTags(e.target.value);
  };

  const onChangeHandler = (e) => {
    setResource({ ...resource, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setResource({ ...resource, [event.target.name]: event.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (validateImage(resource.image_url) || resource.image_url.length == 0) {
      setImageErr(false);
      if (Object.keys(resourceData).length > 0) {
        let mt = null;
        if (medicationTags.length > 0) {
          mt = medicationTags;
        }
        updateResource({ ...resource, medication_ids: mt }, resourceData.id);
      } else {
        createResource({ ...resource, medication_ids: medicationTags });
      }
    // } else {
    //   setImageErr(true);
    // }
  };

  const validateImage = async (url) => {
    var http = new XMLHttpRequest();

    
    http.open("HEAD", url, false);
    http.setRequestHeader("Access-Control-Allow-Origin", "*");
    http.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    http.send();
    if (http.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const onFileChange = ({ target }) => {
    const formData = new FormData();
    formData.append("file", target.files[0], target.files[0].name);
    formData.append(
      "title",
      resource.title ? `${resource.title}.pdf` : "ResourceDoc.pdf"
    );
    axiosWithAuth()
      .post("/api/documents/uploadDocument", formData)
      .then((res) => {
        setResource({ ...resource, link: res.data.link });
      })
      .catch((err) => alert(err));
  };

  const handleUploadDoc = (e) => {
    e.preventDefault();
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <BookIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create New Education Resource
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Medications
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={medicationTags}
                  onChange={onSelect}
                  label="Medications"
                  fullWidth
                  multiple
                  renderValue={(selected) => {
                    const obj = medications.reduce(
                      (a, b) => ((a[b.id] = b), a),
                      {}
                    );
                    return selected
                      .map((s) => obj[s] && obj[s].name)
                      .join(", ");
                  }}
                >
                  {medications.length > 0 &&
                    medications.map((med) => (
                      <MenuItem key={med.id} value={med.id}>
                        <Checkbox
                          checked={medicationTags.indexOf(med.id) > -1}
                        />
                        <ListItemText primary={med.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {Object.keys(resource).map((key, index) => {
              return (
                <>
                  {key == "toggle" ? (
                    <Grid item xs={12}>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>Text</Grid>
                          <Grid item>
                            <Switch
                              checked={resource.toggle}
                              onChange={handleChange}
                              name="toggle"
                            />
                          </Grid>
                          <Grid item>Link</Grid>
                        </Grid>
                      </Typography>
                      {resource.toggle && (
                        <>
                          <input
                            accept="application/pdf"
                            className={classes.input}
                            style={{ display: "none" }}
                            id="add-uploaded_documents"
                            type="file"
                            onChange={onFileChange}
                          />
                          <label htmlFor="add-uploaded_documents">
                            <Button
                              color="primary"
                              variant="outlined"
                              component="span"
                              startIcon={<AttachFileIcon />}
                              className={classes.button}
                              style={{ marginTop: 20 }}
                            >
                              {file ? "Change Document" : "Add Document"}
                            </Button>
                          </label>
                          {file && (
                            <>
                              {file.name ? (
                                <Typography>{`${file.name} - ${file.size}KB`}</Typography>
                              ) : (
                                <Typography>{`URL: ${file}`}</Typography>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={key == "image_url" && resource[key] ? 10 : 12}
                    >
                      <TextField
                        error={
                          key != "image_url"
                            ? error[key]
                              ? true
                              : false
                            : imageErr
                        }
                        helperText={
                          key == "image_url" && imageErr
                            ? "That url is not a valid image link"
                            : error[key] && error[key]
                        }
                        variant="outlined"
                        required={key === "title"}
                        fullWidth
                        multiline={key == "text" ? true : false}
                        value={resource[key]}
                        rows={key == "text" ? 4 : 1}
                        name={key}
                        label={
                          key == "image_url"
                            ? "Cover"
                            : key.charAt(0).toUpperCase() + key.slice(1)
                        }
                        id={key}
                        style={
                          (key == "text" && resource.toggle) ||
                          (key == "link" && !resource.toggle)
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </Grid>
                  )}
                  {key == "image_url" && resource[key] && (
                    <Grid item xs={2}>
                      <CardMedia
                        className={classes.media}
                        image={resource[key]}
                      />
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
          {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )}
          <Button
            // disabled={!typeSelected}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {Object.keys(resourceData).length > 0 ? "Update" : "Create"}
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  resourceData: state.educationReducer.resource,
  medications: state.medicationReducer.medications,
  error: state.educationReducer.error,
});

export default connect(mapStateToProps, {
  createResource,
  updateResource,
  getMedications,
  createUploadedDocument,
})(CreateResource);
