import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Card,
  CardHeader,
  Grid,
  CardContent,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { formatDate } from "../../utils/formatData";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderBottom: "1px solid #EEF0F1",
  },
}));

const ArchivedConversation = ({ data, view }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.root}>
      <CardHeader
        action={
          <Grid container style={{ marginTop: 10 }}>
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                onClick={() => view(data.id)}
              >
                View Conversation
              </Button>
            </Grid>
          </Grid>
        }
        title={data.title}
        subheader={`${formatDate(data.start_date, "MM/DD/YYYY")} - ${formatDate(
          data.end_date,
          "MM/DD/YYYY"
        )}`}
        titleTypographyProps={{ variant: "subtitle2" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
    </Grid>
  );
};

export default ArchivedConversation;
