import {
  SIGNIN_ADMIN_LOADING,
  SET_CURRENT_ADMIN,
  SIGNIN_ADMIN_FAILED,
  LOGOUT_ADMIN,
  PASSWORD_RESET_LOADING,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILED,
} from "../types";

import isEmpty from "../../utils/isEmpty";

const initialState = {
  isAuthenticated: false,
  signin_loading: false,
  password_reset_loading: false,
  iat: null,
  exp: null,
  admin: {},
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_ADMIN_LOADING:
      return { ...state, siginin_loading: true, error: {} };

    case SIGNIN_ADMIN_FAILED:
      return {
        ...state,
        signin_loadin: false,
        error: action.payload,
        isAuthenticated: false,
      };
    case LOGOUT_ADMIN:
      return {
        ...state,
        isAuthenticated: false,
        signin_loading: false,
        iat: null,
        exp: null,
        user: {},
        error: {},
      };
    case SET_CURRENT_ADMIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        signin_loading: false,
        error: {},
        iat: action.payload.iat,
        exp: action.payload.exp,
        admin: {
          username: action.payload.username,
          id: action.payload.id,
          admin: action.payload.admin,
        },
      };
    case PASSWORD_RESET_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
