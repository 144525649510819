import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  GET_ALL_MESSAGES_LOADING,
  GET_ALL_MESSAGES_SUCCESS,
  GET_ALL_MESSAGES_FAILED,
  GET_UNSEEN_MESSAGES_LOADING,
  GET_UNSEEN_MESSAGES_SUCCESS,
  GET_UNSEEN_MESSAGES_FAILED,
  GET_ACTIVE_CONVERSATIONS_LOADING,
  GET_ACTIVE_CONVERSATIONS_SUCCESS,
  GET_ACTIVE_CONVERSATIONS_FAILED,
  CREATE_CONVERSATION_LOADING,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_FAILED,
  ARCHIVE_CONVERSATION_LOADING,
  ARCHIVE_CONVERSATION_SUCCESS,
  ARCHIVE_CONVERSATION_FAILED,
  GET_ARCHIVED_CONVERSATIONS_LOADING,
  GET_ARCHIVED_CONVERSATIONS_SUCCESS,
  GET_ARCHIVED_CONVERSATIONS_FAILED,
  SET_ARCHIVED_CONVERSATION_SUCCESS,
  SET_ACTIVE_CONVERSATION,
} from "../types";

export const getAllMessages = (id, type) => (dispatch) => {
  dispatch({
    type: GET_ALL_MESSAGES_LOADING,
  });
  axiosWithAuth()
    .get(`/api/adminMessages/unread/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ALL_MESSAGES_SUCCESS,
        payload: res.data.allMessages,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_ALL_MESSAGES_FAILED,
        payload: err.response && err.response.data,
      });
    });
};

export const getUnseenMessages = (id, type) => (dispatch) => {
  dispatch({
    type: GET_UNSEEN_MESSAGES_LOADING,
  });
  axiosWithAuth()
    .get(`/api/adminMessages/unread/${id}`)
    .then((res) => {
      dispatch({
        type: GET_UNSEEN_MESSAGES_SUCCESS,
        payload: Object.fromEntries(Object.entries(res.data.allMessages).filter(([, { seen }]) => seen === false)),
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_UNSEEN_MESSAGES_FAILED,
        payload: err.response && err.response.data,
      });
    });
};

export const getActiveConversations = (id) => (dispatch) => {
  dispatch({
    type: GET_ACTIVE_CONVERSATIONS_LOADING,
  });
  axiosWithAuth()
    .get(`/api/archivedMessages/activeConversation/patient/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ACTIVE_CONVERSATIONS_SUCCESS,
        payload: res.data.conversations,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_ACTIVE_CONVERSATIONS_FAILED,
        payload: err.response.data,
      });
    });
};

export const setActiveConversation = (id) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CONVERSATION,
    payload: id,
  });
};

export const createConversation = (title, patient_id) => (dispatch) => {
  dispatch({
    type: CREATE_CONVERSATION_LOADING,
  });
  axiosWithAuth()
    .post(`/api/archivedMessages/`, { title, patient_id })
    .then((res) => {
      dispatch({
        type: CREATE_CONVERSATION_SUCCESS,
        payload: res.data.conversation,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: CREATE_CONVERSATION_FAILED,
        payload: err.response.data,
      });
    });
};

export const archiveConversation = (conversation_id) => (dispatch) => {
  dispatch({
    type: ARCHIVE_CONVERSATION_LOADING,
  });
  axiosWithAuth()
    .put(`/api/archivedMessages/${conversation_id}`)
    .then((res) => {
      dispatch({
        type: ARCHIVE_CONVERSATION_SUCCESS,
        payload: res.data.conversation,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: ARCHIVE_CONVERSATION_FAILED,
        payload: err.response.data,
      });
    });
};

export const getArchivedConversations = (patient_id) => (dispatch) => {
  dispatch({
    type: GET_ARCHIVED_CONVERSATIONS_LOADING,
  });
  axiosWithAuth()
    .get(`/api/archivedMessages/patient/${patient_id}`)
    .then((res) => {
      dispatch({
        type: GET_ARCHIVED_CONVERSATIONS_SUCCESS,
        payload: res.data.conversations,
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: GET_ARCHIVED_CONVERSATIONS_FAILED,
        payload: err.response.data,
      });
    });
};

export const setArchivedConversation = (id) => (dispatch) => {
  dispatch({
    type: SET_ARCHIVED_CONVERSATION_SUCCESS,
    payload: id,
  });
};
