import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getNotifications } from "../../Redux/actions/notificationActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { formatDate } from "../../utils/formatData";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const NotificationsTable = ({ getNotifications, notifications }) => {
  const classes = useStyles();

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Notification Title</TableCell>
              <TableCell>Recipient('s)</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.length > 0 &&
              notifications.map((n) => (
                <TableRow key={`notificationTable-${n.id}`}>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>{n.recipients.join(", ")}</TableCell>
                  <TableCell>{formatDate(n.created_at)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notificationReducer.notifications,
});

export default connect(mapStateToProps, { getNotifications })(
  NotificationsTable
);
