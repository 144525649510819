// AUTH TYPES
export const SIGNIN_ADMIN_LOADING = "SIGNING_ADMIN_LOADING";
export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
export const SIGNIN_ADMIN_FAILED = "SIGNIN_ADMIN_FAILED";

export const SET_PASSWORD_LOADING = "SET_PASSWORD_LOADING";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";

export const PASSWORD_RESET_LOADING = "PASSWORD_RESET_LOADING";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILED = "PASSWORD_RESET_FAILED";

export const LOGOUT_ADMIN = "LOGOUT_ADMIN";

// ADMIN TYPES
export const CREATE_ADMIN_LOADING = "CREATE_ADMIN_LOADING";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAILED = "CREATE_ADMIN_FAILED";

export const UPDATE_ADMIN_LOADING = "UPDATE_ADMIN_LOADING";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILED = "UPDATE_ADMIN_FAILED";

export const GET_ADMINS_LOADING = "GET_ADMINS_LOADING";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAILED = "GET_ADMINS_FAILED";

export const GET_ADMIN_LOADING = "GET_ADMIN_LOADING";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILED = "GET_ADMIN_FAILED";

export const CLEAR_ADMIN = "CLEAR_ADMIN";

export const GET_LOGGED_IN_PERMISSIONS_LOADING =
  "GET_LOGGED_IN_PERMISSIONS_LOADING";
export const GET_LOGGED_IN_PERMISSIONS_SUCCESS =
  "GET_LOGGED_IN_PERMISSIONS_SUCCESS";
export const GET_LOGGED_IN_PERMISSIONS_FAILED =
  "GET_LOGGED_IN_PERMISSIONS_FAILED";

export const GET_PERMISSIONS_LOADING = "GET_PERMISSIONS_LOADING";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILED = "GET_PERMISSIONS_FAILED";

export const ADD_PERMISSION_LOADING = "ADD_PERMISSION_LOADING";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILED = "ADD_PERMISSION_FAILED";

export const REMOVE_PERMISSION_LOADING = "REMOVE_PERMISSION_LOADING";
export const REMOVE_PERMISSION_SUCCESS = "REMOVE_PERMISSION_SUCCESS";
export const REMOVE_PERMISSION_FAILED = "REMOVE_PERMISSION_FAILED";

export const TOGGLE_ACTIVATED_ADMIN_LOADING = "TOGGLE_ACTIVATED_ADMIN_LOADING";
export const TOGGLE_ACTIVATED_ADMIN_SUCCESS = "TOGGLE_ACTIVATED_ADMIN_SUCCESS";
export const TOGGLE_ACTIVATED_ADMIN_FAILED = "TOGGLE_ACTIVATED_ADMIN_FAILED";

export const ADMIN_PASSWORD_RESET_LOADING = "ADMIN_PASSWORD_RESET_LOADING";
export const ADMIN_PASSWORD_RESET_SUCCESS = "ADMIN_PASSWORD_RESET_SUCCESS";
export const ADMIN_PASSWORD_RESET_FAILED = "ADMIN_PASSWORD_RESET_FAILED";

// USER TYPES
export const CREATE_USER_LOADING = "CREATE_USER_LOADING";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const CLEAR_USER = "CLEAR_USER";
export const CLEAR_USER_ERRORS = "CLEAR_USER_ERRORS";

export const SEARCH_PATIENTS = "SEARCH_PATIENTS";

export const GET_PLANS_LOADING = "GET_PLANS_LOADING";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILED = "GET_PLANS_FAILED";

export const ADD_PLAN_LOADING = "ADD_PLAN_LOADING";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ADD_PLAN_FAILED = "ADD_PLAN_FAILED";

export const SET_PLAN = "SET_PLAN";

export const UPDATE_PLAN_LOADING = "UPDATE_PLAN_LOADING";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAILED = "UPDATE_PLAN_FAILED";

export const CLEAR_PLAN = "CLEAR_PLAN";
export const SET_USER_ERROR = "SET_USER_ERROR";

// PRESCRIPTION TYPES
export const CREATE_PRESCRIPTION_LOADING = "CREATE_PRESCRIPTION_LOADING";
export const CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS";
export const CREATE_PRESCRIPTION_FAILED = "CREATE_PRESCRIPTION_FAILED";

export const UPDATE_PRESCRIPTION_SUCCESS = "UPDATE_PRESCRIPTION_SUCCESS";

export const GET_PRESCRIPTION_LOADING = "GET_PRESCRIPTION_LOADING";
export const GET_PRESCRIPTION_SUCCESS = "GET_PRESCRIPTION_SUCCESS";
export const GET_PRESCRIPTION_FAILED = "GET_PRESCRIPTION_FAILED";

export const GET_PRESCRIPTIONS_PLUS_LOADING = "GET_PRESCRIPTIONS_PLUS_LOADING";
export const GET_PRESCRIPTIONS_PLUS_SUCCESS = "GET_PRESCRIPTIONS_PLUS_SUCCESS";
export const GET_PRESCRIPTIONS_PLUS_FAILED = "GET_PRESCRIPTIONS_PLUS_FAILED";

export const GET_PRESCRIPTION_PLUS_LOADING = "GET_PRESCRIPTION_PLUS_LOADING";
export const GET_PRESCRIPTION_PLUS_SUCCESS = "GET_PRESCRIPTION_PLUS_SUCCESS";
export const GET_PRESCRIPTION_PLUS_FAILED = "GET_PRESCRIPTION_PLUS_FAILED";

export const GET_PRESCRIPTIONS_PLUS_BY_USER_LOADING =
  "GET_PRESCRIPTIONS_PLUS_BY_USER_LOADING";
export const GET_PRESCRIPTIONS_PLUS_BY_USER_SUCCESS =
  "GET_PRESCRIPTIONS_PLUS_BY_USER_SUCCESS";
export const GET_PRESCRIPTIONS_PLUS_BY_USER_FAILED =
  "GET_PRESCRIPTIONS_PLUS_BY_USER_FAILED";

export const DELETE_PRESCRIPTION_LOADING = "DELETE_PRESCRIPTION_LOADING";
export const DELETE_PRESCRIPTION_SUCCESS = "DELETE_PRESCRIPTION_SUCCESS";
export const DELETE_PRESCRIPTION_FAILED = "DELETE_PRESCRIPTION_FAILED";

export const GET_PRESCRIPTION_HISTORY_LOADING =
  "GET_PRESCRIPTION_HISTORY_LOADING";
export const GET_PRESCRIPTION_HISTORY_SUCCESS =
  "GET_PRESCRIPTION_HISTORY_SUCCESS";
export const GET_PRESCRIPTION_HISTORY_FAILED =
  "GET_PRESCRIPTION_HISTORY_FAILED";

export const SET_STATUS_TRACKING_LOADING = "SET_STATUS_TRACKING_LOADING";
export const SET_STATUS_TRACKING_SUCCESS = "SET_STATUS_TRACKING_SUCCESS";
export const SET_STATUS_TRACKING_FAILED = "SET_STATUS_TRACKING_FAILED";

export const UPDATE_TRACKING_SUCCESS = "UPDATE_TRACKING_SUCCESS";

export const SET_TRACKING_ID = "SET_TRACKING_ID";
export const CLEAR_TRACKING_ID = "CLEAR_TRACKING_ID";

export const CLEAR_PRESCRIPTION = "CLEAR_PRESCRIPTION";
export const CLEAR_PRESCRIPTION_HISTORY = "CLEAR_PRESCRIPTION_HISTORY";

// MEDICATION TYPES
export const GET_MEDICATIONS_LOADING = "GET_MEDICATIONS_LOADING";
export const GET_MEDICATIONS_SUCCESS = "GET_MEDICATIONS_SUCCESS";
export const GET_MEDICATIONS_FAILED = "GET_MEDICATIONS_FAILED";

export const GET_MEDICATION_LOADING = "GET_MEDICATION_LOADING";
export const GET_MEDICATION_SUCCESS = "GET_MEDICATION_SUCCESS";
export const GET_MEDICATION_FAILED = "GET_MEDICATION_FAILED";

export const CREATE_MEDICATION_LOADING = "CREATE_MEDICATION_LOADING";
export const CREATE_MEDICATION_SUCCESS = "CREATE_MEDICATION_SUCCESS";
export const CREATE_MEDICATION_FAILED = "CREATE_MEDICATION_FAILED";

export const UPDATE_MEDICATION_LOADING = "UPDATE_MEDICATION_LOADING";
export const UPDATE_MEDICATION_SUCCESS = "UPDATE_MEDICATION_SUCCESS";
export const UPDATE_MEDICATION_FAILED = "UPDATE_MEDICATION_FAILED";

export const CLEAR_MEDICATION = "CLEAR_MEDICATION";

export const GET_THERAPIES_LOADING = "GET_THERAPIES_LOADING";
export const GET_THERAPIES_SUCCESS = "GET_THERAPIES_SUCCESS";
export const GET_THERAPIES_FAILED = "GET_THERAPIES_FAILED";

export const CREATE_THERAPY_LOADING = "CREATE_THERAPY_LOADING";
export const CREATE_THERAPY_SUCCESS = "CREATE_THERAPY_SUCCESS";
export const CREATE_THERAPY_FAILED = "CREATE_THERAPY_FAILED";

export const SET_THERAPY = "SET_THERAPY";

export const UPDATE_THERAPY_LOADING = "UPDATE_THERAPY_LOADING";
export const UPDATE_THERAPY_SUCCESS = "UPDATE_THERAPY_SUCCESS";
export const UPDATE_THERAPY_FAILED = "UPDATE_THERAPY_FAILED";

export const CLEAR_THERAPY = "CLEAR_THERAPY";

// QUESTIONNAIRE TYPES
export const GET_ALL_RESULTS_LOADING = "GET_ALL_RESULTS_LOADING";
export const GET_ALL_RESULTS_SUCCESS = "GET_ALL_RESULTS_SUCCESS";
export const GET_ALL_RESULTS_FAILED = "GET_ALL_RESULTS_FAILED";

export const CREATE_QUESTION_LOADING = "CREATE_QUESTION_LOADING";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_FAILED = "CREATE_QUESTION_FAILED";

export const GET_QUESTIONS_LOADING = "GET_QUESTIONS_LOADING";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILED = "GET_QUESTIONS_FAILED";

export const GET_ASSESSMENTS_LOADING = "GET_ASSESSMENTS_LOADING";
export const GET_ASSESSMENTS_SUCCESS = "GET_ASSESSMENTS_SUCCESS";
export const GET_ASSESSMENTS_FAILED = "GET_ASSESSMENTS_FAILED";

export const GET_ASSESSMENT_LOADING = "GET_ASSESSMENT_LOADING";
export const GET_ASSESSMENT_SUCCESS = "GET_ASSESSMENT_SUCCESS";
export const GET_ASSESSMENT_FAILED = "GET_ASSESSMENT_FAILED";

export const CREATE_ASSESSMENT_LOADING = "CREATE_ASSESSMENT_LOADING";
export const CREATE_ASSESSMENT_SUCCESS = "CREATE_ASSESSMENT_SUCCESS";
export const CREATE_ASSESSMENT_FAILED = "CREATE_ASSESSMENT_FAILED";

export const UPDATE_ASSESSMENT_LOADING = "UPDATE_ASSESSMENT_LOADING";
export const UPDATE_ASSESSMENT_SUCCESS = "UPDATE_ASSESSMENT_SUCCESS";
export const UPDATE_ASSESSMENT_FAILED = "UPDATE_ASSESSMENT_FAILED";

export const GET_SURVEYS_LOADING = "GET_SURVEYS_LOADING";
export const GET_SURVEYS_SUCCESS = "GET_SURVEYS_SUCCESS";
export const GET_SURVEYS_FAILED = "GET_SURVEYS_FAILED";

export const GET_SURVEY_LOADING = "GET_SURVEY_LOADING";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";
export const GET_SURVEY_FAILED = "GET_SURVEY_FAILED";

export const CREATE_SURVEY_LOADING = "CREATE_SURVEY_LOADING";
export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const CREATE_SURVEY_FAILED = "CREATE_SURVEY_FAILED";

export const UPDATE_SURVEY_LOADING = "UPDATE_SURVEY_LOADING";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const UPDATE_SURVEY_FAILED = "UPDATE_SURVEY_FAILED";

export const GET_DOCUMENTS_LOADING = "GET_DOCUMENTS_LOADING";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILED = "GET_DOCUMENTS_FAILED";

export const GET_DOCUMENT_LOADING = "GET_DOCUMENT_LOADING";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILED = "GET_DOCUMENT_FAILED";

export const CREATE_DOCUMENT_LOADING = "CREATE_DOCUMENT_LOADING";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_FAILED = "CREATE_DOCUMENT_FAILED";

export const UPDATE_DOCUMENT_LOADING = "UPDATE_DOCUMENT_LOADING";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILED = "UPDATE_DOCUMENT_FAILED";

export const GET_ALL_DOCUMENT_RESULTS_LOADING =
  "GET_ALL_DOCUMENT_RESULTS_LOADING";
export const GET_ALL_DOCUMENT_RESULTS_SUCCESS =
  "GET_ALL_DOCUMENT_RESULTS_SUCCESS";
export const GET_ALL_DOCUMENT_RESULTS_FAILED =
  "GET_ALL_DOCUMENT_RESULTS_FAILED";

export const GET_PATIENT_QUESTIONNAIRES_LOADING =
  "GET_PATIENT_QUESTIONNAIRES_LOADING";
export const GET_PATIENT_QUESTIONNAIRES_SUCCESS =
  "GET_PATIENT_QUESTIONNAIRES_SUCCESS";
export const GET_PATIENT_QUESTIONNAIRES_FAILED =
  "GET_PATIENT_QUESTIONNAIRES_FAILED";

export const SET_VIEW_ASSESSMENT = "SET_VIEW_ASSESSMENT";
export const CLEAR_VIEW_ASSESSMENT = "CLEAR_VIEW_ASSESSMENT";

export const SET_VIEW_SURVEY = "SET_VIEW_SURVEY";
export const CLEAR_VIEW_SURVEY = "CLEAR_VIEW_SURVEY";

export const CLEAR_ASSESSMENT = "CLEAR_ASSESSMENT";
export const CLEAR_SURVEY = "CLEAR_SURVEY";
export const CLEAR_DOCUMENT = "CLEAR_DOCUMENT";

export const ADD_QUESTION = "ADD_QUESTION";
export const REMOVE_QUESTION = "REMOVE_QUESTION";
export const SET_QUESTION = "SET_QUESTION";
export const CLEAR_QUESTION = "CLEAR_QUESTION";

export const EDIT_QUESTION_LOADING = "EDIT_QUESTION_LOADING";
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS";
export const EDIT_QUESTION_FAILED = "EDIT_QUESTION_FAILED";

export const DELETE_QUESTION_LOADING = "DELETE_QUESTION_LOADING";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILED = "DELETE_QUESTION_FAILED";

export const ASSIGN_QUESTIONNAIRE_LOADING = "ASSIGN_QUESTIONNAIRE_LOADING";
export const ASSIGN_QUESTIONNAIRE_SUCCESS = "ASSIGN_QUESTIONNAIRE_SUCCESS";
export const ASSIGN_QUESTIONNAIRE_FAILED = "ASSIGN_QUESTIONNAIRE_FAILED";

export const UPDATE_QUESTIONNAIRE_RESULT_LOADING =
  "UPDATE_QUESTIONNAIRE_RESULT_LOADING";
export const UPDATE_QUESTIONNAIRE_RESULT_SUCCESS =
  "UPDATE_QUESTIONNAIRE_RESULT_SUCCESS";
export const UPDATE_QUESTIONNAIRE_RESULT_FAILED =
  "UPDATE_QUESTIONNAIRE_RESULT_FAILED";

export const GET_QUESTIONNAIRE_RESULT_HISTORY =
  "GET_QUESTIONNAIRE_RESULT_HISTORY";
export const GET_QUESTIONNAIRE_RESULT_HISTORY_SUCCESS =
  "GET_QUESTIONNAIRE_RESULT_HISTORY_SUCCESS";
export const GET_QUESTIONNAIRE_RESULT_HISTORY_FAILED =
  "GET_QUESTIONNAIRE_RESULT_HISTORY_FAILED";

export const CLEAR_ADDED_QUESTIONS = "CLEAR_ADDED_QUESTIONS";
export const SET_ADDED_QUESTIONS = "SET_ADDED_QUESTIONS";

export const ASSIGN_BY_PATIENTS_LOADING = "ASSIGN_BY_PATIENTS_LOADING";
export const ASSIGN_BY_PATIENTS_SUCCESS = "ASSIGN_BY_PATIENTS_SUCCESS";
export const ASSIGN_BY_PATIENTS_FAILED = "ASSIGN_BY_PATIENTS_FAILED";

// NOTIFICATION TYPES

export const GET_NOTIFICATIONS_LOADING = "GET_NOTIFICATIONS_LOADING";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED";

export const CREATE_NOTIFICATION_LOADING = "CREATE_NOTIFICATION_LOADING";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAILED = "CREATE_NOTIFICATION_FAILED";

// EDUCATION / RESOURCES TYPES
export const GET_RESOURCES_LOADING = "GET_RESOURCES_LOADING";
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
export const GET_RESOURCES_FAILED = "GET_RESOURCES_FAILED";

export const GET_RESOURCE_LOADING = "GET_RESOURCE_LOADING";
export const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS";
export const GET_RESOURCE_FAILED = "GET_RESOURCE_FAILED";

export const CREATE_RESOURCE_LOADING = "CREATE_RESOURCE_LOADING";
export const CREATE_RESOURCE_SUCCESS = "CREATE_RESOURCE_SUCCESS";
export const CREATE_RESOURCE_FAILED = "CREATE_RESOURCE_FAILED";

export const UPDATE_RESOURCE_LOADING = "UPDATE_RESOURCE_LOADING";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_FAILED = "UPDATE_RESOURCE_FAILED";

export const CLEAR_RESOURCE = "CLEAR_RESOURCE";

// MESSAGES TYPES
export const GET_ALL_MESSAGES_LOADING = "GET_ALL_MESSAGES_LOADING";
export const GET_ALL_MESSAGES_SUCCESS = "GET_ALL_MESSAGES_SUCCESS";
export const GET_ALL_MESSAGES_FAILED = "GET_ALL_MESSAGES_FAILED";

export const GET_UNSEEN_MESSAGES_LOADING = "GET_UNSEEN_MESSAGES_LOADING";
export const GET_UNSEEN_MESSAGES_SUCCESS = "GET_UNSEEN_MESSAGES_SUCCESS";
export const GET_UNSEEN_MESSAGES_FAILED = "GET_UNSEEN_MESSAGES_FAILED";

export const GET_ACTIVE_CONVERSATIONS_LOADING =
  "GET_ACTIVE_CONVERSATIONS_LOADING";
export const GET_ACTIVE_CONVERSATIONS_SUCCESS =
  "GET_ACTIVE_CONVERSATIONS_SUCCESS";
export const GET_ACTIVE_CONVERSATIONS_FAILED =
  "GET_ACTIVE_CONVERSATIONS_FAILED";

export const SET_ACTIVE_CONVERSATION = "SET_ACTIVE_CONVERSATION";
export const ADD_CONVERSATION = "ADD_CONVERSATION";

export const CREATE_CONVERSATION_LOADING = "CREATE_CONVERSATION_LOADING";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_FAILED = "CREATE_CONVERSATION_FAILED";

export const ARCHIVE_CONVERSATION_LOADING = "ARCHIVE_CONVERSATION_LOADING";
export const ARCHIVE_CONVERSATION_SUCCESS = "ARCHIVE_CONVERSATION_SUCCESS";
export const ARCHIVE_CONVERSATION_FAILED = "ARCHIVE_CONVERSATION_FAILED";

export const GET_ARCHIVED_CONVERSATIONS_LOADING =
  "GET_ARCHIVED_CONVERSATIONS_LOADING";
export const GET_ARCHIVED_CONVERSATIONS_SUCCESS =
  "GET_ARCHIVED_CONVERSATIONS_SUCCESS";
export const GET_ARCHIVED_CONVERSATIONS_FAILED =
  "GET_ARCHIVED_CONVERSATIONS_FAILED";

export const SET_ARCHIVED_CONVERSATION_SUCCESS =
  "SET_ARCHIVED_CONVERSATION_SUCCESS";

// DRAWER TYPES
export const DRAWER_OPEN = "DRAWER_OPEN";
export const DRAWER_CLOSE = "DRAWER_CLOSE";
export const SET_DRAWER_CONTENT = "SET_DRAWER_CONTENT";

// UPLOADED DOCUMENT TYPES
export const CREATE_UPLOADED_DOCUMENT_LOADING =
  "CREATE_UPLOADED_DOCUMENT_LOADING";
export const CREATE_UPLOADED_DOCUMENT_SUCCESS =
  "CREATE_UPLOADED_DOCUMENT_SUCCESS";
export const CREATE_UPLOADED_DOCUMENT_FAILED =
  "CREATE_UPLOADED_DOCUMENT_FAILED";

export const GET_UPLOADED_DOCUMENT_LOADING = "GET_UPLOADED_DOCUMENT_LOADING";
export const GET_UPLOADED_DOCUMENT_SUCCESS = "GET_UPLOADED_DOCUMENT_SUCCESS";
export const GET_UPLOADED_DOCUMENT_FAILED = "GET_UPLOADED_DOCUMENT_FAILED";

export const REMOVE_UPLOADED_DOCUMENT_LOADING =
  "REMOVE_UPLOADED_DOCUMENT_LOADING";
export const REMOVE_UPLOADED_DOCUMENT_SUCCESS =
  "REMOVE_UPLOADED_DOCUMENT_SUCCESS";
export const REMOVE_UPLOADED_DOCUMENT_FAILED =
  "REMOVE_UPLOADED_DOCUMENT_FAILED";

export const UPDATE_UPLOADED_DOCUMENT_LOADING =
  "UPDATE_UPLOADED_DOCUMENT_LOADING";
export const UPDATE_UPLOADED_DOCUMENT_SUCCESS =
  "UPDATE_UPLOADED_DOCUMENT_SUCCESS";
export const UPDATE_UPLOADED_DOCUMENT_FAILED =
  "UPDATE_UPLOADED_DOCUMENT_FAILED";

export const GET_DOCUMENT_BY_ID = "GET_DOCUMENT_BY_ID";
export const CLEAR_DOCUMENT_FORM = "CLEAR_DOCUMENT_FORM";
