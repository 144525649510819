import { DRAWER_OPEN, DRAWER_CLOSE, SET_DRAWER_CONTENT } from "../types";

const initialState = {
  open: false,
  content: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAWER_OPEN:
      return { ...state, open: true };
    case DRAWER_CLOSE:
      return { ...state, open: false };
    case SET_DRAWER_CONTENT:
      return { ...state, content: action.payload };
    default:
      return state;
  }
};
