import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getUsers,
  getUser,
  searchPatients,
} from "../../Redux/actions/userActions";
import { openDrawer } from "../../Redux/actions/drawerActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { formatDate, formatPhone } from "../../utils/formatData";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
    // margin: 20
  },
  header: {
    textTransform: "uppercase",
  },
  headerCell: {
    paddingTop: 0,
    color: "#798991",
    fontSize: 12,
    fontWeight: 400,
  },
  date: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.4,
    color: "#576C75",
  },
  name: {
    fontWeight: 500,
    color: "#283136",
    textDecoration: "none",
  },
});

const UsersTable = ({
  getUsers,
  getUser,
  users,
  user,
  openDrawer,
  searchPatients,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getUsers();
  }, []);

  const handleSearch = (e) => {
    searchPatients(e.target.value);
  };

  return (
    <>
      <div style={{ marginBottom: 25 }}>
        <TextField
          id="standard-basic"
          label="Search Patients"
          onChange={(e) => handleSearch(e)}
        />
      </div>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.headerCell}>Patient Name</TableCell>
              <TableCell className={classes.headerCell} align="center">
                Prescriptions
              </TableCell>
              <TableCell className={classes.headerCell}>Phone</TableCell>
              <TableCell className={classes.headerCell}>Date Created</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 &&
              users.map((user) => (
                <TableRow key={`patientTable-${user.id}`}>
                  <TableCell>
                    <a
                      className={classes.name}
                      href={`/patients/${user.id}`}
                    >{`${user.f_name} ${user.l_name}`}</a>
                  </TableCell>
                  <TableCell align="center">
                    {user.prescriptions.length}
                  </TableCell>
                  <TableCell>{formatPhone(user.phone)}</TableCell>
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                  <TableCell align="right">
                    <IconButton href={`/patients/${user.id}`}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  get_users_loading: state.userReducer.get_users_loading,
  error: state.userReducer.error,
  users: state.userReducer.users,
  admin: state.authReducer.admin,
});

export default connect(mapStateToProps, {
  getUsers,
  getUser,
  openDrawer,
  searchPatients,
})(UsersTable);
