import React from "react";
import { connect } from "react-redux";
import { openDrawer } from "../../Redux/actions/drawerActions";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Button, Grid } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import hasPermission from "../../utils/hasPermission";

const ActionsBar = ({ openDrawer, logged_in_permissions }) => {
  return (
    <Toolbar>
      <Grid style={{ paddingBottom: 40 }} container>
        <Grid item xs={3}>
          <Typography variant="h4" style={{ marginLeft: 10 }}>
            Patients
          </Typography>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid
          item
          xs={1}
          style={{
            display: "flex",
            textAlign: "right",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => openDrawer("plans")}
            style={{}}
            startIcon={<GraphicEqIcon />}
          >
            Plans
          </Button>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            textAlign: "right",
            justifyContent: "flex-end",
          }}
        >
          {hasPermission("CRUD_PATIENT", logged_in_permissions) && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => openDrawer("createUser")}
              style={{}}
              startIcon={<PersonAddIcon />}
            >
              Add New Patient
            </Button>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {
  openDrawer,
})(ActionsBar);
