import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPrescriptionsPlus,
  getPrescriptionPlus,
  createPrescription,
} from "../../Redux/actions/prescriptionActions";
import { openDrawer } from "../../Redux/actions/drawerActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AutoRenew from "@material-ui/icons/Autorenew";

const useStyles = makeStyles({
  container: {
    width: '100%',
    margin: "auto",
    // maxWidth: "95%",
  },
  table: {
    minWidth: 650,
  },
});

const PrescriptionsTable = ({
  createPrescription,
  getPrescriptionsPlus,
  getPrescriptionPlus,
  getPrescription,
  prescriptions,
  user,
  openDrawer,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getPrescriptionsPlus();
  }, []);

  const handleEditPrescription = (id) => {
    getPrescriptionPlus(id);
    openDrawer("createPrescription");
  };

  const handleRefillPrescription = (prescription) => {
    createPrescription({ ...prescription, parent_id: prescription.id });
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Medication</TableCell>
              <TableCell>Dosage</TableCell>
              <TableCell align="right">Quantity</TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prescriptions.length > 0 &&
              prescriptions.map((prescription) => (
                <TableRow key={`patientTable-${prescription.id}`}>
                  <TableCell>{prescription.patient_l_name}</TableCell>
                  <TableCell>{prescription.patient_f_name}</TableCell>
                  <TableCell>{prescription.medication_name}</TableCell>
                  <TableCell>{prescription.dosage}</TableCell>
                  <TableCell align="right">{prescription.quantity}</TableCell>

                  
                  <TableCell align="right">
                  <IconButton
                      onClick={() => handleEditPrescription(prescription.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleRefillPrescription(prescription)}
                    >
                      <AutoRenew />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

PrescriptionsTable.propTypes = {};

const mapStateToProps = (state) => ({
  get_prescriptions_loading:
    state.prescriptionReducer.get_prescriptions_loading,
  error: state.prescriptionReducer.error,
  prescriptions: state.prescriptionReducer.prescriptions,
  user: state.authReducer.user,
});

export default connect(mapStateToProps, {
  createPrescription,
  getPrescriptionsPlus,
  getPrescriptionPlus,
  openDrawer,
})(PrescriptionsTable);
