import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";

import ResourcesTable from "../Tables/ResourcesTable";
import ViewResource from "./ViewResource";

const ResourcesLibrary = ({ resource }) => {
  return (
    <div>
      {resource && Object.keys(resource).length > 0 ? (
        <ViewResource data={resource} />
      ) : (
        <ResourcesTable />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  resource: state.educationReducer.resource,
});

export default connect(mapStateToProps, {})(ResourcesLibrary);
