import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Card,
  CardHeader,
  Grid,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderBottom: "1px solid lightGrey",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Prescription = ({
  data,
  updateStatus,
  deletePre,
  viewHistory,
  openDrawer,
  logged_in_permissions,
  hasPermission,
  setActiveConversation,
  setTab,
}) => {
  const classes = useStyles();
  const [status, setStatus] = useState("");

  useEffect(() => {
    setStatus(data.status);
  }, []);

  const handleChange = (event) => {
    if (hasPermission("UPDATE_PRESCRIPTION_STATUS", logged_in_permissions)) {
      let value = event.target.value;
      let text = "";
      setStatus(value);
      if (value == "requested") {
        text = "Requested";
      } else if (value == "inProcess") {
        text = "In Process";
      } else if (value == "processed") {
        text = "Filled";
      } else if (value == "shipped") {
        text = "Shipped";
        openDrawer("addTracking", data.id);
      } else {
        text = "Billed";
      }
      setTab(event, 0);
      setActiveConversation(data.id);
      updateStatus(text, data, value);
    }
  };

  return (
    <Grid item className={classes.root}>
      <CardHeader
        action={
          <Grid container style={{ marginTop: 10 }}>
            <Grid item xs={8}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={status}
                  onChange={handleChange}
                  label="Status"
                >
                  <MenuItem disabled value="created">
                    Created
                  </MenuItem>
                  <MenuItem value="requested">Requested</MenuItem>
                  <MenuItem value="inProcess">In Process</MenuItem>
                  <MenuItem value="processed">Filled</MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="billed">Billed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        }
        title={data.medication_name}
        subheader={
          <div>
            <Grid item xs={12}>
              {`Dosage - ${data.dosage}`}
            </Grid>
            {data.tracking_link && status === "shipped" && (
              <Button
                href={`https://www.ups.com/track?loc=null&tracknum=${data.tracking_link}&requester=WT/trackdetails`}
                target="_blank"
                color="primary"
                style={{ padding: 0, marginTop: 10 }}
              >
                Tracking Link
              </Button>
            )}
          </div>
        }
        titleTypographyProps={{ variant: "subtitle2" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={8}>
            <Button onClick={viewHistory}>View History</Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              onClick={(e) => {
                setActiveConversation(data.id);
                setTab(e, 0);
              }}
            >
              View conversation
            </Button>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            {hasPermission(
              "UPDATE_PRESCRIPTION_STATUS",
              logged_in_permissions
            ) &&
              status === "shipped" && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  edge="end"
                  onClick={() => openDrawer("addTracking", data.id)}
                >
                  <LocalShippingIcon />
                </IconButton>
              )}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            {hasPermission("DELETE_PRESCRIPTION", logged_in_permissions) && (
              <IconButton
                aria-label="delete"
                size="small"
                edge="end"
                onClick={deletePre}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
};

export default Prescription;
