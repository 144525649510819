import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setStatusTracking } from "../../Redux/actions/prescriptionActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import useStyles from "../Auth/AuthFormStyles";

const AddTracking = ({ trackingId, setStatusTracking, userPrescriptions }) => {
  const classes = useStyles();
  const [tracking, setTracking] = useState("");

  useEffect(() => {
    const found = userPrescriptions.filter((p) => p.id === trackingId)[0];
    if (found) {
      setTracking(found.tracking_link);
    }
  }, [userPrescriptions]);

  const onChangeHandler = (e) => {
    setTracking(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatusTracking(trackingId, tracking);
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add Tracking Number
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                // error={error.username ? true : false}
                // helperText={error.username && error.username}
                variant="outlined"
                required
                fullWidth
                id="tracking"
                label="Tracking number"
                name="tracking"
                autoComplete="tracking"
                value={tracking}
                onChange={(e) => onChangeHandler(e)}
              />
            </Grid>
          </Grid>

          <Button
            // disabled={!typeSelected}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            Add Tracking Number
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  trackingId: state.prescriptionReducer.trackingId,
  userPrescriptions: state.prescriptionReducer.userPrescriptions,
});

export default connect(mapStateToProps, { setStatusTracking })(AddTracking);
