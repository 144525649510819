import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { passwordReset } from "../../Redux/actions/authActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Paper,
  Typography,
  Container,
} from "@material-ui/core";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import logo from "../../assets/img/icon.svg";
import useStyles from "./AuthFormStyles";

const PasswordReset = ({ passwordReset, history, error, toggleView }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    passwordReset({ username }, toggleView);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Paper style={{ padding: 40 }}>
        <CssBaseline />
        <img src={logo} width={192} />{" "}
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        <Typography variant="caption">
          You will receive a code by email to reset your password.
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            error={error.email ? true : false}
            helperText={error.email && error.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            onChange={(e) => setUsername(e.target.value)}
          />

          {error.error && (
            <Typography variant="body2" className={classes.errorText}>
              {error.error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            Get Reset Code
          </Button>
        </form>
        <a onClick={() => toggleView("signin")} href="#">
          Sign In
        </a>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  error: state.authReducer.error,
});

export default connect(mapStateToProps, { passwordReset })(PasswordReset);
