import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  GET_ALL_RESULTS_LOADING,
  GET_ALL_RESULTS_SUCCESS,
  GET_ALL_RESULTS_FAILED,
  CREATE_QUESTION_LOADING,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILED,
  EDIT_QUESTION_LOADING,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILED,
  GET_QUESTIONS_LOADING,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAILED,
  GET_ASSESSMENTS_LOADING,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_FAILED,
  GET_ASSESSMENT_LOADING,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAILED,
  CREATE_ASSESSMENT_LOADING,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAILED,
  GET_SURVEYS_LOADING,
  GET_SURVEYS_SUCCESS,
  GET_SURVEYS_FAILED,
  GET_SURVEY_LOADING,
  GET_SURVEY_SUCCESS,
  GET_SURVEY_FAILED,
  CREATE_SURVEY_LOADING,
  CREATE_SURVEY_SUCCESS,
  CREATE_SURVEY_FAILED,
  GET_DOCUMENTS_LOADING,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILED,
  GET_DOCUMENT_LOADING,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILED,
  CREATE_DOCUMENT_LOADING,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAILED,
  REMOVE_DOCUMENT,
  GET_PATIENT_QUESTIONNAIRES_LOADING,
  GET_PATIENT_QUESTIONNAIRES_SUCCESS,
  GET_PATIENT_QUESTIONNAIRES_FAILED,
  CLEAR_ASSESSMENT,
  CLEAR_SURVEY,
  CLEAR_DOCUMENT,
  ADD_QUESTION,
  REMOVE_QUESTION,
  DELETE_QUESTION_LOADING,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILED,
  SET_VIEW_ASSESSMENT,
  SET_VIEW_SURVEY,
  CLEAR_VIEW_ASSESSMENT,
  CLEAR_VIEW_SURVEY,
  SET_QUESTION,
  CLEAR_QUESTION,
  ASSIGN_QUESTIONNAIRE_LOADING,
  ASSIGN_QUESTIONNAIRE_SUCCESS,
  ASSIGN_QUESTIONNAIRE_FAILED,
  UPDATE_ASSESSMENT_LOADING,
  UPDATE_ASSESSMENT_SUCCESS,
  UPDATE_ASSESSMENT_FAILED,
  UPDATE_SURVEY_LOADING,
  UPDATE_SURVEY_SUCCESS,
  UPDATE_SURVEY_FAILED,
  UPDATE_DOCUMENT_LOADING,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILED,
  DRAWER_CLOSE,
  UPDATE_QUESTIONNAIRE_RESULT_LOADING,
  UPDATE_QUESTIONNAIRE_RESULT_SUCCESS,
  UPDATE_QUESTIONNAIRE_RESULT_FAILED,
  GET_QUESTIONNAIRE_RESULT_HISTORY,
  GET_QUESTIONNAIRE_RESULT_HISTORY_SUCCESS,
  GET_QUESTIONNAIRE_RESULT_HISTORY_FAILED,
  CREATE_UPLOADED_DOCUMENT_SUCCESS,
  CLEAR_ADDED_QUESTIONS,
  SET_ADDED_QUESTIONS,
  SEND_REMINDER_NOTIFICATION_LOADING,
  SEND_REMINDER_NOTIFICATION_SUCCESS,
  SEND_REMINDER_NOTIFICATION_FAILED,
  ASSIGN_BY_PATIENTS_LOADING,
  ASSIGN_BY_PATIENTS_SUCCESS,
  ASSIGN_BY_PATIENTS_FAILED,
} from "../types";

export const getAllResults = () => (dispatch) => {
  dispatch({
    type: GET_ALL_RESULTS_LOADING,
  });
  axiosWithAuth()
    .get("api/questionnaireResults/")
    .then((res) => {
      dispatch({
        type: GET_ALL_RESULTS_SUCCESS,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_ALL_RESULTS_FAILED,
        payload: err.response.data,
      });
    });
};

export const createQuestion = (question, clearVals) => (dispatch) => {
  dispatch({
    type: CREATE_QUESTION_LOADING,
  });
  axiosWithAuth()
    .post("api/questions/", question)
    .then((res) => {
      dispatch({
        type: CREATE_QUESTION_SUCCESS,
        payload: res.data.question,
      });
      clearVals();
    })
    .catch((err) => {
      console.error(err.response);
      dispatch({
        type: CREATE_QUESTION_FAILED,
        payload: err.response.data,
      });
    });
};

export const getQuestions = () => (dispatch) => {
  dispatch({
    type: GET_QUESTIONS_LOADING,
  });
  axiosWithAuth()
    .get("/api/questions/")
    .then((res) => {
      dispatch({
        type: GET_QUESTIONS_SUCCESS,
        payload: res.data.questions.reverse(),
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_QUESTIONS_FAILED,
        payload: err.response.data,
      });
    });
};

export const getAssessments = () => (dispatch) => {
  dispatch({
    type: GET_ASSESSMENTS_LOADING,
  });
  axiosWithAuth()
    .get("/api/questionnaires/")
    .then((res) => {
      dispatch({
        type: GET_ASSESSMENTS_SUCCESS,
        payload: res.data.questionnaires
          .filter((a) => a.type === "assessment")
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }

          // names must be equal
          return 0;
          }),
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_ASSESSMENTS_FAILED,
        payload: err.response.data,
      });
    });
};

export const getSurveys = () => (dispatch) => {
  dispatch({
    type: GET_SURVEYS_LOADING,
  });
  axiosWithAuth()
    .get("/api/questionnaires/")
    .then((res) => {
      dispatch({
        type: GET_SURVEYS_SUCCESS,
        payload: res.data.questionnaires.filter((a) => a.type === "survey"),
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_SURVEYS_FAILED,
        payload: err.response.data,
      });
    });
};

export const getDocuments = () => (dispatch) => {
  dispatch({
    type: GET_DOCUMENTS_LOADING,
  });
  axiosWithAuth()
    .get("/api/questionnaires/")
    .then((res) => {
      dispatch({
        type: GET_DOCUMENTS_SUCCESS,
        payload: res.data.questionnaires.filter((a) => a.type === "document"),
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_DOCUMENTS_FAILED,
        payload: err.response.data,
      });
    });
};

export const getAssessment = (id) => (dispatch) => {
  dispatch({
    type: GET_ASSESSMENT_LOADING,
  });
  axiosWithAuth()
    .get(`/api/questionnaires/${id}`)
    .then((res) => {
      dispatch({
        type: GET_ASSESSMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_ASSESSMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const getSurvey = (id) => (dispatch) => {
  dispatch({
    type: GET_SURVEY_LOADING,
  });
  axiosWithAuth()
    .get(`/api/questionnaires/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SURVEY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_SURVEY_FAILED,
        payload: err.response.data,
      });
    });
};

export const getDocument = (id) => (dispatch) => {
  dispatch({
    type: GET_DOCUMENT_LOADING,
  });
  axiosWithAuth()
    .get(`/api/questionnaires/${id}`)
    .then((res) => {
      dispatch({
        type: GET_DOCUMENT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_DOCUMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const clearAssessment = () => (dispatch) => {
  dispatch({
    type: CLEAR_ASSESSMENT,
  });
};

export const clearSurvey = () => (dispatch) => {
  dispatch({
    type: CLEAR_SURVEY,
  });
};

export const clearDocument = () => (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT,
  });
};

export const addQuestion = (question) => (dispatch) => {
  dispatch({
    type: ADD_QUESTION,
    payload: question,
  });
};

export const setAddedQuestions = (questions) => (dispatch) => {
  dispatch({
    type: SET_ADDED_QUESTIONS,
    payload: questions,
  });
};

export const removeQuestion = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_QUESTION,
    payload: id,
  });
};

export const clearAddedQuestions = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADDED_QUESTIONS,
  });
};

export const createAssessment = (assessment) => (dispatch) => {
  dispatch({
    type: CREATE_ASSESSMENT_LOADING,
  });
  axiosWithAuth()
    .post(`/api/questionnaires/`, assessment)
    .then((res) => {
      dispatch({
        type: CREATE_ASSESSMENT_SUCCESS,
        payload: res.data.error,
      });
      window.location.href = "/assessments";
    })
    .catch((err) => {
      dispatch({
        type: CREATE_ASSESSMENT_FAILED,
        payload: err.response.data,
      });
      throw err.response.data || err;
    });
};

export const updateAssessment = (id, assessment) => (dispatch) => {
  dispatch({
    type: UPDATE_ASSESSMENT_LOADING,
  });

  axiosWithAuth()
    .put(`/api/questionnaires/${id}`, assessment)
    .then((res) => {
      dispatch({
        type: UPDATE_ASSESSMENT_SUCCESS,
        payload: res.data.questionnaire,
      });
      window.alert("Successfully updated that assessment");
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: UPDATE_ASSESSMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const createSurvey = (survey) => (dispatch) => {
  dispatch({
    type: CREATE_SURVEY_LOADING,
  });
  axiosWithAuth()
    .post(`/api/questionnaires/`, survey)
    .then((res) => {
      dispatch({
        type: CREATE_SURVEY_SUCCESS,
        payload: res.data.questionnaire,
      });
      window.location.href = "/surveys";
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: CREATE_SURVEY_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateSurvey = (id, survey) => (dispatch) => {
  dispatch({
    type: UPDATE_SURVEY_LOADING,
  });

  axiosWithAuth()
    .put(`/api/questionnaires/${id}`, survey)
    .then((res) => {
      dispatch({
        type: UPDATE_SURVEY_SUCCESS,
        payload: res.data.questionnaire,
      });
      window.alert("Successfully updated that survey");
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: UPDATE_SURVEY_FAILED,
        payload: err.response.data,
      });
    });
};

export const createDocument = (document) => (dispatch) => {
  dispatch({
    type: CREATE_DOCUMENT_LOADING,
  });
  axiosWithAuth()
    .post(`/api/questionnaires/`, document)
    .then((res) => {
      dispatch({
        type: CREATE_DOCUMENT_SUCCESS,
        payload: res.data.questionnaire,
      });
      window.location.href = "/documents";
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: CREATE_DOCUMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateDocument = (id, document) => (dispatch) => {
  dispatch({
    type: UPDATE_DOCUMENT_LOADING,
  });

  axiosWithAuth()
    .put(`/api/questionnaires/${id}`, document)
    .then((res) => {
      dispatch({
        type: UPDATE_DOCUMENT_SUCCESS,
        payload: res.data.questionnaire,
      });
      window.alert("Successfully updated that document");
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: UPDATE_DOCUMENT_FAILED,
        payload: err.response.data,
      });
    });
};

export const getQuestionnairesByPatient = (id) => (dispatch) => {
  dispatch({
    type: GET_PATIENT_QUESTIONNAIRES_LOADING,
  });
  axiosWithAuth()
    .get(`/api/questionnaireResults/patients/${id}`)
    .then((res) => {
      dispatch({
        type: GET_PATIENT_QUESTIONNAIRES_SUCCESS,
        assessments: res.data.results.filter((q) => q.type == "assessment"),
        surveys: res.data.results.filter((q) => q.type == "survey"),
        documents: res.data.results.filter((q) => q.type == "document"),
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_PATIENT_QUESTIONNAIRES_FAILED,
        payload: err.response.data,
      });
    });
};

export const deleteConnectedQuestion = (id) => (dispatch) => {
  dispatch({
    type: DELETE_QUESTION_LOADING,
  });
  axiosWithAuth()
    .delete(`/api/questionnaires/connectedQuestion/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_QUESTION_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: DELETE_QUESTION_FAILED,
        payload: err.response.data,
      });
    });
};

export const setViewAssessment = (assessment) => (dispatch) => {
  dispatch({
    type: SET_VIEW_ASSESSMENT,
    payload: assessment,
  });
};

export const setViewSurvey = (survey) => (dispatch) => {
  dispatch({
    type: SET_VIEW_SURVEY,
    payload: survey,
  });
};

export const clearViewAssessment = () => (dispatch) => {
  dispatch({
    type: CLEAR_VIEW_ASSESSMENT,
  });
};

export const clearViewSurvey = () => (dispatch) => {
  dispatch({
    type: CLEAR_VIEW_SURVEY,
  });
};

export const setQuestion = (question) => (dispatch) => {
  dispatch({
    type: SET_QUESTION,
    payload: question,
  });
};

export const clearQuestion = () => (dispatch) => {
  dispatch({
    type: CLEAR_QUESTION,
  });
};

export const EditQuestion = (question, id) => (dispatch) => {
  dispatch({
    type: EDIT_QUESTION_LOADING,
  });
  const confirmed = window.confirm(
    "Editing a question COULD change past patient results. If you are making a significant change, it's recommended that you create a new question instead."
  );
  if (confirmed) {
    axiosWithAuth()
      .put(`api/questions/${id}`, question)
      .then((res) => {
        dispatch({
          type: EDIT_QUESTION_SUCCESS,
          payload: res.data.question,
        });
      })
      .catch((err) => {
        console.error(err.response.data);
        dispatch({
          type: EDIT_QUESTION_FAILED,
          payload: err.response.data,
        });
      });
  }
};

export const assignQuestionnaire = (questionnaire) => (dispatch) => {
  dispatch({
    type: ASSIGN_QUESTIONNAIRE_LOADING,
  });
  axiosWithAuth()
    .post(`api/questionnaireResults/`, questionnaire)
    .then((res) => {
      dispatch({
        type: ASSIGN_QUESTIONNAIRE_SUCCESS,
        payload: res.data.result,
      });
      dispatch({
        type: CREATE_UPLOADED_DOCUMENT_SUCCESS,
        payload: { ...res.data.result, documentType: "questionnaire" },
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: ASSIGN_QUESTIONNAIRE_FAILED,
        payload: err.response.data,
      });
    });
};

export const assignAssessmentsByPatients = (questionnaire) => (dispatch) => {
  dispatch({
    type: ASSIGN_BY_PATIENTS_LOADING,
  });
  axiosWithAuth()
    .post(`api/questionnaireResults/byPatients`, questionnaire)
    .then((res) => {
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: ASSIGN_BY_PATIENTS_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateQuestionnaireResult = (
  questionnaire,
  carelogUpdate,
  activeConversation
) => (dispatch) => {
  dispatch({
    type: UPDATE_QUESTIONNAIRE_RESULT_LOADING,
  });

  axiosWithAuth()
    .put(`api/questionnaireResults/`, questionnaire)
    .then((res) => {
      dispatch({
        type: UPDATE_QUESTIONNAIRE_RESULT_SUCCESS,
        payload: res.data.updatedResult,
      });
      if (Object.keys(activeConversation).length > 0) {
        carelogUpdate();
      }
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: UPDATE_QUESTIONNAIRE_RESULT_FAILED,
        payload: err.response.data,
      });
    });
};

export const fetchStatusHistory = (questionnaireResults_id) => (dispatch) => {
  dispatch({
    type: GET_QUESTIONNAIRE_RESULT_HISTORY,
  });

  axiosWithAuth()
    .get(
      `api/questionnaireResultsStatusHistory/questionnaireResults/${questionnaireResults_id}`
    )
    .then((res) => {
      dispatch({
        type: GET_QUESTIONNAIRE_RESULT_HISTORY_SUCCESS,
        payload: res.data.statusHistories,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_QUESTIONNAIRE_RESULT_HISTORY_FAILED,
        payload: err.response.data,
      });
    });
};

export const sendReminderNotification = (
  questionnaireType,
  questionnaireName,
  resultId,
  patient_id,
  triggerSnack
) => (dispatch) => {
  axiosWithAuth()
    .post(`api/questionnaireResults/reminder`, {
      questionnaireType,
      questionnaireName,
      resultId,
      patient_id,
    })
    .then((res) => {
      triggerSnack(true);
    })
    .catch((err) => {
      console.error(err.response.data);
    });
};
