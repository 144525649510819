import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUsers } from "../../../Redux/actions/userActions";
import {
  getAssessments,
  assignAssessmentsByPatients,
} from "../../../Redux/actions/questionnaireActions";
import {
  Button,
  TextField,
  Grid,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
} from "@material-ui/core";

import useStyles from "../../Auth/AuthFormStyles";

const SendByPatients = ({
  getUsers,
  getAssessments,
  assessments,
  users,
  assignAssessmentsByPatients,
}) => {
  const classes = useStyles();
  const [recipients, setRecipients] = useState([]);
  const [questionnaire, setQuestionnaire] = useState("");
  const [value, setValue] = useState(0);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });

  const onSelectAssessment = (e) => {
    setQuestionnaire(e.target.value);
  };

  const onSelect = (e) => {
    setRecipients(e.target.value);
  };

  const onChangeHandler = (e) => {
    setNotification({ ...notification, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (recipients.length > 0) {
      assignAssessmentsByPatients({
        patient_ids: recipients,
        questionnaire_id: questionnaire,
        answers: [],
        completed: false,
      });
    }
  };

  useEffect(() => {
    getUsers();
    getAssessments();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Assessments
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={questionnaire}
              onChange={onSelectAssessment}
              label={"Assessments"}
              fullWidth
            >
              {assessments &&
                assessments.map((assess) => (
                  <MenuItem key={assess.name} value={assess.id}>
                    <ListItemText primary={assess.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Recipients
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={recipients}
              onChange={onSelect}
              label="Recipients"
              fullWidth
              multiple
              renderValue={(selected) => {
                const obj = users.reduce((a, b) => ((a[b.id] = b), a), {});
                return selected
                  .map((s) => obj[s] && `${obj[s].f_name} ${obj[s].l_name}`)
                  .join(", ");
              }}
            >
              {users.map(({ l_name, f_name, id }) => {
                const name = `${f_name} ${l_name}`;
                return (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={recipients.indexOf(id) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )}
          {success && (
            <Typography
              variant="body2"
              id="success"
              className={classes.successText}
            >
              {success}
            </Typography>
          )} */}

      <Button
        // disabled={!typeSelected}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={(e) => handleSubmit(e)}
      >
        Assign Assessment
      </Button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  create_loading: state.adminReducer.create_loading,
  success: state.adminReducer.success,
  error: state.adminReducer.error,
  users: state.userReducer.users,
  assessments: state.questionnaireReducer.assessments,
});

export default connect(mapStateToProps, {
  getUsers,
  getAssessments,
  assignAssessmentsByPatients,
})(SendByPatients);
