import { axiosWithAuth } from "../../utils/axiosWithAuth";
import {
  CREATE_ADMIN_LOADING,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
  UPDATE_ADMIN_LOADING,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILED,
  GET_ADMINS_LOADING,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAILED,
  GET_ADMIN_LOADING,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILED,
  CLEAR_ADMIN,
  DRAWER_CLOSE,
  GET_PERMISSIONS_LOADING,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILED,
  GET_LOGGED_IN_PERMISSIONS_LOADING,
  GET_LOGGED_IN_PERMISSIONS_SUCCESS,
  GET_LOGGED_IN_PERMISSIONS_FAILED,
  ADD_PERMISSION_LOADING,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILED,
  REMOVE_PERMISSION_LOADING,
  REMOVE_PERMISSION_SUCCESS,
  REMOVE_PERMISSION_FAILED,
  TOGGLE_ACTIVATED_ADMIN_LOADING,
  TOGGLE_ACTIVATED_ADMIN_SUCCESS,
  TOGGLE_ACTIVATED_ADMIN_FAILED,
  ADMIN_PASSWORD_RESET_LOADING,
  ADMIN_PASSWORD_RESET_SUCCESS,
  ADMIN_PASSWORD_RESET_FAILED,
} from "../types";

export const createAdmin = (newUser) => (dispatch) => {
  dispatch({
    type: CREATE_ADMIN_LOADING,
  });

  axiosWithAuth()
    .post("/api/admins/", { ...newUser, role_id: 1 })
    .then((res) => {
      dispatch({
        type: CREATE_ADMIN_SUCCESS,
        payload: res.data.admin,
      });
      dispatch({
        type: DRAWER_CLOSE,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: CREATE_ADMIN_FAILED,
        payload: err.response.data,
      });
    });
};

export const updateAdmin =
  (updatedAdmin, id, originalUsername) => (dispatch) => {
    dispatch({
      type: UPDATE_ADMIN_LOADING,
    });

    axiosWithAuth()
      .put(`/api/admins/update/${id}`, updatedAdmin)
      .then((res) => {
        dispatch({
          type: UPDATE_ADMIN_SUCCESS,
          payload: updatedAdmin,
          original: originalUsername,
        });
        dispatch({
          type: DRAWER_CLOSE,
        });
      })
      .catch((err) => {
        console.error(err.response.data);
        dispatch({
          type: UPDATE_ADMIN_FAILED,
          payload: err.response.data,
        });
      });
  };

export const getAdmins = () => (dispatch) => {
  dispatch({
    type: GET_ADMINS_LOADING,
  });
  axiosWithAuth()
    .get("/api/admins/")
    .then((res) => {
      dispatch({
        type: GET_ADMINS_SUCCESS,
        payload: res.data.admins.sort((a, b) => {
          let nameA = a.username;
          let nameB = b.username;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_ADMINS_FAILED,
        payload: err.response.data,
      });
    });
};

export const getAdmin = (id) => (dispatch) => {
  dispatch({
    type: GET_ADMIN_SUCCESS,
    payload: id,
  });
};

export const clearAdmin = () => (dispatch) => {
  dispatch({
    type: CLEAR_ADMIN,
  });
};

export const getPermissionsByAdmin = (username) => (dispatch) => {
  dispatch({
    type: GET_PERMISSIONS_LOADING,
  });
  axiosWithAuth()
    .get(`/api/permissions/${username}`)
    .then((res) => {
      dispatch({
        type: GET_PERMISSIONS_SUCCESS,
        payload: res.data.permissions,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_PERMISSIONS_FAILED,
        payload: err.response.data,
      });
    });
};

export const addPermission = (permission) => (dispatch) => {
  dispatch({
    type: ADD_PERMISSION_LOADING,
  });
  axiosWithAuth()
    .post(`/api/permissions/`, permission)
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: ADD_PERMISSION_FAILED,
        payload: err.response.data,
      });
    });
};

export const removePermission = (permission) => (dispatch) => {
  dispatch({
    type: REMOVE_PERMISSION_LOADING,
  });
  axiosWithAuth()
    .delete(`/api/permissions/`, { data: permission })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: REMOVE_PERMISSION_FAILED,
        payload: err.response.data,
      });
    });
};

export const getLoggedInPermissions = (username) => (dispatch) => {
  dispatch({
    type: GET_LOGGED_IN_PERMISSIONS_LOADING,
  });
  axiosWithAuth()
    .get(`/api/permissions/${username}`)
    .then((res) => {
      dispatch({
        type: GET_LOGGED_IN_PERMISSIONS_SUCCESS,
        payload: res.data.permissions,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: GET_LOGGED_IN_PERMISSIONS_FAILED,
        payload: err.response.data,
      });
    });
};

export const toggleActivatedAdmin = (username) => (dispatch) => {
  dispatch({
    type: TOGGLE_ACTIVATED_ADMIN_LOADING,
  });
  axiosWithAuth()
    .put(`/api/admins/deactivate/${username}`)
    .then((res) => {
      dispatch({
        type: TOGGLE_ACTIVATED_ADMIN_SUCCESS,
        payload: res.data.admin,
      });
    })
    .catch((err) => {
      console.error(err.response.data);
      dispatch({
        type: TOGGLE_ACTIVATED_ADMIN_FAILED,
        payload: err.response.data,
      });
    });
};

export const adminPasswordResetRequest =
  (username, openSnack) => (dispatch) => {
    dispatch({
      type: ADMIN_PASSWORD_RESET_LOADING,
    });
    axiosWithAuth()
      .post(`/api/adminPasswordResetRequest/`, { username })
      .then((res) => {
        openSnack(true);
      })
      .catch((err) => {
        console.error(err.response.data);
        dispatch({
          type: ADMIN_PASSWORD_RESET_FAILED,
          payload: err.response.data,
        });
      });
  };
