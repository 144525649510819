import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signin } from "../../Redux/actions/authActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Paper,
  Typography,
  Container,
} from "@material-ui/core";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import logo from "../../assets/img/CottrillsCare-App-launchscreen.png";
import useStyles from "./AuthFormStyles";

const Signin = ({ signin, history, signin_loading, error, toggleView }) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const onChangeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const redirect = () => history.push("/dashboard");
    signin(user, redirect);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Paper style={{ padding: 40 }}>
        <CssBaseline />
        <img src={logo}  height={100} style={{ marginBottom: 20 }}/>{" "}
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            error={error.username ? true : false}
            helperText={error.username && error.username}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={(e) => onChangeHandler(e)}
          />
          <TextField
            error={error.password ? true : false}
            helperText={error.password && error.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => onChangeHandler(e)}
          />
          {error.error && (
            <Typography variant="body2" className={classes.errorText}>
              {error.error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {!signin_loading ? "SIGN IN" : "LOADING"}
          </Button>
        </form>
        <div style={{ padding: 5 }}>
          <a onClick={() => toggleView("setupAccount")} href="#">
            Setup New Account
          </a>
        </div>
        <div style={{ padding: 5 }}>
          <a onClick={() => toggleView("passwordReset")} href="#">
            Password Reset
          </a>
        </div>
      </Paper>
    </Container>
  );
};

Signin.propTypes = {};

const mapStateToProps = (state) => ({
  signin_loading: state.authReducer.signin_loading,
  error: state.authReducer.error,
});

export default connect(mapStateToProps, { signin })(Signin);
