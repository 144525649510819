import {
  CREATE_UPLOADED_DOCUMENT_LOADING,
  CREATE_UPLOADED_DOCUMENT_SUCCESS,
  CREATE_UPLOADED_DOCUMENT_FAILED,
  GET_UPLOADED_DOCUMENT_LOADING,
  GET_UPLOADED_DOCUMENT_SUCCESS,
  GET_UPLOADED_DOCUMENT_FAILED,
  REMOVE_UPLOADED_DOCUMENT_LOADING,
  REMOVE_UPLOADED_DOCUMENT_SUCCESS,
  REMOVE_UPLOADED_DOCUMENT_FAILED,
  GET_DOCUMENT_BY_ID,
  CLEAR_DOCUMENT_FORM,
  UPDATE_UPLOADED_DOCUMENT_LOADING,
  UPDATE_UPLOADED_DOCUMENT_SUCCESS,
  UPDATE_UPLOADED_DOCUMENT_FAILED,
  GET_ALL_DOCUMENT_RESULTS_LOADING,
  GET_ALL_DOCUMENT_RESULTS_SUCCESS,
  GET_ALL_DOCUMENT_RESULTS_FAILED,
} from "../types";

const initialState = {
  create_document_loading: false,
  get_document_loading: false,
  get_document_results_loading: false,
  remove_document_loading: false,
  update_document_loading: false,
  documents: [],
  documentResults: [],
  document: {},
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_UPLOADED_DOCUMENT_LOADING:
      return {
        ...state,
        create_document_loading: true,
      };
    case CREATE_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        create_document_loading: false,
        documents: [action.payload, ...state.documents],
      };
    case CREATE_UPLOADED_DOCUMENT_FAILED:
      return {
        ...state,
        create_document_loading: false,
        error: action.payload,
      };
    case GET_UPLOADED_DOCUMENT_LOADING:
      return {
        ...state,
        get_document_loading: true,
      };
    case GET_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        get_document_loading: false,
        documents: action.payload,
      };
    case GET_UPLOADED_DOCUMENT_FAILED:
      return {
        ...state,
        get_document_loading: false,
        error: action.payload,
      };
    case GET_UPLOADED_DOCUMENT_LOADING:
      return {
        ...state,
        remove_document_loading: true,
        error: action.payload,
      };
    case REMOVE_UPLOADED_DOCUMENT_SUCCESS:
      const documents = JSON.parse(JSON.stringify(state.documents));
      var removeIndex = state.documents
        .map((d) => d.id)
        .indexOf(action.payload);
      documents.splice(removeIndex, 1);
      return {
        ...state,
        documents,
        remove_document_loading: false,
      };
    case GET_UPLOADED_DOCUMENT_FAILED:
      return {
        ...state,
        remove_document_loading: false,
        error: action.payload,
      };
    case GET_DOCUMENT_BY_ID:
      return {
        ...state,
        document: state.documents.filter((d) => d.id === action.payload)[0],
      };
    case CLEAR_DOCUMENT_FORM:
      return {
        ...state,
        document: {},
      };
    case UPDATE_UPLOADED_DOCUMENT_LOADING:
      return {
        ...state,
        update_document_loading: true,
      };
    case UPDATE_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        update_document_loading: true,
        documents: [
          action.payload,
          ...state.documents.filter((d) => d.id != action.payload.id),
        ],
      };
    case UPDATE_UPLOADED_DOCUMENT_FAILED:
      return {
        ...state,
        update_document_loading: true,
        error: action.payload,
      };
    case GET_ALL_DOCUMENT_RESULTS_LOADING:
      return {
        ...state,
        get_document_results_loading: true,
      };
    case GET_ALL_DOCUMENT_RESULTS_SUCCESS:
      return {
        ...state,
        get_document_results_loading: false,
        documentResults: action.payload,
      };
    case GET_ALL_DOCUMENT_RESULTS_FAILED:
      return {
        ...state,
        get_document_results_loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
