import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPermissionsByAdmin,
  addPermission,
  removePermission,
  clearAdmin,
} from "../../Redux/actions/adminActions";
import { closeDrawer } from "../../Redux/actions/drawerActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Tooltip,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";

import useStyles from "../Auth/AuthFormStyles";

const AdminPermissions = ({
  error,
  getPermissionsByAdmin,
  admin,
  selected_permissions,
  addPermission,
  removePermission,
  closeDrawer,
  clearAdmin,
  logged_in_super_admin,
}) => {
  const classes = useStyles();
  const [superAdmin, setSuperAdmin] = useState(false);
  const [checked, setChecked] = useState({
    CREATE_ADMIN: false,
    UPDATE_ADMIN: false,
    ADMIN_PERMISSIONS_CONTROL: false,
    CRUD_PATIENT: false,
    CREATE_PRESCRIPTION: false,
    DELETE_PRESCRIPTION: false,
    UPDATE_PRESCRIPTION_STATUS: false,
    CRUD_MEDICATION: false,
    CRUD_QUESTIONNAIRE: false,
    ASSIGN_QUESTIONNAIRE: false,
    CRUD_NOTIFICATION: false,
    PATIENT_CHAT: false,
    CARE_LOG_CHAT: false,
    EDIT_ASSESSMENT: false,
    CRUD_RESOURCE: false,
    CRUD_DOCUMENT: false,
    DEACTIVATE_ADMIN: false,
  });

  useEffect(() => {
    return () => {
      clearAdmin();
      setSuperAdmin(false);
    };
  }, []);

  useEffect(() => {
    getPermissionsByAdmin(admin.username);
  }, [admin]);

  useEffect(() => {
    if (selected_permissions.length > 0) {
      let perms = {};

      if (
        selected_permissions.filter((p) => p.privilege === "SUPER_ADMIN")[0]
      ) {
        setSuperAdmin(true);
        perms = {
          CREATE_ADMIN: true,
          UPDATE_ADMIN: true,
          ADMIN_PERMISSIONS_CONTROL: true,
          CRUD_PATIENT: true,
          CREATE_PRESCRIPTION: true,
          DELETE_PRESCRIPTION: true,
          UPDATE_PRESCRIPTION_STATUS: true,
          CRUD_MEDICATION: true,
          CRUD_QUESTIONNAIRE: true,
          ASSIGN_QUESTIONNAIRE: true,
          CRUD_NOTIFICATION: true,
          PATIENT_CHAT: true,
          CARE_LOG_CHAT: true,
          EDIT_ASSESSMENT: true,
          CRUD_RESOURCE: true,
          CRUD_DOCUMENT: true,
          DEACTIVATE_ADMIN: true,
        };
      } else {
        selected_permissions.forEach((p) => {
          if (p.privilege != "SUPER_ADMIN") {
            perms[p.privilege] = true;
          }
        });
      }

      setChecked({ ...checked, ...perms });
    }
    return () =>
      setChecked({
        CREATE_ADMIN: false,
        UPDATE_ADMIN: false,
        ADMIN_PERMISSIONS_CONTROL: false,
        CRUD_PATIENT: false,
        CREATE_PRESCRIPTION: false,
        DELETE_PRESCRIPTION: false,
        UPDATE_PRESCRIPTION_STATUS: false,
        CRUD_MEDICATION: false,
        CRUD_QUESTIONNAIRE: false,
        ASSIGN_QUESTIONNAIRE: false,
        CRUD_NOTIFICATION: false,
        PATIENT_CHAT: false,
        CARE_LOG_CHAT: false,
        EDIT_ASSESSMENT: false,
        CRUD_RESOURCE: false,
        EDIT_ASSESSMENT: false,
        CRUD_RESOURCE: false,
        CRUD_DOCUMENT: false,
        DEACTIVATE_ADMIN: false,
      });
  }, [selected_permissions]);

  const handleChange = (e) => {
    setChecked({ ...checked, [e.target.name]: e.target.checked });
    if (e.target.checked) {
      addPermission({
        username: admin.username,
        privilege: e.target.name,
      });
    } else {
      removePermission({
        username: admin.username,
        privilege: e.target.name,
      });
    }
  };

  const close = (e) => {
    e.preventDefault();
    closeDrawer();
  };

  const setLabel = (key) => {
    switch (key) {
      case "CREATE_ADMIN":
        return "Create New Admins";
      case "UPDATE_ADMIN":
        return "Update Admins";
      case "DELETE_ADMIN":
        return "Delete Admins";
      case "ADMIN_PERMISSIONS_CONTROL":
        return "Modify Admin Permissions";
      case "CRUD_PATIENT":
        return "Create, Update or Delete Patients";
      case "CREATE_PRESCRIPTION":
        return "Create New Prescriptions";
      case "DELETE_PRESCRIPTION":
        return "Delete Prescriptions";
      case "UPDATE_PRESCRIPTION_STATUS":
        return "Update Prescription Status";
      case "CRUD_MEDICATION":
        return "Create, Update or Delete Medications";
      case "CRUD_QUESTIONNAIRE":
        return "Create, Update or Delete Assessments & Surveys";
      case "ASSIGN_QUESTIONNAIRE":
        return "Assign Assessments & Surveys";
      case "CRUD_NOTIFICATION":
        return "Create Notification";
      case "PATIENT_CHAT":
        return "Chat with Patients";
      case "CARE_LOG_CHAT":
        return "Care Log Chat";
      case "EDIT_ASSESSMENT":
        return "Edit Patient Assessments";
      case "CRUD_RESOURCE":
        return "Create and Update Resources";
      case "CRUD_DOCUMENT":
        return "Create and Update Documents";
      case "DEACTIVATE_ADMIN":
        return "Deactivate Admins";
      default:
        return key;
    }
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <NotificationsNoneOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Set Permissions for {admin.username}
        </Typography>
        {superAdmin && (
          <Typography variant="caption">
            Super Admin Privileges Can Not Be Changed
          </Typography>
        )}

        <form className={classes.form} noValidate style={{ minWidth: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
                required
              >
                {Object.keys(checked).map((key, val) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[key]}
                        onChange={handleChange}
                        name={key}
                        color="primary"
                        disabled={superAdmin}
                      />
                    }
                    label={setLabel(key)}
                  />
                ))}
              </FormControl>
            </Grid>
          </Grid>
          {/* {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )} */}

          <Button
            // disabled={!typeSelected}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => close(e)}
            disabled={superAdmin}
          >
            Update Permissions
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  admin: state.adminReducer.admin,
  selected_permissions: state.adminReducer.selected_permissions,
  logged_in_super_admin: state.adminReducer.logged_in_super_admin,
});

export default connect(mapStateToProps, {
  getPermissionsByAdmin,
  addPermission,
  removePermission,
  closeDrawer,
  clearAdmin,
})(AdminPermissions);
