import {
  DRAWER_OPEN,
  DRAWER_CLOSE,
  SET_DRAWER_CONTENT,
  CLEAR_USER,
  CLEAR_PRESCRIPTION,
  SET_TRACKING_ID,
  CLEAR_TRACKING_ID,
} from "../types";

export const openDrawer = (content, id) => (dispatch) => {
  dispatch({ type: DRAWER_OPEN });
  dispatch({ type: SET_DRAWER_CONTENT, payload: content });
  if (id) {
    dispatch({
      type: SET_TRACKING_ID,
      payload: id,
    });
  }
};

export const closeDrawer = () => (dispatch) => {
  dispatch({ type: DRAWER_CLOSE });
  dispatch({ type: CLEAR_PRESCRIPTION });
  dispatch({ type: CLEAR_TRACKING_ID });
};
