import moment from "moment-timezone";

export const sortFormatDate = (date) =>
  date ? moment(date, "YYYY-MM-DD HH:mm:ss").unix() : "";

export const formatDate = (date, format = "MMM Do, YYYY") => {
  if (date != null) {
    let formatted = moment
      .utc(date, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format(format);
    return formatted;
  } else {
    return "—";
  }
};

export const dateUnix = date =>
  date ? moment(date, 'YYYY-MM-DD HH:mm:ss').unix() : 0;

export const formatDateTime = (date) => {
  if (date != null) {
    let formatted = moment
      .utc(date, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("MMM Do, YYYY, h:mm:ss a");
    return formatted;
  } else {
    return "—";
  }
};

export const fromNow = (date) => {
  if (date != null) {
    let formatted = moment.utc(date).local().fromNow();
    return formatted;
  } else {
    return "—";
  }
};

export const checkWeekAgo = (date) => {
  if (date != null) {
    const weekAgo = moment().subtract(7, "days").calendar();
    const formatted = moment(date).format("M/DD/YYYY");
    if (formatted > weekAgo) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const formatPhone = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};
