import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { openDrawer } from "../../Redux/actions/drawerActions";
import { logoutAdmin } from "../../Redux/actions/authActions";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Navigation = ({ openDrawer, isAuthenticated, logoutAdmin }) => {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Cottrills Cares Admin
        </Typography>
        {isAuthenticated && (
          <>
            <Button color="inherit" onClick={() => openDrawer("createAdmin")}>
              Create Admin
            </Button>
            <Button color="inherit" onClick={() => openDrawer("createPatient")}>
              Create Patient
            </Button>
            <Button
              color="inherit"
              onClick={() => openDrawer("createPrescription")}
            >
              Create Prescription
            </Button>
            <Button color="inherit" onClick={logoutAdmin}>
              Logout
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, {
  openDrawer,
  logoutAdmin,
})(Navigation);
