import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getAdmins,
  getAdmin,
  adminPasswordResetRequest,
} from "../../Redux/actions/adminActions";
import { openDrawer } from "../../Redux/actions/drawerActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import EditIcon from "@material-ui/icons/Edit";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import EmailIcon from "@material-ui/icons/Email";
import Snackbar from "../Snackbar/Snackbar";

import { formatDate, formatPhone } from "../../utils/formatData";
import { VpnKey } from "@material-ui/icons";

import hasPermission from "../../utils/hasPermission";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
    // margin: 20
  },
  header: {
    textTransform: "uppercase",
  },
  headerCell: {
    paddingTop: 0,
    color: "#798991",
    fontSize: 12,
    fontWeight: 400,
  },
  date: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.4,
    color: "#576C75",
  },
  name: {
    fontWeight: 500,
    color: "#283136",
    textDecoration: "none",
  },
});

const AdminsTable = ({
  getAdmins,
  admins,
  getAdmin,
  openDrawer,
  logged_in_permissions,
  adminPasswordResetRequest,
}) => {
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const classes = useStyles();

  useEffect(() => {
    getAdmins();
  }, []);

  return (
    <>
      <Snackbar
        openSnackbar={openSnack}
        handleCloseSnackbar={handleCloseSnackbar}
        message={"Activation Email Sent!"}
      />
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.headerCell}>Username</TableCell>
              <TableCell className={classes.headerCell} align="center">
                Permissions
              </TableCell>
              <TableCell className={classes.headerCell}>Date Created</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.length > 0 &&
              admins.map((admin) => (
                <TableRow key={`adminsTable-${admin.username}`}>
                  <TableCell>
                    <a
                      className={classes.name}
                      // href={`/patients/${user.id}`}
                    >
                      {admin.username}
                    </a>
                  </TableCell>
                  <TableCell align="center">
                    {!admin.activated
                      ? "Not Active"
                      : admin.permissions.filter(
                          (p) => p.privilege === "SUPER_ADMIN"
                        )[0]
                      ? "Super Admin"
                      : "Limited Access"}
                  </TableCell>
                  <TableCell>{formatDate(admin.created_at)}</TableCell>
                  <TableCell align="right">
                    {hasPermission("UPDATE_ADMIN", logged_in_permissions) && (
                      <IconButton
                        onClick={() => {
                          getAdmin(admin.username);
                          openDrawer("createAdmin");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {hasPermission(
                      "ADMIN_PERMISSIONS_CONTROL",
                      logged_in_permissions
                    ) && (
                      <IconButton
                        onClick={() => {
                          getAdmin(admin.username);
                          openDrawer("adminPermissions");
                        }}
                      >
                        <VpnKeyIcon />
                      </IconButton>
                    )}
                    {hasPermission("UPDATE_ADMIN", logged_in_permissions) && (
                      <IconButton
                        onClick={() =>
                          adminPasswordResetRequest(
                            admin.username,
                            setOpenSnack
                          )
                        }
                      >
                        <EmailIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  admins: state.adminReducer.admins,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {
  getAdmins,
  openDrawer,
  getAdmin,
  adminPasswordResetRequest,
})(AdminsTable);
