import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getSurveys,
  getSurvey,
} from "../../Redux/actions/questionnaireActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { formatDate } from "../../utils/formatData";

import hasPermission from "../../utils/hasPermission";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
  name: {
    fontWeight: 500,
    color: "#283136",
    textDecoration: "none",
  },
});

const SurveysTable = ({
  getSurveys,
  getSurvey,
  surveys,
  history,
  logged_in_permissions,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Survey Name</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys.length > 0 &&
              surveys.map((surv) => (
                <TableRow key={`surveyTable-${surv.id}`}>
                  <TableCell className={classes.name}>{surv.name}</TableCell>
                  <TableCell>{formatDate(surv.created_at)}</TableCell>
                  <TableCell>{formatDate(surv.updated_at)}</TableCell>
                  <TableCell align="right">
                    {hasPermission(
                      "CRUD_QUESTIONNAIRE",
                      logged_in_permissions
                    ) && (
                      <IconButton
                        onClick={() => history.push(`/surveys/edit/${surv.id}`)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}

                    <IconButton
                      onClick={() => history.push(`/surveys/view/${surv.id}`)}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  surveys: state.questionnaireReducer.surveys,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {
  getSurveys,
  getSurvey,
})(SurveysTable);
