import React, { useEffect, useState } from "react";
import SignIn from "../Components/Auth/SignIn";
import PasswordReset from "../Components/Auth/PasswordReset";
import NewAccount from "../Components/Auth/NewAccount";

const Auth = ({ history, match }) => {
  const [view, setView] = useState("signin");

  useEffect(() => {
    if (match.params.var == "1") {
      setView("setupAccount");
    }
  }, [match.params.var]);

  if (view === "signin") {
    return <SignIn history={history} toggleView={setView} />;
  } else if (view === "passwordReset") {
    return <PasswordReset history={history} toggleView={setView} />;
  } else if (view === "setupAccount") {
    return <NewAccount history={history} toggleView={setView} />;
  }
};

export default Auth;
