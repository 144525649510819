import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createPrescription,
  updatePrescription,
} from "../../Redux/actions/prescriptionActions";
import { getMedications } from "../../Redux/actions/medicationActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import HealingIcon from "@material-ui/icons/Healing";

import useStyles from "../Auth/AuthFormStyles";

const CreatePrescription = ({
  createPrescription,
  updatePrescription,
  getUsers,
  getMedications,
  success,
  error,
  create_loading,
  prescription,
  medications,
  user,
}) => {
  const classes = useStyles();
  const [medication, setMedication] = useState({});
  const [newPrescription, setNewPrescription] = useState({
    dosage: "",
    quantity: "",
  });

  useEffect(() => {
    getMedications();
  }, []);

  useEffect(() => {
    if (Object.keys(prescription).length > 0) {
      setNewPrescription({
        dosage: prescription.dosage,
        quantity: prescription.quantity,
      });

      let medication;

      if (medications.length > 0) {
        medication = medications.filter(
          (m) => m.id == prescription.medication_id
        )[0];
      }

      setMedication(medication);
    }
  }, [prescription, medications]);

  const onChangeHandler = (e) => {
    setNewPrescription({ ...newPrescription, [e.target.name]: e.target.value });
  };

  const handleMedicationSelect = (e) => {
    setMedication(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(prescription).length > 0) {
      updatePrescription(
        {
          ...newPrescription,
          patient_id: user.id,
          medication_id: medication.id,
        },
        prescription.id
      );
    } else {
      createPrescription({
        ...newPrescription,
        patient_id: user.id,
        medication_id: medication.id,
      });
    }
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <HealingIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {Object.keys(prescription).length > 0
            ? "Edit Prescription"
            : "Create New Prescription"}
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={error["patient"] ? true : false}
                helperText={error["patient"] && error["patient"]}
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Patient"
                name="user"
                value={`${user.f_name} ${user.l_name}`}
                disabled
                style={{ textTransform: "capitalize" }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                // error={error.medication_id ? true : false}
              >
                <InputLabel id="medication-select-label">Medication</InputLabel>
                <Select
                  labelId="medication-select-label"
                  error={error["medication_id"] ? true : false}
                  id="medication-select"
                  value={medication}
                  onChange={handleMedicationSelect}
                  label="Medication"
                  style={{ width: "100%" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {medications &&
                    medications.length > 0 &&
                    medications.map((m) => (
                      <MenuItem value={m} key={m.name}>
                        {m.name}
                      </MenuItem>
                    ))}
                </Select>
                {error.medication_id && (
                  <FormHelperText error>{error.medication_id}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {Object.keys(newPrescription).map((key, index) => {
              return (
                <Grid item xs={12} key={`userFormInput_${key}`}>
                  <TextField
                    error={error[key] ? true : false}
                    helperText={error[key] && error[key]}
                    variant="outlined"
                    required
                    fullWidth
                    id={key}
                    label={key}
                    name={key}
                    value={newPrescription[key]}
                    onChange={(e) => onChangeHandler(e)}
                    style={{ textTransform: "capitalize" }}
                  />
                </Grid>
              );
            })}
          </Grid>
          {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
            >
              {error.error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {create_loading
              ? "LOADING"
              : Object.keys(prescription).length > 0
              ? "UPDATE"
              : "CREATE"}
          </Button>
        </form>
      </div>
    </Container>
  );
};

CreatePrescription.propTypes = {};

const mapStateToProps = (state) => ({
  create_loading: state.prescriptionReducer.create_loading,
  success: state.prescriptionReducer.success,
  prescription: state.prescriptionReducer.prescription,
  medications: state.medicationReducer.medications,
  error: state.prescriptionReducer.error,
  user: state.userReducer.user,
});

export default connect(mapStateToProps, {
  createPrescription,
  updatePrescription,
  getMedications,
})(CreatePrescription);
