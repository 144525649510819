import {
  GET_ALL_RESULTS_LOADING,
  GET_ALL_RESULTS_SUCCESS,
  GET_ALL_RESULTS_FAILED,
  CREATE_QUESTION_LOADING,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILED,
  EDIT_QUESTION_LOADING,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILED,
  GET_QUESTIONS_LOADING,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAILED,
  GET_ASSESSMENTS_LOADING,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_FAILED,
  GET_ASSESSMENT_LOADING,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAILED,
  CREATE_ASSESSMENT_LOADING,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAILED,
  GET_SURVEYS_LOADING,
  GET_SURVEYS_SUCCESS,
  GET_SURVEYS_FAILED,
  GET_SURVEY_LOADING,
  GET_SURVEY_SUCCESS,
  GET_SURVEY_FAILED,
  CREATE_SURVEY_LOADING,
  CREATE_SURVEY_SUCCESS,
  CREATE_SURVEY_FAILED,
  GET_DOCUMENTS_LOADING,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAILED,
  GET_DOCUMENT_LOADING,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILED,
  CREATE_DOCUMENT_LOADING,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAILED,
  GET_PATIENT_QUESTIONNAIRES_LOADING,
  GET_PATIENT_QUESTIONNAIRES_SUCCESS,
  GET_PATIENT_QUESTIONNAIRES_FAILED,
  CLEAR_ASSESSMENT,
  CLEAR_SURVEY,
  CLEAR_DOCUMENT,
  ADD_QUESTION,
  REMOVE_QUESTION,
  DELETE_QUESTION_LOADING,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILED,
  SET_VIEW_ASSESSMENT,
  SET_VIEW_SURVEY,
  CLEAR_VIEW_ASSESSMENT,
  CLEAR_VIEW_SURVEY,
  SET_QUESTION,
  CLEAR_QUESTION,
  ASSIGN_QUESTIONNAIRE_LOADING,
  ASSIGN_QUESTIONNAIRE_SUCCESS,
  ASSIGN_QUESTIONNAIRE_FAILED,
  UPDATE_QUESTIONNAIRE_RESULT_LOADING,
  UPDATE_QUESTIONNAIRE_RESULT_SUCCESS,
  UPDATE_QUESTIONNAIRE_RESULT_FAILED,
  GET_QUESTIONNAIRE_RESULT_HISTORY,
  GET_QUESTIONNAIRE_RESULT_HISTORY_SUCCESS,
  GET_QUESTIONNAIRE_RESULT_HISTORY_FAILED,
  CLEAR_ADDED_QUESTIONS,
  SET_ADDED_QUESTIONS,
} from "../types";

const initialState = {
  get_all_results_loading: false,
  create_question_loading: false,
  edit_question_loading: false,
  edit_result_loading: false,
  create_assessment_loading: false,
  create_survey_loading: false,
  create_document_loading: false,
  get_questions_loading: false,
  get_assessments_loading: false,
  get_surveys_loading: false,
  get_documents_loading: false,
  get_assessment_loading: false,
  get_survey_loading: false,
  get_document_loading: false,
  get_history_loading: false,
  delete_question_loading: false,
  get_patient_assessments_loading: false,
  allResults: [],
  patientAssessments: [],
  patientSurveys: [],
  patientDocuments: [],
  questions: [],
  question: {},
  assign_questionnaire_loading: false,
  assessments: [],
  surveys: [],
  documents: [],
  assessment: {},
  survey: {},
  document: {},
  addedQuestions: [],
  viewAssessment: {},
  viewSurvey: {},
  error: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_RESULTS_LOADING:
      return {
        ...state,
        get_all_results_loading: true,
        error: {},
      };
    case GET_ALL_RESULTS_SUCCESS:
      return {
        ...state,
        get_all_results_loading: false,
        allResults: action.payload,
        error: {},
      };
    case GET_ALL_RESULTS_FAILED:
      return {
        ...state,
        get_all_results_loading: false,
        error: action.payload,
      };
    case CREATE_QUESTION_LOADING:
      return {
        ...state,
        create_question_loading: true,
        error: {},
      };
    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        create_question_loading: false,
        questions: [action.payload, ...state.questions],
        addedQuestions: [...state.addedQuestions, action.payload],
        error: {},
      };
    case CREATE_QUESTION_FAILED:
      return {
        ...state,
        create_question_loading: false,
        error: action.payload,
      };
    case EDIT_QUESTION_LOADING:
      return {
        ...state,
        edit_question_loading: true,
        error: {},
      };
    case EDIT_QUESTION_SUCCESS:
      let found = state.assessment.questions.filter(
        (q) => q.id == action.payload.id
      )[0];
      return {
        ...state,
        edit_question_loading: false,
        questions: [
          action.payload,
          ...state.questions.filter((q) => q.id != action.payload.id),
        ],
        addedQuestions: found
          ? [
              action.payload,
              ...state.addedQuestions.filter((q) => q.id != action.payload.id),
            ]
          : state.addedQuestions,
        question: {},
        error: {},
      };
    case EDIT_QUESTION_FAILED:
      return {
        ...state,
        edit_question_loading: false,
        error: action.payload,
      };
    case GET_QUESTIONS_LOADING:
      return {
        ...state,
        get_questions_loading: true,
        error: {},
      };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        get_questions_loading: false,
        questions: action.payload,
        error: {},
      };
    case GET_QUESTIONS_FAILED:
      return {
        ...state,
        get_questions_loading: false,
        error: action.payload,
      };
    case GET_ASSESSMENTS_LOADING:
      return {
        ...state,
        get_assessments_loading: true,
        error: {},
      };
    case GET_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        get_assessments_loading: false,
        assessments: action.payload,
        error: {},
      };
    case GET_ASSESSMENTS_FAILED:
      return {
        ...state,
        get_assessments_loading: false,
        error: action.payload,
      };
    case GET_SURVEYS_LOADING:
      return {
        ...state,
        get_surveys_loading: true,
        error: {},
      };
    case GET_SURVEYS_SUCCESS:
      return {
        ...state,
        get_surveys_loading: false,
        surveys: action.payload,
        error: {},
      };
    case GET_SURVEYS_FAILED:
      return {
        ...state,
        get_surveys_loading: false,
        error: action.payload,
      };
    case GET_DOCUMENTS_LOADING:
      return {
        ...state,
        get_documents_loading: true,
        error: {},
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        get_documents_loading: false,
        documents: action.payload,
        error: {},
      };
    case GET_DOCUMENTS_FAILED:
      return {
        ...state,
        get_documents_loading: false,
        error: action.payload,
      };
    case GET_ASSESSMENT_LOADING:
      return {
        ...state,
        get_assessment_loading: true,
        error: {},
      };
    case GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        get_assessment_Loading: false,
        assessment: action.payload,
        error: {},
      };
    case GET_ASSESSMENT_FAILED:
      return {
        ...state,
        get_assessment_Loading: false,
        error: action.payload,
      };
    case GET_SURVEY_LOADING:
      return {
        ...state,
        get_survey_loading: true,
        error: {},
      };
    case GET_SURVEY_SUCCESS:
      return {
        ...state,
        get_survey_Loading: false,
        survey: action.payload,
        error: {},
      };
    case GET_SURVEY_FAILED:
      return {
        ...state,
        get_survey_Loading: false,
        error: action.payload,
      };
    case GET_DOCUMENT_LOADING:
      return {
        ...state,
        get_document_Loading: true,
        error: {},
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        get_document_Loading: false,
        document: action.payload,
        error: {},
      };
    case GET_DOCUMENT_FAILED:
      return {
        ...state,
        get_document_Loading: false,
        error: action.payload,
      };
    case CLEAR_ASSESSMENT:
      return {
        ...state,
        assessment: {},
      };
    case CLEAR_SURVEY:
      return {
        ...state,
        survey: {},
      };
    case CLEAR_DOCUMENT:
      return {
        ...state,
        document: {},
      };
    case ADD_QUESTION:
      return {
        ...state,
        addedQuestions: [...state.addedQuestions, action.payload],
      };
    case REMOVE_QUESTION:
      return {
        ...state,
        addedQuestions: state.addedQuestions.filter(
          (q) => q.id != action.payload
        ),
      };
    case CREATE_ASSESSMENT_LOADING:
      return {
        ...state,
        create_assessment_loading: true,
      };
    case CREATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        create_assessment_loading: false,
        assessments: [action.payload, ...state.assessments],
      };
    case CREATE_ASSESSMENT_FAILED:
      return {
        ...state,
        create_assessment_loading: false,
        error: action.payload,
      };
    case CREATE_SURVEY_LOADING:
      return {
        ...state,
        create_survey_loading: true,
      };
    case CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        create_survey_loading: false,
        surveys: [action.payload, ...state.surveys],
      };
    case CREATE_SURVEY_FAILED:
      return {
        ...state,
        create_survey_loading: false,
        error: action.payload,
      };
    case CREATE_DOCUMENT_LOADING:
      return {
        ...state,
        create_document_loading: true,
      };
    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        create_document_loading: false,
        documents: [action.payload, ...state.documents],
      };
    case CREATE_DOCUMENT_FAILED:
      return {
        ...state,
        create_document_loading: false,
        error: action.payload,
      };
    case GET_PATIENT_QUESTIONNAIRES_LOADING:
      return {
        ...state,
        get_patient_assessments_loading: true,
        error: {},
      };
    case GET_PATIENT_QUESTIONNAIRES_SUCCESS:
      return {
        ...state,
        get_patient_assessments_loading: false,
        patientAssessments: action.assessments,
        patientSurveys: action.surveys,
        patientDocuments: action.documents,
      };
    case GET_PATIENT_QUESTIONNAIRES_FAILED:
      return {
        ...state,
        get_patient_assessments_loading: false,
        error: action.payload,
      };
    case DELETE_QUESTION_LOADING:
      return {
        ...state,
        delete_question_loading: true,
        error: {},
      };
    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        delete_question_loading: false,
        // assessment: {
        //   ...state.assessment,
        //   questions: state.assessment.questions.filter(
        //     (q) => q.question_to_questionnaire_id != action.payload
        //   ),
        // },
      };
    case DELETE_QUESTION_FAILED:
      return {
        ...state,
        delete_question_loading: false,
        error: action.payload,
      };
    case SET_VIEW_ASSESSMENT:
      return {
        ...state,
        viewAssessment: action.payload,
      };
    case SET_VIEW_SURVEY:
      return {
        ...state,
        viewSurvey: action.payload,
      };
    case CLEAR_VIEW_ASSESSMENT:
      return {
        ...state,
        viewAssessment: {},
      };
    case CLEAR_VIEW_SURVEY:
      return {
        ...state,
        viewSurvey: {},
      };
    case SET_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case CLEAR_QUESTION:
      return {
        ...state,
        question: {},
      };
    case ASSIGN_QUESTIONNAIRE_LOADING:
      return {
        ...state,
        assign_questionnaire_loading: true,
        error: {},
      };
    case ASSIGN_QUESTIONNAIRE_SUCCESS:
      if (action.payload.type === "assessment") {
        return {
          ...state,
          assign_questionnaire_loading: false,
          patientAssessments: [action.payload, ...state.patientAssessments],
        };
      } else if (action.payload.type === "survey") {
        return {
          ...state,
          assign_questionnaire_loading: false,
          patientSurveys: [action.payload, ...state.patientSurveys],
        };
      } else if (action.payload.type === "document") {
        return {
          ...state,
          assign_questionnaire_loading: false,
          patientDocuments: [action.payload, ...state.patientDocuments],
        };
      }
    case ASSIGN_QUESTIONNAIRE_FAILED:
      return {
        ...state,
        assign_questionnaire_loading: false,
        error: action.payload,
      };
    case UPDATE_QUESTIONNAIRE_RESULT_LOADING:
      return {
        ...state,
        edit_result_loading: true,
        error: {},
      };
    case UPDATE_QUESTIONNAIRE_RESULT_SUCCESS:
      return {
        ...state,
        edit_result_loading: false,
        viewAssessment: {
          ...state.viewAssessment,
          answers: action.payload.answers,
        },
        error: {},
      };
    case UPDATE_QUESTIONNAIRE_RESULT_FAILED:
      return {
        ...state,
        edit_result_loading: false,
        error: action.payload,
      };
    case GET_QUESTIONNAIRE_RESULT_HISTORY:
      return {
        ...state,
        get_history_loading: true,
      };
    case GET_QUESTIONNAIRE_RESULT_HISTORY_SUCCESS:
      return {
        ...state,
        viewAssessment: {
          ...state.viewAssessment,
          statusHistories: action.payload,
        },
        get_history_loading: false,
      };
    case GET_QUESTIONNAIRE_RESULT_HISTORY_FAILED:
      return {
        ...state,
        get_history_loading: false,
        error: action.payload,
      };
    case CLEAR_ADDED_QUESTIONS:
      return {
        ...state,
        addedQuestions: [],
      };
    case SET_ADDED_QUESTIONS:
      return {
        ...state,
        addedQuestions: action.payload,
      };
    default:
      return state;
  }
};
