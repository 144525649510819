import {
  CREATE_PRESCRIPTION_LOADING,
  CREATE_PRESCRIPTION_SUCCESS,
  CREATE_PRESCRIPTION_FAILED,
  UPDATE_PRESCRIPTION_SUCCESS,
  CLEAR_PRESCRIPTION,
  GET_PRESCRIPTION_LOADING,
  GET_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_FAILED,
  GET_PRESCRIPTIONS_PLUS_LOADING,
  GET_PRESCRIPTIONS_PLUS_SUCCESS,
  GET_PRESCRIPTIONS_PLUS_FAILED,
  GET_PRESCRIPTION_PLUS_LOADING,
  GET_PRESCRIPTION_PLUS_SUCCESS,
  GET_PRESCRIPTION_PLUS_FAILED,
  GET_PRESCRIPTIONS_PLUS_BY_USER_LOADING,
  GET_PRESCRIPTIONS_PLUS_BY_USER_SUCCESS,
  GET_PRESCRIPTIONS_PLUS_BY_USER_FAILED,
  DELETE_PRESCRIPTION_LOADING,
  DELETE_PRESCRIPTION_SUCCESS,
  DELETE_PRESCRIPTION_FAILED,
  GET_PRESCRIPTION_HISTORY_LOADING,
  GET_PRESCRIPTION_HISTORY_SUCCESS,
  GET_PRESCRIPTION_HISTORY_FAILED,
  CLEAR_PRESCRIPTION_HISTORY,
  SET_TRACKING_ID,
  CLEAR_TRACKING_ID,
  UPDATE_TRACKING_SUCCESS,
} from "../types";

const initialState = {
  create_loading: false,
  get_prescriptions_loading: false,
  get_prescription_loading: false,
  delete_prescription_loading: false,
  get_status_history_loading: false,
  success: null,
  prescriptions: [],
  requested: [],
  inProcess: [],
  processed: [],
  shipped: [],
  prescription: {},
  userPrescriptions: [],
  userStatusHistory: [],
  error: {},
  trackingId: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PRESCRIPTION_LOADING:
      return {
        ...state,
        create_loading: true,
        success: null,
        error: {},
      };
    case CREATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        create_loading: false,
        success: `You have successfully created the prescription`,
        prescriptions: [...state.prescriptions, action.payload],
        userPrescriptions: [...state.userPrescriptions, action.payload],
      };
    case CREATE_PRESCRIPTION_FAILED:
      return {
        ...state,
        create_loading: false,
        error: action.payload,
        success: null,
      };
    case UPDATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        create_loading: false,
        success: `You have successfully updated the prescription`,
        prescriptions: [
          ...state.prescriptions.filter((p) => p.id !== action.payload.id),
          action.payload,
        ],
        userPrescriptions: state.userPrescriptions.length > 0 && [
          ...state.userPrescriptions.map((p) => {
            if (p.id === action.payload.id) {
              return action.payload;
            }
            return p;
          }),
        ],
      };
    case GET_PRESCRIPTIONS_PLUS_LOADING:
      return {
        ...state,
        get_prescriptions_loading: true,
        error: {},
      };
    case GET_PRESCRIPTIONS_PLUS_SUCCESS:
      return {
        ...state,
        get_prescriptions_loading: false,
        prescriptions: action.payload,
        requested: action.requested,
        inProcess: action.inProcess,
        processed: action.processed,
        shipped: action.shipped,
        error: {},
      };
    case GET_PRESCRIPTIONS_PLUS_FAILED:
      return {
        ...state,
        get_prescriptions_loading: false,
        prescriptions: [],
        error: action.payload,
      };
    case GET_PRESCRIPTION_PLUS_LOADING:
      return {
        ...state,
        get_prescription_loading: true,
        error: {},
      };
    case GET_PRESCRIPTION_PLUS_SUCCESS:
      return {
        ...state,
        get_prescription_loading: false,
        prescription: action.payload,
        error: {},
      };
    case GET_PRESCRIPTION_PLUS_FAILED:
      return {
        ...state,
        get_prescription_loading: false,
        error: action.payload,
      };
    case GET_PRESCRIPTIONS_PLUS_BY_USER_LOADING:
      return {
        ...state,
        get_prescriptions_loading: true,
        error: {},
      };
    case GET_PRESCRIPTIONS_PLUS_BY_USER_SUCCESS:
      return {
        ...state,
        get_prescriptions_loading: false,
        userPrescriptions: action.payload,
        error: {},
      };
    case GET_PRESCRIPTIONS_PLUS_BY_USER_FAILED:
      return {
        ...state,
        get_prescriptions_loading: false,
        error: action.payload,
      };
    case DELETE_PRESCRIPTION_LOADING:
      return {
        ...state,
        delete_prescription_loading: true,
        error: {},
      };
    case DELETE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        delete_prescription_loading: false,
        userPrescriptions: state.userPrescriptions.filter(
          (p) => p.id !== action.payload.id
        ),
        error: {},
      };
    case DELETE_PRESCRIPTION_FAILED:
      return {
        ...state,
        delete_prescription_loading: false,
        error: action.payload,
      };
    case GET_PRESCRIPTION_HISTORY_LOADING:
      return {
        ...state,
        get_status_history_loading: true,
        error: {},
      };
    case GET_PRESCRIPTION_HISTORY_SUCCESS:
      return {
        ...state,
        get_status_history_loading: false,
        userStatusHistory: action.payload.sort((a, b) => {
          return a.row - b.row;
        }),
        error: {},
      };
    case GET_PRESCRIPTION_HISTORY_FAILED:
      return {
        ...state,
        get_status_history_loading: false,
        error: action.payload,
      };
    case CLEAR_PRESCRIPTION_HISTORY:
      return {
        ...state,
        userStatusHistory: [],
      };
    case CLEAR_PRESCRIPTION:
      return {
        ...state,
        prescription: {},
        error: {},
      };
    case SET_TRACKING_ID:
      return {
        ...state,
        trackingId: action.payload,
      };
    case CLEAR_TRACKING_ID:
      return {
        ...state,
        trackingId: "",
      };
    case UPDATE_TRACKING_SUCCESS:
      return {
        ...state,
        userPrescriptions: state.userPrescriptions.map((p) => {
          if (p.id == action.payload.id) {
            p.tracking_link = action.payload.tracking_link;
          }
          return p;
        }),
      };
    default:
      return state;
  }
};
