import React from "react";
import { connect } from "react-redux";
import { openDrawer } from "../../Redux/actions/drawerActions";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SendIcon from "@material-ui/icons/Send";

import hasPermission from "../../utils/hasPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // width: 1000,
    // margin: "auto",
    // maxWidth: "95%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: 10,
  },
}));

const ActionsBar = ({ openDrawer, logged_in_permissions }) => {
  const classes = useStyles();
  return (
    <Toolbar>
      <Grid style={{ paddingBottom: 40 }} container>
        <Typography variant="h4" className={classes.title}>
          Notifications
        </Typography>

        <Grid
          item
          style={{
            display: "flex",
            textAlign: "right",
            justifyContent: "flex-end",
          }}
        >
          {hasPermission("CRUD_NOTIFICATION", logged_in_permissions) && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => openDrawer("createNotification")}
              startIcon={<SendIcon />}
            >
              Create Notification
            </Button>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  logged_in_permissions: state.adminReducer.logged_in_permissions,
});

export default connect(mapStateToProps, {
  openDrawer,
})(ActionsBar);
