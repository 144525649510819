import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closeDrawer } from "../../Redux/actions/drawerActions";
import {
  clearErrors,
  getPlans,
  setError,
} from "../../Redux/actions/userActions";
import NumberFormat from "react-number-format";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  createUser,
  updateUser,
  clearUser,
} from "../../Redux/actions/userActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  CircularProgress,
  Tooltip,
  withStyles,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AccountCircle from "@material-ui/icons/AccountCircle";

import useStyles from "../Auth/AuthFormStyles";
import userReducer from "../../Redux/reducers/userReducer";

const CreateUser = ({
  user,
  createUser,
  updateUser,
  create_loading,
  success,
  error,
  closeDrawer,
  clearErrors,
  getPlans,
  plans,
  setError,
}) => {
  const classes = useStyles();
  const [admin, setAdmin] = useState(false);
  const [plan, setPlan] = useState("");
  const [newUser, setNewUser] = useState({
    email: "",
    f_name: "",
    l_name: "",
    gender: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    birth: "1970-01-01T05:00:00.000Z",
    plan: "",
  });

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      Object.keys(user).map((key, index) => {
        if (user[key] == null) {
          user[key] = "";
        }
      });
      let phone = user.phone.substring(2);
      setNewUser({
        email: user.email,
        f_name: user.f_name,
        l_name: user.l_name,
        gender: user.gender,
        address: user.address,
        city: user.city,
        state: user.state,
        zip: user.zip,
        phone: phone,
        birth: user.birth,
        plan: user.plan,
      });
      setPlan(user.plan);
    }

    return () => {
      clearErrors();
    };
  }, [user]);

  useEffect(() => {
    getPlans();
  }, []);

  const onChangeHandler = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    setNewUser({ ...newUser, birth: date });
  };

  const handleSubmit = (e) => {
    let formattedUser = { ...newUser };
    e.preventDefault();
    formattedUser.phone = formattedUser.phone.replace(/[' ', (, ), -]/g, "");
    if (formattedUser.phone.charAt(0) != "+") {
      formattedUser.phone = "+1" + formattedUser.phone;
    }
    if (formattedUser.phone.length < 12) {
      setError({ phone: "Incorrect Phone Field Format" });
      return;
    }

    if (Object.keys(user).length > 0) {
      updateUser(formattedUser, user.id);
    } else {
      createUser(formattedUser);
    }
  };

  const handlePlanSelect = (e) => {
    setPlan(e.target.value);
    setNewUser({ ...newUser, plan: e.target.value });
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircle />
        </Avatar>
        <Typography component="h1" variant="h5">
          {Object.keys(user).length > 0 ? "Edit Patient" : "Create New Patient"}
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            {Object.keys(newUser).map((key, index) => {
              return (
                <Grid item xs={12} key={`userFormInput_${key}`}>
                  {key == "phone" ? (
                    <NumberFormat
                      error={error[key] ? true : false}
                      helperText={error[key] && error[key]}
                      variant="outlined"
                      fullWidth
                      customInput={TextField}
                      value={newUser[key]}
                      onChange={(e) => onChangeHandler(e)}
                      label="Phone Number"
                      format="+1 (###) ###-####"
                      name="phone"
                    />
                  ) : key == "birth" ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        error={error[key] ? true : false}
                        helperText={error[key] && error[key]}
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Birthdate"
                        name="birth"
                        clearable
                        autoOk
                        animateYearScrolling
                        disableFuture
                        openTo="year"
                        orientation="landscape"
                        onChange={(d) => handleDateChange(d)}
                        value={newUser.birth}
                        style={{ width: "100%" }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  ) : key === "plan" ? (
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      // error={error.medication_id ? true : false}
                    >
                      <InputLabel id="medication-select-label">Plan</InputLabel>
                      <Select
                        labelId="plan-select-label"
                        error={error["plan"] ? true : false}
                        id="plan-select"
                        value={plan}
                        selected={plan}
                        onChange={handlePlanSelect}
                        label="Plan"
                        style={{ width: "100%" }}
                      >
                        {plans &&
                          plans.length > 0 &&
                          plans.map((p) => (
                            <MenuItem value={p.name} key={p.name}>
                              {p.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {error.medication_id && (
                        <FormHelperText error>
                          {error.medication_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <TextField
                      error={error[key] ? true : false}
                      helperText={error[key] && error[key]}
                      variant="outlined"
                      required={key != "email" ? true : false}
                      fullWidth
                      id={key}
                      label={
                        key == "f_name"
                          ? "First Name"
                          : key == "l_name"
                          ? "Last Name"
                          : key
                      }
                      name={key}
                      value={newUser[key]}
                      onChange={(e) => onChangeHandler(e)}
                      style={{ textTransform: "capitalize" }}
                    />
                  )}
                </Grid>
              );
            })}

            <Grid item xs={12}></Grid>
          </Grid>
          {error.error && (
            <Typography
              variant="body2"
              id="error"
              className={classes.errorText}
              style={{ color: "red" }}
            >
              {error.error}
            </Typography>
          )}
          {success && (
            <Typography
              variant="body2"
              id="success"
              className={classes.successText}
            >
              {success}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {Object.keys(user).length > 0
              ? !create_loading
                ? "UPDATE"
                : "LOADING"
              : !create_loading
              ? "CREATE"
              : "LOADING"}
            {}
          </Button>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  create_loading: state.userReducer.create_loading,
  success: state.userReducer.success,
  error: state.userReducer.error,
  plans: state.userReducer.plans,
});

export default connect(mapStateToProps, {
  createUser,
  updateUser,
  closeDrawer,
  clearErrors,
  getPlans,
  setError,
})(CreateUser);
