import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  IconButton,
  Typography,
  CardHeader,
  Grid,
  CardContent,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cardHeaderContent: {
    overflow: "hidden",
  },
  title: {
    // width: "75%",
    maxWidth: 350,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // maxHeight: 24,
  },
}));

const QuestionItem = ({
  data,
  add,
  remove,
  setQuestion,
  edit,
  added,
  deleteQ,
}) => {
  const classes = useStyles();

  const getType = (type) => {
    switch (type) {
      case "multiple-choice":
        return "Multiple Choice";
      case "yes-no":
        return "Yes / No";
      case "text-area":
        return "Text Area";
      case "text-block":
        return "Text Block";
      case "number":
        return "Number";
      case "date-picker":
        return "Date Picker";
      case "signature":
        return null;
      default:
        return "N/A";
    }
  };

  const setDeleteButton = (newQ) => {
    if (!edit || newQ) {
      return (
        <IconButton onClick={() => remove(data.id)} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          onClick={() => {
            let confirm = window.confirm(
              "Deleting this question will result in the question being removed from all previous patient results"
            );
            if (confirm) {
              deleteQ();
            }
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  };

  return (
    <Grid item className={classes.root}>
      <Paper style={{ marginBottom: 10 }}>
        <CardHeader
          classes={{
            content: classes.cardHeaderContent,
          }}
          action={
            <Grid
              container
              justify="flex-end"
              direction="column"
              style={{ marginTop: 10, width: 64 }}
            >
              {add ? (
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    onClick={() => add({ ...data, new: true })}
                  >
                    Add
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setQuestion(data)}
                  >
                    Edit
                  </Button>
                </>
              ) : (
                setDeleteButton(data.new)
              )}
            </Grid>
          }
          title={data.body != "" ? data.body : "Signature"}
          subheader={
            <>
              {getType(data.questionType)}
              {data.questionType != "text-block" &&
              data.questionType != "signature" ? (
                <Typography variant="subtitle2">
                  {`Additional question: ${data.additional ? "true" : "false"}`}
                </Typography>
              ) : (
                <Typography variant="subtitle2">
                  {data.description.substring(0, 150) + "..."}
                </Typography>
              )}

              {getType(data.questionType) === "Multiple Choice" && (
                <Typography variant="subtitle2">
                  {`Multiple answer: ${
                    data.answersType === "singleAnswer" ? "false" : "true"
                  }`}
                </Typography>
              )}
            </>
          }
          titleTypographyProps={{
            variant: "subtitle1",
            // display: "block",
            // noWrap: true,
            className: classes.title,
          }}
          subheaderTypographyProps={{ variant: "subtitle2" }}
        />

        {getType(data.questionType) == "Multiple Choice" && (
          <CardContent style={{ paddingTop: 0 }}>
            <ul>
              {data.answers.map((a, i) => (
                <li key={i}>{a}</li>
              ))}
            </ul>
          </CardContent>
        )}
      </Paper>
    </Grid>
  );
};

export default QuestionItem;
