import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getPrescriptionHistory,
  clearPrescriptionHistory,
} from "../../Redux/actions/prescriptionActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { formatDateTime } from "../../utils/formatData";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PrescriptionDialog({
  open,
  setOpen,
  getPrescriptionHistory,
  pre,
  userStatusHistory,
  clearPrescriptionHistory,
}) {
  const classes = useStyles();

  useEffect(() => {
    pre && pre.id && getPrescriptionHistory(pre.id);
    return () => {
      clearPrescriptionHistory();
    };
  }, [pre]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {`Status History for ${pre.medication_name}`}
        </DialogTitle>
        <DialogContent>
          <TableContainer className={classes.container}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Admin</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userStatusHistory.length > 0 &&
                  userStatusHistory.map((stat) => (
                    <TableRow key={`statusHistTable-${stat.row}`}>
                      <TableCell>{stat.admin_username || "Patient"}</TableCell>
                      <TableCell>{formatDateTime(stat.created_at)}</TableCell>
                      <TableCell>{stat.status}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userStatusHistory: state.prescriptionReducer.userStatusHistory,
});

export default connect(mapStateToProps, {
  getPrescriptionHistory,
  clearPrescriptionHistory,
})(PrescriptionDialog);
