import store from "../Redux/store";
const hasPermission = (needed, permissions) => {
  const cont = permissions.filter(
    (p) => p.privilege === needed || p.privilege === "SUPER_ADMIN"
  );

  if (cont[0]) {
    return true;
  } else {
    return false;
  }
};

export default hasPermission;
