import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  clearViewAssessment,
  updateQuestionnaireResult,
  fetchStatusHistory,
} from "../../Redux/actions/questionnaireActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { useReactToPrint } from "react-to-print";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { formatDate } from "../../utils/formatData";
import { makeStyles } from "@material-ui/core/styles";
import { arraysEqual } from "../../utils/arraysEqual";

import hasPermission from "../../utils/hasPermission";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AssessmentDialog({
  open,
  setOpen,
  user,
  clearViewAssessment,
  viewAssessment,
  questions,
  updateQuestionnaireResult,
  updateCarelog,
  fetchStatusHistory,
  logged_in_permissions,
  assessQuestions,
  activeConversation,
}) {
  const componentRef = useRef();
  const [edit, setEdit] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [history, setHistory] = useState(false);
  const [newQuestions, setNewQuestions] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setAnswers(viewAssessment.questions);
  }, [viewAssessment]);

  useEffect(() => {
    if (open) setHistory(false);
  }, [open]);

  useEffect(() => {
    fetchStatusHistory(viewAssessment.id);
  }, [history]);

  const getUpdates = () => {
    const statusHistories =
      viewAssessment.statusHistories &&
      viewAssessment.statusHistories.filter((t) => t.status !== "created");
    const updates = {};
    statusHistories.forEach((t, i) => {
      if (!statusHistories[i - 1]) return;
      let before = statusHistories[i - 1].answers.map((a) => a.question_id);
      let after = statusHistories[i].answers.map((a) => a.question_id);

      let added = [...after.filter((x) => !before.includes(x))];

      let removed = [...before.filter((x) => !after.includes(x))];
      let allAnswered = [...new Set([...before, ...after])];
      let difference = allAnswered.filter(
        (x) => !added.includes(x) && !removed.includes(x)
      );

      let updated = difference
        .map((d) => {
          let a = statusHistories[i - 1].answers.find(
            (e) => d === e.question_id
          );
          let b = statusHistories[i].answers.find((e) => d === e.question_id);
          var same = false;
          if (!Array.isArray(a.answer)) {
            same = a.answer === b.answer;
          } else {
            same = arraysEqual(a.answer, b.answer);
          }
          if (!same)
            return {
              id: a.question_id,
              before: a.answer,
              after: b.answer,
            };
        })
        .filter((e) => e !== undefined);

      added = added.map((a) =>
        statusHistories[i].answers.find((_a) => _a.question_id === a)
      );

      updates[t.created_at] = {
        created_at: t.created_at,
        created_by: t.admin_username,
        added,
        removed,
        updated,
      };
    });

    return updates;
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    clearViewAssessment();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onChangeHandler = (e, i) => {
    if (!e.target.value && e.nativeEvent.inputType !== "deleteContentBackward")
      return alert("Please provide a number");
    const data = [...answers];
    data[i].answer = e.target.value;
    setAnswers(data);
  };

  const onChangeHandlerNQ = (e, i) => {
    if (!e.target.value && e.nativeEvent.inputType !== "deleteContentBackward")
      return alert("Please provide a number");
    const data = [...newQuestions];
    data[i].answer = e.target.value;
    setNewQuestions(data);
  };

  const handleChange = (e, i) => {
    const data = [...answers];
    data[i].answer = e.target.value;
    setAnswers(data);
  };

  const handleChangeNQ = (e, i) => {
    const data = [...newQuestions];
    data[i].answer = e.target.value;
    setNewQuestions(data);
  };

  const handleEdit = () => {
    setEdit(true);
  };
  const handleCancel = () => {
    setEdit(false);
    setNewQuestions([]);
  };

  const handleSave = () => {
    let formattedNewQuestions = newQuestions.map((nq) => {
      let data = {
        answers: nq.answers,
        answer: nq.answer,
        answersType: nq.answersType,
        body: nq.body,
        created_at: nq.created_at,
        questionType: nq.questionType,
        question_id: nq.question_id,
        updated_at: nq.updated_at,
      };
      if (nq.additional) {
        data.additional = "";
      }
      if (nq.description != "") {
        data.description = nq.description;
      } else {
        data.description = null;
      }
      return data;
    });
    const questionnaireResults = {
      id: viewAssessment.id,
      patient_id: viewAssessment.patient_id,
      questionnaire_id: viewAssessment.questionnaire_id,
      completed: viewAssessment.completed,
      created_at: viewAssessment.created_at,
      last_status_row: viewAssessment.last_status_row,
      answers: [...answers, ...formattedNewQuestions],
    };
    // ///UPDATE

    const handleCarelogUpdate = () => {
      updateCarelog(viewAssessment);
    };

    updateQuestionnaireResult(
      questionnaireResults,
      handleCarelogUpdate,
      activeConversation
    );

    setEdit(false);
    setNewQuestions([]);
  };

  const getParsedDate = (date) => {
    if (!date) return "";
    return date;
  };

  const renderYesNoAnswers = (answer, i, nq) => (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Answer</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={answer}
        onChange={nq ? (e) => handleChangeNQ(e, i) : (e) => handleChange(e, i)}
        label="Answer"
      >
        <MenuItem value="YES">YES</MenuItem>
        <MenuItem value="NO">NO</MenuItem>
      </Select>
    </FormControl>
  );

  const renderMultipleChoiceAnswers = (answer, { answers }, i, nq) => (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Answer</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={answer}
        fullWidth
        onChange={nq ? (e) => handleChangeNQ(e, i) : (e) => handleChange(e, i)}
        label="Answer"
      >
        {answers.map((a, i) => (
          <MenuItem key={i} value={a}>
            {a}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderTextAreaAnswers = (answer, question_id, i, nq) => (
    <TextField
      variant="outlined"
      required
      fullWidth
      value={answer}
      id={question_id}
      name={question_id}
      onChange={
        nq ? (e) => onChangeHandlerNQ(e, i) : (e) => onChangeHandler(e, i)
      }
    />
  );

  const renderTextBlock = (description) => (
    <Typography variant="h6" style={{ color: "#323A3E" }}>
      {description}
    </Typography>
  );

  const renderNumberAnswers = (answer, question_id, i, nq) => (
    <TextField
      variant="outlined"
      required
      type="number"
      fullWidth
      value={answer}
      id={question_id}
      name={question_id}
      onChange={
        nq ? (e) => onChangeHandlerNQ(e, i) : (e) => onChangeHandler(e, i)
      }
    />
  );

  const renderDatePickerAnswers = (answer, question_id, i, nq) => {
    const handleDateChange = (date) => {
      const obj = {
        month: `${date.getMonth() + 1}`,
        day: `${date.getDate()}`,
        year: `${date.getFullYear()}`,
      };
      if (nq) {
        const data = [...newQuestions];
        data[i].answer = JSON.stringify(obj);
        setNewQuestions(data);
      } else {
        const data = [...answers];
        data[i].answer = JSON.stringify(obj);
        setAnswers(data);
      }
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          id={question_id}
          label="Select Date"
          name={question_id}
          clearable
          autoOk
          animateYearScrolling
          disableFuture
          openTo="year"
          orientation="landscape"
          onChange={(d) => handleDateChange(d)}
          value={formatDate(getParsedDate(answer), "MM/DD/YYYY")}
          style={{ width: "100%" }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderSignatureAnswers = (answer) => {
    return (
      <>
        <Typography
          style={{
            fontSize: 18,
            fontWeight: 400,
            color: "#576C75",
          }}
        >
          SIGNATURE
        </Typography>
        <img src={answer} height={100} />
      </>
    );
  };

  const renderHistoryAdded = ({ added, created_by }) =>
    added.map((a) => {
      const q = questions.find((q) => a.question_id === q.id);
      if (!q) return "";
      return (
        <Typography style={{ margin: "5px 0" }}>
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`${created_by}`}</Typography>{" "}
          {` added answer `}
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`${a.answer}`}</Typography>{" "}
          {` for question `}
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`${q.body}`}</Typography>{" "}
        </Typography>
      );
    });

  const renderHistoryRemoved = ({ removed, created_by }) =>
    removed.map((a) => {
      const q = questions.find((q) => a.id === q.id);
      if (!q) return "";
      return (
        <Typography style={{ margin: "5px 0" }}>
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`${created_by}`}</Typography>{" "}
          {` removed answers for question `}
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`"${q.body}"`}</Typography>{" "}
        </Typography>
      );
    });

  const renderHistoryUpdated = ({ updated, created_by }) =>
    updated.map((a) => {
      const q = questions.find((q) => a.id === q.id);
      if (!q) return "";
      return (
        <Typography style={{ margin: "5px 0" }}>
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`${created_by}`}</Typography>{" "}
          {`updated answer from `}
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`"${a.before}"`}</Typography>{" "}
          {` to `}{" "}
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`"${a.after}"`}</Typography>{" "}
          {`for question `}{" "}
          <Typography
            display="inline"
            style={{ fontWeight: 500 }}
          >{`"${q.body}"`}</Typography>{" "}
        </Typography>
      );
    });

  const renderHistory = () => {
    const updates = viewAssessment.statusHistories ? getUpdates() : {};
    if (!Object.keys(updates).length)
      return <Typography variant="subtitle2">{`No History`}</Typography>;
    return Object.keys(updates).map((key, index) => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: 12, margin: "15px 0 5px", color: "#576C75" }}
            >
              {`${formatDate(updates[key].created_at, "llll")}`}
            </Typography>
            {renderHistoryAdded(updates[key])}
            {renderHistoryRemoved(updates[key])}
            {renderHistoryUpdated(updates[key])}
          </Grid>
        </Grid>
      );
    });
  };

  const renderNewQuestions = () => {
    if (newQuestions.length > 0) {
      if (!edit) {
        return (
          <Grid
            container
            style={{
              paddingTop: "25px",
              paddingBottom: "25px",
              borderBottom: "2px solid #EEF0F1",
            }}
          >
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 200,
                color: "red",
              }}
            >
              {`There are ${newQuestions.length} new questions available to be answered. Select EDIT to answer these new questions.`}
            </Typography>
          </Grid>
        );
      } else {
        return (
          <div>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 400,
                color: "red",
              }}
            >
              New Questions
            </Typography>
            {newQuestions.map((q, i) => (
              <Grid
                key={i}
                container
                style={{
                  paddingTop: "25px",
                  paddingBottom: "25px",
                  borderBottom: "2px solid #EEF0F1",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontSize: 18,
                      fontWeight: 400,
                      color: "#576C75",
                    }}
                  >
                    {q.body}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {q.questionType === "text-area" ? (
                    renderTextAreaAnswers(q.answer, q.id, i, true)
                  ) : q.questionType === "yes-no" ? (
                    renderYesNoAnswers(q.answer, i, true)
                  ) : q.questionType === "multiple-choice" ? (
                    renderMultipleChoiceAnswers(q.answer, q, i, true)
                  ) : q.questionType === "text-block" ? (
                    renderTextBlock(q.description)
                  ) : q.questionType === "number" ? (
                    renderNumberAnswers(q.answer, q, i, true)
                  ) : q.questionType === "date-picker" ? (
                    renderDatePickerAnswers(q.answer, q, i, true)
                  ) : q.questionType === "date-picker" ? (
                    <Typography variant="h6" style={{ color: "#323A3E" }}>
                      {formatDate(getParsedDate(q.answer))}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            ))}
          </div>
        );
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        ref={componentRef}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ background: "#F2F9FC" }}
        >
          {viewAssessment.name}
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Patient:</Typography>
              <Typography style={{ marginBottom: 10 }}>
                {user.f_name} {user.l_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Assigned:</Typography>
              <Typography style={{ marginBottom: 10 }}>
                {formatDate(viewAssessment.created_at, "llll")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Completed:</Typography>
              <Typography style={{ marginBottom: 10 }}>
                {formatDate(viewAssessment.taken_at, "llll")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {viewAssessment.last_update_by && (
                <Typography variant="subtitle2">Last Updated:</Typography>
              )}
              {viewAssessment.last_update_by && (
                <Typography style={{ marginBottom: 10 }}>
                  {formatDate(viewAssessment.last_update_at, "llll")}, by:{" "}
                  {viewAssessment.last_update_by}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {history && (
              <Box
                style={{
                  color: "#323A3E",
                  background: "#F2F9FC",
                  margin: "-16px -24px",
                  padding: "16px 24px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Typography variant="subtitle2">Assessment History</Typography>
                {renderHistory()}
              </Box>
            )}
            <Grid
              item
              xs={12}
              style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                borderBottom: "2px solid #EEF0F1",
              }}
            >
              <Typography
                style={{
                  color: "#323A3E",
                }}
              >
                <Typography variant="subtitle2">
                  Assessment instructions:
                </Typography>
                {viewAssessment.instructions}
              </Typography>
            </Grid>
            {answers &&
              answers.map((q, i) => {
                return (
                  <Grid
                    key={i}
                    container
                    style={{
                      paddingTop: "25px",
                      paddingBottom: "25px",
                      borderBottom: "2px solid #EEF0F1",
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container style={{ marginTop: 20 }}>
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            color: "#576C75",
                          }}
                        >
                          {q.questionType != "signature" && q.body}
                          {q.questionType == "signature" &&
                            renderSignatureAnswers(q.answer)}
                        </Typography>
                        {q.newQuestion ? (
                          <Typography
                            style={{
                              fontSize: 18,
                              fontWeight: 800,
                              color: "red",
                              marginLeft: 10,
                            }}
                          >
                            {"NEW"}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {edit ? (
                        q.questionType === "text-area" ? (
                          renderTextAreaAnswers(q.answer, q.id, i)
                        ) : q.questionType === "yes-no" ? (
                          renderYesNoAnswers(q.answer, i)
                        ) : q.questionType === "multiple-choice" ? (
                          renderMultipleChoiceAnswers(q.answer, q, i)
                        ) : q.questionType === "text-block" ? (
                          renderTextBlock(q.description)
                        ) : q.questionType === "number" ? (
                          renderNumberAnswers(q.answer, q, i)
                        ) : q.questionType === "date-picker" ? (
                          renderDatePickerAnswers(q.answer, q, i)
                        ) : undefined
                      ) : q.questionType === "date-picker" ? (
                        <Typography variant="h6" style={{ color: "#323A3E" }}>
                          {formatDate(getParsedDate(q.answer))}
                        </Typography>
                      ) : (
                        <Typography variant="h6" style={{ color: "#323A3E" }}>
                          {q.questionType != "signature"
                            ? q.answer || q.description
                            : null}
                        </Typography>
                      )}
                    </Grid>
                    {q.additionalAnswer && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          &rdquo;{q.additionalAnswer}&ldquo;
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            {renderNewQuestions()}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setHistory(!history)}
            style={{ marginRight: "auto" }}
          >
            {history ? "Hide" : "Show"} History
          </Button>
          {hasPermission("EDIT_ASSESSMENT", logged_in_permissions) &&
            (edit ? (
              <>
                <Button onClick={handleCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                  Save
                </Button>
              </>
            ) : (
              <Button onClick={handleEdit} color="primary">
                Edit
              </Button>
            ))}

          <Button onClick={handlePrint} color="primary">
            Print
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { viewAssessment } = state.questionnaireReducer;
  const { assessment } = state.questionnaireReducer;
  const { get_history_loading } = state.questionnaireReducer;
  const { logged_in_permissions } = state.adminReducer;
  const { activeConversation } = state.messageReducer;

  return {
    viewAssessment,
    historyLoading: get_history_loading,
    logged_in_permissions,
    assessQuestions: assessment.questions,
    activeConversation,
    user: state.userReducer.user,
  };
};

export default connect(mapStateToProps, {
  clearViewAssessment,
  updateQuestionnaireResult,
  fetchStatusHistory,
})(AssessmentDialog);

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 5,
  },
}));
