import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllResults } from "../../Redux/actions/questionnaireActions";
import { getAllDocumentResults } from "../../Redux/actions/documentActions";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { common } from "@material-ui/core/colors";

import { checkWeekAgo } from "../../utils/formatData";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "95%",
    margin: "auto",
  },
  statCard: {
    height: 72,
    borderRadius: 25,
    padding: 25,
  },
  statCardContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  headingContainer: {
    color: "white",
    width: "50%",
  },
  heading: {
    margin: 0,
    opacity: 1,
    lineHeight: 1,
    fontWeight: 500,
    fontSize: "1.5em",
  },
  statContainer: {
    textAlign: "right",
    width: "50%",
    color: "white",
  },
  stat: {
    margin: 0,
    lineHeight: 1,
    fontSize: "3em",
  },
}));

const statObjects = [
  {
    heading: "REFILL REQUESTS",
    color: "#00B9BC",
    data: "refillRequests",
  },
  {
    heading: "REFILLS PROCESSING",
    color: "#74C455",
    data: "refillsProcessing",
  },
  {
    heading: "RECENT ASSESSMENTS COMPLETED",
    color: "#4297D0",
    data: "assessmentsCompleted",
  },
  {
    heading: "RECENT DOCUMENTS COMPLETED",
    color: "#283136",
    data: "documentsCompleted",
  },
];

const StatCard = ({ classes, heading, stat, color }) => {
  const split = heading.split(" ");
  return (
    <Grid
      item
      style={{
        width: "24%",
        height: 122,
      }}
    >
      <div
        className={classes.statCard}
        style={{
          backgroundColor: color,
        }}
      >
        <div className={classes.statCardContainer}>
          <div className={classes.headingContainer}>
            {" "}
            {split.map((s) => (
              <h3 key={`splitHeading-${s}`} className={classes.heading}>
                {" "}
                {s}{" "}
              </h3>
            ))}{" "}
          </div>{" "}
          <div className={classes.statContainer}>
            <h1 className={classes.stat}> {stat} </h1>{" "}
          </div>
        </div>
      </div>{" "}
    </Grid>
  );
};

const Stats = ({
  requested,
  getAllResults,
  allResults,
  processed,
  inProcess,
  getAllDocumentResults,
  documentResults,
}) => {
  const classes = useStyles();
  const [assessmentsCompleted, setAssessmentsCompleted] = useState([]);
  const [docsCompleted, setDocsCompleted] = useState([]);

  useEffect(() => {
    getAllResults();
    getAllDocumentResults();
  }, []);

  useEffect(() => {
    setAssessmentsCompleted([
      ...allResults.filter(
        (r) => r.completed && r.type == "assessment" && checkWeekAgo(r.taken_at)
      ),
    ]);
  }, [allResults]);

  useEffect(() => {
    setDocsCompleted([
      ...documentResults.filter(
        (d) => d.created_at && checkWeekAgo(d.created_at)
      ),
    ]);
  }, [documentResults]);

  const setStat = (data) => {
    if (data == "refillRequests") {
      return requested.length;
    } else if (data == "refillsProcessing") {
      return inProcess.length + processed.length;
    } else if (data == "assessmentsCompleted") {
      return assessmentsCompleted.length;
    } else if (data === "documentsCompleted") {
      return docsCompleted.length;
    }
  };

  return (
    <Grid container className={classes.container} justify="space-between">
      {" "}
      {statObjects.map((s) => (
        <StatCard
          key={`statCard-${s.heading}`}
          classes={classes}
          heading={s.heading}
          stat={setStat(s.data)}
          color={s.color}
        />
      ))}{" "}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  requested: state.prescriptionReducer.requested,
  allResults: state.questionnaireReducer.allResults,
  inProcess: state.prescriptionReducer.inProcess,
  processed: state.prescriptionReducer.processed,
  shipped: state.prescriptionReducer.shipped,
  documentResults: state.documentReducer.documentResults,
});

export default connect(mapStateToProps, {
  getAllResults,
  getAllDocumentResults,
})(Stats);
