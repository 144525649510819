import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getTherapies,
  addTherapy,
  setTherapy,
  clearTherapy,
  updateTherapy,
} from "../../Redux/actions/medicationActions";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import EditIcon from "@material-ui/icons/Edit";
import useStyles from "../Auth/AuthFormStyles";

const Therapies = ({
  therapies,
  getTherapies,
  addTherapy,
  setTherapy,
  clearTherapy,
  updateTherapy,
  therapy,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");

  useEffect(() => {
    getTherapies();

    return () => {
      clearTherapy();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(therapy).length > 0) {
      setName(therapy.name);
    }
  }, [therapy]);

  const onChangeHandler = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name != "") {
      let n = name.replace("/", "|");
      if (Object.keys(therapy).length > 0) {
        updateTherapy(therapy.row, n);
      } else {
        addTherapy(n);
      }
    }
  };

  return (
    <Container component="main" className={classes.authContainer}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <GraphicEqIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Therapies
        </Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                // error={error.username ? true : false}
                // helperText={error.username && error.username}
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Therapy Name"
                name="name"
                autoComplete="name"
                value={name}
                onChange={(e) => onChangeHandler(e)}
              />
            </Grid>
          </Grid>

          <Button
            // disabled={!typeSelected}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {Object.keys(therapy).length > 0 ? "Update Therapy" : "Add Therapy"}
          </Button>
        </form>
      </div>
      <TableContainer className={classes.container} style={{ marginTop: 25 }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.headerCell}>Therapy Name</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {therapies.map((t) => (
              <TableRow key={t.name}>
                <TableCell align="left">{t.name}</TableCell>
                <TableCell align="left">
                  <Button onClick={() => setTherapy(t.row)}>
                    <EditIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  therapies: state.medicationReducer.therapies,
  therapy: state.medicationReducer.therapy,
});

export default connect(mapStateToProps, {
  getTherapies,
  addTherapy,
  setTherapy,
  clearTherapy,
  updateTherapy,
})(Therapies);
