import {
  CREATE_ADMIN_LOADING,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_FAILED,
  UPDATE_ADMIN_LOADING,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILED,
  GET_ADMINS_LOADING,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAILED,
  GET_ADMIN_SUCCESS,
  CLEAR_ADMIN,
  GET_PERMISSIONS_LOADING,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILED,
  GET_LOGGED_IN_PERMISSIONS_LOADING,
  GET_LOGGED_IN_PERMISSIONS_SUCCESS,
  GET_LOGGED_IN_PERMISSIONS_FAILED,
  TOGGLE_ACTIVATED_ADMIN_LOADING,
  TOGGLE_ACTIVATED_ADMIN_SUCCESS,
  TOGGLE_ACTIVATED_ADMIN_FAILED,
} from "../types";

import isEmpty from "../../utils/isEmpty";

const initialState = {
  create_loading: false,
  get_all_loading: false,
  get_permissions_loading: false,
  get_logged_in_permissions_loading: false,
  toggle_activated_loading: false,
  logged_in_permissions: [],
  logged_in_super_admin: false,
  selected_permissions: [],
  admins: [],
  admin: {},
  error: {},
  success: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ADMIN_LOADING:
      return {
        ...state,
        create_loading: true,
        success: null,
        error: {},
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        create_loading: false,
        admins: [...state.admins, { ...action.payload, permissions: [] }],
      };
    case CREATE_ADMIN_FAILED:
      return {
        ...state,
        error: action.payload,
        create_loading: false,
        success: null,
      };
    case UPDATE_ADMIN_LOADING:
      return {
        ...state,
        create_loading: true,
        success: null,
        error: {},
      };
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        create_loading: false,
        admins: [
          ...state.admins.map((a) => {
            if (a.username == action.original) {
              a = { ...a, ...action.payload };
            }
            return a;
          }),
        ],
      };
    case UPDATE_ADMIN_FAILED:
      return {
        ...state,
        error: action.payload,
        create_loading: false,
        success: null,
      };
    case GET_ADMINS_LOADING:
      return {
        ...state,
        get_all_loading: true,
        error: {},
      };
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        get_all_loading: false,
        admins: action.payload,
      };
    case GET_ADMINS_FAILED:
      return {
        ...state,
        get_all_loading: false,
        error: action.payload,
      };
    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        admin: state.admins.filter((a) => a.username === action.payload)[0],
      };
    case GET_LOGGED_IN_PERMISSIONS_LOADING:
      return {
        ...state,
        get_logged_in_permissions_loading: true,
      };
    case GET_LOGGED_IN_PERMISSIONS_SUCCESS:
      const superAdmin = action.payload.filter(
        (p) => p.privilege === "SUPER_ADMIN"
      );
      return {
        ...state,
        get_logged_in_permissions_loading: false,
        logged_in_permissions: action.payload,
        logged_in_super_admin: superAdmin[0] ? true : false,
      };
    case GET_LOGGED_IN_PERMISSIONS_FAILED:
      return {
        ...state,
        get_logged_in_permissions_loading: false,
        error: action.payload,
      };
    case GET_PERMISSIONS_LOADING:
      return {
        ...state,
        get_permissions_loading: true,
        selected_permissions: [],
        error: {},
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        get_permissions_loading: false,
        selected_permissions: action.payload,
      };
    case GET_PERMISSIONS_FAILED:
      return {
        ...state,
        get_permissions_loading: false,
        error: action.payload,
      };
    case TOGGLE_ACTIVATED_ADMIN_LOADING:
      return {
        ...state,
        toggle_activated_loading: true,
        error: {},
      };
    case TOGGLE_ACTIVATED_ADMIN_SUCCESS:
      return {
        ...state,
        toggle_activated_loading: false,
        admins: [
          ...state.admins.filter((a) => a.username != action.payload.username),
          { ...action.payload, permissions: [] },
        ],
      };
    case TOGGLE_ACTIVATED_ADMIN_FAILED:
      return {
        ...state,
        toggle_activated_loading: false,
        error: action.payload,
      };
    case CLEAR_ADMIN:
      return {
        ...state,
        admin: {},
        selected_permissions: [],
      };
    default:
      return state;
  }
};
