import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getQuestions,
  addQuestion,
  setQuestion,
} from "../../Redux/actions/questionnaireActions";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
} from "@material-ui/core";

import QuestionItem from "./QuestionItem";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    transition: "300ms background ease",
    marginBottom: 20,
  },
  accordionExpanded: {
    background: "#F0F8FF",
  },
  expandIcon: {
    color: "#fff",
    background: "#B3D5EC",
    "&:hover": {
      color: "#fff",
      background: "#B3D5EC",
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -24,
    top: 11,
    margin: "auto",
    fontSize: 14,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "10px 8px",
  },
}))(Badge);

const QuestionLibrary = ({
  getQuestions,
  questions,
  addQuestion,
  setQuestion,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    getQuestions();
  }, []);

  const data = search ? searchResults : questions;

  return (
    <>
      <SearchBar
        value={search}
        onChange={(newValue) => {
          setSearchResults(
            questions.filter(
              (a) =>
                (a.body &&
                  a.body.toLowerCase().includes(newValue.toLowerCase())) ||
                (a.description &&
                  a.description.toLowerCase().includes(newValue.toLowerCase()))
            )
          );
          setSearch(newValue);
        }}
        placeholder="Search a Question in Library"
        searchIcon={<Typography />}
      />
      <Accordion
        defaultExpanded
        classes={{
          root: classes.accordion,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            edge: "end",
            size: "small",
            disableRipple: true,
          }}
          classes={{
            expandIcon: classes.expandIcon,
            expanded: classes.accordionExpanded,
          }}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <StyledBadge badgeContent={questions.length} color="primary">
            <Typography className={classes.heading}>
              Questions Library
            </Typography>
          </StyledBadge>
        </AccordionSummary>
        <AccordionDetails
          style={{
            maxHeight: "600px",
            overflow: "scroll",
            background: "#F0F8FF",
          }}
        >
          <Grid container>
            {data.length > 0 &&
              data.map((q) => (
                <Grid item xs={12} key={q.id}>
                  <QuestionItem
                    data={q}
                    add={addQuestion}
                    setQuestion={setQuestion}
                  />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  questions: state.questionnaireReducer.questions,
});

export default connect(mapStateToProps, {
  getQuestions,
  addQuestion,
  setQuestion,
})(QuestionLibrary);
