import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getAllMessages,
  setActiveConversation,
} from "../../Redux/actions/messageActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import { fromNow } from "../../utils/formatData";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
    // margin: 20
  },
  header: {
    textTransform: "uppercase",
  },
  headerCell: {
    paddingTop: 0,
    color: "#798991",
    fontSize: 12,
    fontWeight: 400,
  },
  row: {
    justifyContent: "center",
  },
  date: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.4,
    color: "#576C75",
  },
  name: {
    fontWeight: 500,
    color: "#283136",
    textDecoration: "none",
  },
  seen: {
    color: "#798991",
    fontWeight: 400,
  },
  unseen: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));

const MessagesTable = ({ getAllMessages, admin, unseen, type }) => {
  const classes = useStyles();

  useEffect(() => {
    getAllMessages(admin.username);
  }, []);

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.headerCell} align="left">
                Time
              </TableCell>
              <TableCell className={classes.headerCell}>Patient Name</TableCell>
              <TableCell className={classes.headerCell}>Message</TableCell>
              {type != "patient" && (
                <TableCell className={classes.headerCell}>
                  Conversation
                </TableCell>
              )}

              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(unseen).length > 0 &&
              Object.values(unseen)
                .sort((a, b) => {
                  if (a.created_at < b.created_at) {
                    return 1;
                  } else if (a.created_at > b.created_at) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .filter((v) => {
                  if (v.type == type) {
                    return v;
                  }
                })
                .map((v) => {
                  if (!v.seen) {
                    return (
                      <TableRow
                        className={classes.row}
                        key={`unseenMessages-${v.patient_id}-${v.adminChat}`}
                      >
                        <TableCell
                          className={v.seen ? classes.seen : classes.unseen}
                        >
                          {fromNow(v.created_at)}
                        </TableCell>
                        <TableCell className={classes.name}>{v.name}</TableCell>
                        <TableCell
                          className={v.seen ? classes.seen : classes.unseen}
                        >
                          {v.adminChat ? (
                            <Tooltip title="Admin Chat">
                              <PeopleIcon
                                style={{
                                  position: "relative",
                                  top: 5,
                                  marginRight: 5,
                                  opacity: 0.35,
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Patient Chat">
                              <PersonIcon
                                style={{
                                  position: "relative",
                                  top: 5,
                                  marginRight: 5,
                                  opacity: 0.35,
                                }}
                              />
                            </Tooltip>
                          )}
                          {v.text.substring(0, 45)}
                        </TableCell>
                        {v.title && (
                          <TableCell align="left">{v.title}</TableCell>
                        )}
                        <TableCell align="right">
                          <IconButton
                            href={`/patients/${v.patient_id}/${
                              v.adminChat ? "0/" : ""
                            }${v.adminChat ? v.conversation_id : ""}`}
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
            {Object.keys(unseen).length > 0 &&
              Object.values(unseen)
                .sort((a, b) => {
                  if (a.created_at < b.created_at) {
                    return 1;
                  } else if (a.created_at > b.created_at) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .filter((v) => {
                  if (v.type === type) {
                    return v;
                  }
                })
                .map((v) => {
                  if (v.seen) {
                    return (
                      <TableRow
                        className={classes.row}
                        key={`unseenMessages-${v.patient_id}`}
                      >
                        <TableCell
                          className={v.seen ? classes.seen : classes.unseen}
                        >
                          {fromNow(v.created_at)}
                        </TableCell>
                        <TableCell className={classes.name}>{v.name}</TableCell>
                        <TableCell
                          className={v.seen ? classes.seen : classes.unseen}
                        >
                          {v.adminChat ? (
                            <Tooltip title="Admin Chat">
                              <PeopleIcon
                                style={{
                                  position: "relative",
                                  top: 5,
                                  marginRight: 5,
                                  opacity: 0.35,
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Patient Chat">
                              <PersonIcon
                                style={{
                                  position: "relative",
                                  top: 5,
                                  marginRight: 5,
                                  opacity: 0.35,
                                }}
                              />
                            </Tooltip>
                          )}
                          {v.text.substring(0, 45)}
                        </TableCell>
                        {v.title && (
                          <TableCell align="left">{v.title}</TableCell>
                        )}

                        <TableCell align="right">
                          <IconButton
                            href={`/patients/${v.patient_id}/${
                              v.adminChat ? "0/" : ""
                            }${v.adminChat ? v.conversation_id : ""}`}
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
            {/* {users.length > 0 &&
              users.map((user) => (
                <TableRow key={`patientTable-${user.id}`}>
                  <TableCell>
                    <a
                      className={classes.name}
                      href={`/patients/${user.id}`}
                    >{`${user.f_name} ${user.l_name}`}</a>
                  </TableCell>
                  <TableCell align="center">
                    {user.prescriptions.length}
                  </TableCell>
                  <TableCell>{formatPhone(user.phone)}</TableCell>
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" href={`/patients/${user.id}`}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.authReducer.admin,
  unseen: state.messageReducer.all_messages,
});

export default connect(mapStateToProps, {
  getAllMessages,
  setActiveConversation,
})(MessagesTable);
