import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getResources,
  getResource,
} from "../../Redux/actions/educationActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CardMedia,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { formatDate } from "../../utils/formatData";

const useStyles = makeStyles({
  container: {
    width: "100%",
  },
  table: {
    minWidth: 650,
    // margin: 20
  },
  header: {
    textTransform: "uppercase",
  },
  headerCell: {
    paddingTop: 0,
    color: "#798991",
    fontSize: 12,
    fontWeight: 400,
  },
  date: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.4,
    color: "#576C75",
  },
  name: {
    fontWeight: 500,
    color: "#283136",
    textDecoration: "none",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9,
    border: "2px solid #798991",
    borderRadius: 5,
  },
});

const ResourcesTable = ({ getResources, getResource, resources }) => {
  const classes = useStyles();

  useEffect(() => {
    getResources();
  }, []);

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.headerCell}>Title</TableCell>
              <TableCell className={classes.headerCell}>Cover</TableCell>
              <TableCell className={classes.headerCell}>Description</TableCell>
              <TableCell className={classes.headerCell}>Medications</TableCell>
              <TableCell className={classes.headerCell}>Date Created</TableCell>
              <TableCell className={classes.headerCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.length > 0 &&
              resources.map((r) => (
                <TableRow key={`educationTable-${r.id}`}>
                  <TableCell className={classes.name}>{r.title}</TableCell>
                  <TableCell>
                    {r.image_url ? (
                      <CardMedia
                        className={classes.media}
                        image={r.image_url}
                      />
                    ) : (
                      "—"
                    )}
                  </TableCell>
                  <TableCell>{r.description}</TableCell>
                  <TableCell>
                    {r.medicationTags ? r.medicationTags.join(", ") : "None"}
                  </TableCell>
                  <TableCell>{formatDate(r.created_at)}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => getResource(r.id)}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  resources: state.educationReducer.resources,
});

export default connect(mapStateToProps, {
  getResources,
  getResource,
})(ResourcesTable);
